import { changeLanguage } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
    const {i18n} = useTranslation();
    const changeLanguage=(lng)=>{
        i18n.changeLanguage(lng);
    }

  return (
    <div className='grid grid-cols-2 gap-4 content-center p-3'>
        <button className={"en" === i18n.language?" text-blue-600 ":""} onClick={()=> changeLanguage("en")}>English</button>
        <button className={"zh" === i18n.language?" text-blue-600":""} onClick={()=> changeLanguage("zh")}>Chinese</button>
    </div>
  )
}

export default LanguageSelector