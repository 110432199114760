import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import TruncateTooltip from "../../../components/TruncateTooltip";
import { FaSearch } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
import { IoIosArrowDown } from "react-icons/io"; // Down arrow icon
import Select from "../../../components/Select";

const CategorizedList = () => {
  const [openRow, setOpenRow] = useState(null);

  const handleButtonClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const data = [
    { id: 1, name: "John Doe", age: 28 },
    { id: 2, name: "Jane Smith", age: 34 },
    { id: 3, name: "Alice Johnson", age: 45 },
  ];
  const options = [
    "",
    "",
    "",
    // Add more options as needed
  ];

  return (
    <>
      <div className="font-sans">
        <div className="my-9 flex justify-between h-9">
          <div className="h-9 flex">
            <div className="relative flex items-center">
              {/* Calendar Icon */}
              <FaCalendarAlt className="absolute left-3 text-gray-500 pointer-events-none" />

              {/* Select Field */}
              <select className="w-[175px] h-9 pl-10 pr-10 py-2 text-sm border border-greyText rounded-md focus:outline-none focus:border-lochmara appearance-none">
                <option value="" disabled>
                  All dates
                </option>
                <option value="2024-01-01">All dates</option>
                <option value="2024-01-02"></option>
                {/* Add more options as needed */}
              </select>

              {/* Down Arrow Icon */}
              <IoIosArrowDown className="absolute right-3 text-gray-500 pointer-events-none" />
            </div>
            <div className="relative ml-3 ">
              <input
                type="text"
                className="pl-10 pr-4 py-2 w-[475px] text-sm h-9 border border-greyText px-2 rounded-md focus:outline-none focus:border-lochmara"
                placeholder="Search by description,check by number, or amount"
              />
              <FaSearch className="absolute w-6 left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>
            {/* <SearchBox /> */}
          </div>
          <div className="content-center">
            <div className="flex px-5 gap-2">
              <div className="w-9 h-9">
                <img src="blob.png" />
              </div>
              <div className="w-9 h-9">
                <img src="icon8.png" />
              </div>
              <div className="w-9 h-9">
                <img src="printer.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                <th className="w-[7%] py-1">
                  <input type="checkbox" className="w-5 h-5 mt-1" />
                </th>
                <th className="w-[13%]">
                  <div className="flex">
                    DATE
                    <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                  </div>
                </th>
                <th className="w-[18%]">DESCRIPTION</th>
                <th className="w-[10%]">AMOUNT</th>
                <th className="w-[15%]">FROM/TO</th>
                <th className="w-[20%]">ASSIGN TO</th>
                <th className="w-[20%]">CATEGORY</th>
                <th className="w-[20%]">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <React.Fragment key={row.id}>
                  <tr className="border-b text-sm">
                    <td className="py-2">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </td>
                    <td className="py-2">25/07/2024</td>
                    <td className="py-2 pt-3 whitespace-nowrap relative group">
                      <TruncateTooltip
                        text="online transfer to sam online transfer to sam online transfer to sam "
                        maxLength={50}
                      />
                    </td>
                    <td className="py-2">$400.00</td>
                    <td className="py-2"></td>
                    <td className="py-2 flex pt-4">
                      Added to : <p className="text-royalBlue ml-1">Expense 1</p>
                    </td>
                    <td className="py-2">
                      <Select
                        options={options}
                        placeholder="Contract labour"
                        width="w-[155px]" // Set dynamic width here
                      />
                    </td>
                    <td className="py-2 text-royalBlue">
                      <button
                        onClick={() => handleButtonClick(row.id)}
                        className="py-1 text-base font-semibold"
                      >
                        Undo
                      </button>
                    </td>
                  </tr>
                  {openRow === row.id && (
                    <tr className="w-full">
                      <td colSpan="8" className="w-full">
                        <div className="h-[239px] w-full bg-bonJour pt-2">
                          <div className="relative">
                            <button
                              onClick={() => handleButtonClick(row.id)}
                              className="absolute top-2 right-5 text-gray-600 hover:text-gray-900 transition-colors"
                            >
                              <img
                                src="cross-icon.png"
                                className="w-[12px] h-[12px] m-2"
                              />
                            </button>
                          </div>
                          <div className="py-5 px-3">
                            <div className="py-5">
                                <p className="text-sm">Description</p>
                                <p className="text-base flex">Cash shows this as <p className="font-semibold pl-1">ONLINE TRANSFER TO SAM #PO879654C9L ON 10/29/2023</p></p>
                            </div>
                            <div>
                                <p className="text-sm">Memo</p>
                                <p className="text-base">Cash shows this as ONLINE TRANSFER TO SAM #PO879654C9L ON 10/29/2023</p>
                            </div>
                          </div>
                          <div className="flex justify-end px-7">
                            <button className="bg-lochmara text-white w-[200px] h-[35px] text-sm font-bold uppercase rounded-[10px] mb-3">
                              Undo
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CategorizedList;
