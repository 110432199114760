import React from 'react'
import Layout from '../../Layout'
import Tabs from '../../../components/Tabs';
import TransactionList from '../../../components/transaction-list/TransactionList';
import CustomerDetails from '../../../components/customer-details/CustomerDetails';
import Statement from '../../../components/statement/Statement';
import { Link } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/io5';

const CustomerDashboard = () => {
    const tabs = [
        {
          id: 'transactionlist',
          title: 'Transaction List',
          content: <TransactionList/>,
        },
        {
          id: 'statements',
          title: 'Statements',
          content: <Statement/>,
        },
        {
          id: 'customerdetails',
          title: 'Customer Details',
          content: <CustomerDetails/>,
        },
      ];
    
  return (
    <>
         <Layout>
        <div className=" bg-white w-full h-full px-8 overflow-y-scroll">
          <div className="">
            <div className="w-full">
             
              <div className="text-xl font-semibold font-sans py-7">
                <h1>Customers Dashboard</h1>
              </div>
              <div className='flex content-center'><IoChevronBackOutline className="text-blueRibbon mt-1 text-lg cursor-pointer"/>
              <Link to="/customer" className='mb-2 text-lg font-semibold font-sans text-blueRibbon ml-2'>Customer</Link></div>
              <div className="w-full gap-1 bg-bonJour px-2 h-auto border border-greyText">
              <div className="w-[60%] py-2">
                <p className='text-2xl font-sans font-semibold pb-3'>Sample Company Name</p>
                <div className="flex justify-between">
                    <div>
                      <p className='font-normal text-sm font-sans'>Company</p>
                      <p className="font-semibold text-sm font-sans">Sample Company Inc</p>
                    </div>
                    <div>
                        <p className='font-normal text-sm font-sans'>Billing address</p>
                        <div className='font-semibold text-sm font-sans'>
                            <p>123 some street</p>
                            <p>Suite # 999</p>
                            <p>Some City, Some State</p>
                            <p>Some Country, 999 9999</p>
                        </div>
                    </div>
                </div>
              </div>
              <div className='py-1'>
                <lable className='font-normal text-sm font-sans'>Notes</lable>
                <div className='font-semibold text-sm font-sans py-1'>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt cupiditate nobis deserunt ea non illum inventore explicabo dolores, ad eius corporis nisi. Incidunt, inventore esse recusandae ipsum eveniet voluptatibus?Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt cupiditate nobis deserunt ea non illum inventore explicabo dolores, ad eius corporis nisi. Incidunt, inventore esse recusandae ipsum eveniet voluptatibus?</p>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div>
          <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CustomerDashboard