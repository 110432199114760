import React from 'react'
import { useTranslation } from "react-i18next";

const EditExitInfo = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
  return (
    <>
        <div className=" bg-white w-full h-full grid grid-cols content-between overflow-y-scroll font-sans ">
        <div className="">
          <div className="w-full px-7 pb-3">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
              <h1>Update Employee Exit Information</h1>
              </div>
            </div>
            <div>
              <p className="text-lochmara text-base font-semibold">
              TERMINATION INFORMATION
              </p>
              <div>
                <div className="flex text-start">
                  <div className="w-[200px] py-1">
                    <lable className="text-sm font-semibold font-sans">Date of Exit</lable>
                    <div>
                      <input
                        type="date"
                        className="w-[200px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold font-sans">Reason</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b w-full border-pearl"></div>
        </div>
        <div className="w-full pb-5 px-7">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditExitInfo