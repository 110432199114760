import React,{useState} from "react";
import Select from "../../../components/Select";

const SelectAccount = ({setIsSelectAccountOpen,setIsMapFieldOpen,setIsUploadFileOpen}) => {

  const handleMapFieldPopup = () => {
    setIsSelectAccountOpen(false)
    setIsMapFieldOpen(true);
  };
  const handleClose =()=>{
    setIsSelectAccountOpen(false)
  }

  const handlePrevClick = ()=>{
    setIsSelectAccountOpen(false)
    setIsUploadFileOpen(true);
  }
  const options = [
    "Select Account",
    ""
    // Add more options as needed
  ];

  return (
    <>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center bg-gray-800">
        <div className="text-center w-[500px] h-[500px] bg-white py-5 font-sans">
          <div className="w-[470px] h-[86px] mx-4 my-3 flex">
            <p className="w-full text-4xl font-semibold text-start">
            Which account are these transactions from?
            </p>
            <div className="relative">
              <button
                onClick={handleClose}
                className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
              >
                <img src="cross-icon.png" className="w-[18px] h-[18px] mx-2 -mt-4" />
              </button>
            </div>
          </div>
          <div className="w-[470px] py-10 px-5">
                <p className="text-lg font-semibold text-start">Select a account for the bank file you want to upload</p>
            </div>
            <div className="text-start px-5 text-lg">
              <p>Account</p>
              <Select
                options={options}
                placeholder="Select Account"
                width="w-[360px]" // Set dynamic width here
              />
            </div>
          <div className="flex justify-center px-6 h-40">
            <div className="content-end">
            <button className="w-[200px] h-[35px] text-sm font-bold rounded-[10px] mt-4 mr-4 uppercase bg-pearl text-white"
            onClick={handlePrevClick}>
              back
            </button>
            <button className="w-[200px] h-[35px] text-sm font-bold rounded-[10px] mt-4 uppercase bg-lochmara text-white"
            onClick={handleMapFieldPopup}>
              continue
            </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectAccount;
