import React, { useState } from "react";
import AddService from "./service/AddService";
import AddBundle from "./bundle/AddBundle";
import AddProduct from "./product/AddProduct";

const AddProductsAndServices = ({onSubmit,onClose}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [isUploadFileOpen, setIsUploadFileOpen] = useState(false);
  const [isSelectAccountOpen, setIsSelectAccountOpen] = useState(false);
  const [isMapFieldOpen, setIsMapFieldOpen] = useState(false);

  const handleUploadFilePopup = () => {
    setIsUploadFileOpen(true);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className=" bg-white w-full h-full overflow-y-scroll px-7 font-sans">
        <div className="">
          <div className="w-full">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
                <h1>Product/Service information</h1>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-[600px] h-[45px] flex justify-center">
              <div
                className={`${
                  activeTab === 1 ? "bg-pearl text-white" : "text-gray-600"
                } w-[200px] border border-greyText text-center content-center rounded-l-[18px]`}
              >
                <button
                  key={1}
                  onClick={() => handleTabClick(1)}
                  className="text-sm"
                >
                  Product
                </button>
              </div>
              <div
                className={`${
                  activeTab === 2 ? "bg-pearl text-white" : "text-gray-600"
                } w-[200px] border border-greyText text-center content-center`}
              >
                <button
                  key={2}
                  onClick={() => handleTabClick(2)}
                  className="text-sm"
                >
                  Service
                </button>
              </div>
              <div
                className={`${
                  activeTab === 3 ? "bg-pearl text-white" : "text-gray-600"
                } w-[200px] border border-greyText text-center content-center rounded-r-[18px]`}
              >
                <button
                  key={3}
                  onClick={() => handleTabClick(3)}
                  className="text-sm"
                >
                  Bundle
                </button>
              </div>
            </div>
          </div>
          {activeTab === 1 && (
            <AddProduct
              onSubmit={onSubmit}
              onClose={onClose}
            />
          )}
          {activeTab === 2 && (
            <AddService
              onSubmit={onSubmit}
              onClose={onClose}
            />
          )}
          {activeTab === 3 && (
            <AddBundle
              onSubmit={onSubmit}
              onClose={onClose}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddProductsAndServices;
