import React from 'react'

const CustomerDetails = () => {
  return (
    <>
      <div>
        <div className='w-full border-2 border-bonJour rounded-md py-3 h-[170px]'>
            <div className='w-[90%] grid grid-cols-3 gap-10 px-3'>
                <div className='text-sm font-sans'>
                    <lable className="text-greyText font-semibold">Customer name</lable>
                    <p className='font-semibold pb-3'>Sample Customer Name</p>
                    <label className="text-greyText font-semibold">Company name</label>
                    <p className=' font-semibold'>Sample Company Name</p>
                </div>
                <div className='text-sm font-sans'>
                    <lable className="text-greyText font-semibold">Email</lable>
                    <p className=' font-semibold pb-3'>someone@gmail.com</p>
                    <lable className="text-greyText font-semibold">Fax</lable>
                    <p className=' font-semibold pb-3'>+1 999 999 9999</p>
                    <lable className="text-greyText font-semibold">Website</lable>
                    <p className=' font-semibold'>www.samplewebsite.com</p>
                </div>
                <div className='text-sm font-sans'>
                    <lable className="text-greyText font-semibold">Phone number</lable>
                    <p className=' font-semibold pb-3'>+1 999 999 9999</p>
                    <lable className="text-greyText font-semibold">Mobile number</lable>
                    <p className=' font-semibold'>+1 999 999 9999</p>
                </div>
            </div>
        </div>
        <div className='w-full border-2 border-bonJour rounded-md my-2 h-[142px]'>
            <div className='w-[90%] grid grid-cols-2 gap-10 px-3 py-3'>
                <div className='text-sm font-sans'>
                    <lable className="text-greyText font-semibold font-sans">Billing address</lable>
                    <div className='font-semibold text-sm font-sans py-2'>
                            <p>123 some street</p>
                            <p>Suite # 999</p>
                            <p>Some City, Some State</p>
                            <p>Some Country, 999 9999</p>
                    </div>
                </div>
                <div className='text-sm'>
                    <lable className="text-greyText font-semibold">Shipping address</lable>
                    <div className='font-semibold text-sm py-2 font-sans'>
                            <p>123 some street</p>
                            <p>Suite # 999</p>
                            <p>Some City, Some State</p>
                            <p>Some Country, 999 9999</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full border-2 border-bonJour rounded-md h-[142px]'>
            <div className='w-[90%] grid grid-cols-2 gap-10 p-3'>
                <div className='text-sm '>
                    <lable className="text-greyText font-semibold">Notes</lable>
                    <div className='font-semibold text-sm py-2 w-[80%]'>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                             Possimus ducimus consectetur assumenda voluptate optio neque.</p>
                    </div>
                </div>
                <div className='text-xs'>
                    <lable className="text-greyText font-semibold">Attachements</lable>
                    <div className='font-semibold text-sm py-2'>
                           <img src='pdf-file.png' className='w-10 h-10'/>
                           <span className='text-sm text-greyText'>CompanyRegistration.pdf</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDetails
