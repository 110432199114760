import React,{useState} from "react";
import Layout from "../../Layout";
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown";
import AddCategory from "./add-category/AddCategory";
import Dialog from "../../../components/Dialog";
import Modal from "../../../components/Modal";

const Category = () => {
    const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
    const [isCategoryDialogClose, setIsCatgoryDialogClose] = useState(false);
    const [isRemoveCategoryModalOpen, setRemoveCategoryModalOpen] = useState(false);

    const openRemoveCategoryModal = () => setRemoveCategoryModalOpen(true);
  const closeRemoveCategoryModal = () => setRemoveCategoryModalOpen(false);
    
  
    const handleSubmitCategory = () => {
        setIsCategoryDialogOpen((preventDefault) => !preventDefault);
    };
    const handleSelect = (value) => {
      if (value === "remove") {
        openRemoveCategoryModal();
      }
      
    };
  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-8 font-sans">
          <div className="">
            <div className="w-full">
              <div className="w-full text-xl font-semibold font-sans my-[70px] justify-between flex">
                <div className="">
                  <h1>Product Categories</h1>
                </div>
              </div>
              <div className="float-right mb-10">
                <button
                  className="uppercase bg-lochmara text-sm font-sans font-semibold justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2 pl-5"
                  onClick={handleSubmitCategory}
                >
                  ADD CATEGORY
                </button>
              </div>
            </div>
            <div className="py-10">
              <div className="py-2">
                <table className="w-full">
                  <tbody className="text-sm">
                    <tr className="border-b-2 border-bombay text-left uppercase">
                      <th className="w-[50%]">
                        <div className="flex">Name</div>
                      </th>
                      <th className="w-[50%] text-right px-2">Action</th>
                    </tr>
                    <tr className="border-b-2 border-bombay">
                      <td className="py-2 text-left">Grocery</td>
                      <td className="py-2 flex cursor-pointer justify-end text-nowrap pl-3">
                        <button
                        //onClick={handleSubmitVendorCredit}
                        >
                          <a className="text-royalBlue font-semibold pt-2">
                            Edit |
                          </a>
                        </button>
                        <div className="">
                          <CustomDropdown
                            options={[{ value: "remove", option: "Remove" }]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 border-bombay">
                      <td className="py-2 text-left">Service</td>
                      <td className="py-2 flex cursor-pointer justify-end text-nowrap pl-3">
                        <button
                        //onClick={handleSubmitVendorCredit}
                        >
                          <a className="text-royalBlue font-semibold pt-2">
                            Edit |
                          </a>
                        </button>
                        <div className="">
                          <CustomDropdown
                            options={[{ value: "remove", option: "Remove" }]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={isRemoveCategoryModalOpen}>
        <div className="relative">
          <button
            onClick={closeRemoveCategoryModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img src="warning-icon.png" className="w-9 h-9 mx-auto mb-3" />
        </div>
        <p className="text-sm font-sans font-normal my-5 mt-9 px-7 text-start">
        Are you sure you want to delete this category? Any products, services, or categories in it will be uncategorized.
        </p>
        <button
          onClick={closeRemoveCategoryModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          YES
        </button>
      </Modal>
      <Dialog
        isOpen={isCategoryDialogOpen}
        onClose={isCategoryDialogClose}
        height="h-[720px]"
        width="w-[550px]"
      >
        <AddCategory
          onSubmit={handleSubmitCategory}
          onClose={handleSubmitCategory}
        />
      </Dialog>
    </>
  );
};

export default Category;
