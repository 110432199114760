import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="py-4">
      <div className="flex border-b-2 border-pearl w-full">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`py-2 mr-4 text-base font-semibold font-sans ${
              activeTab === tab.id
                ? 'border-b-4 border-salem mb-[1px] text-blue-500'
                : 'text-gray-600'
            }`}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="">
        {tabs.map((tab) =>
          activeTab === tab.id ? (
            <div key={tab.id} className="py-4">
              {tab.content}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Tabs;
