import React from 'react'

const ForReview = () => {
  return (
    <>
         <div className="py-10">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold uppercase">
                      <th className="w-[3%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[10%]">
                        Bill No
                      </th>
                      <th className="w-[10%]">Vendor</th>
                      <th className="w-[15%]">Bill Date</th>
                      <th className="w-[15%]">Category</th>
                      <th className="w-[15%]">Due Date</th>
                      <th className="w-[20%]">Bill Amount</th>
                      <th className="w-[10%]">ACTION</th>
                    </tr>
                  </tbody>
                </table>
                <div className='w-full flex justify-center py-10'>
                    <p className='font-sans text-5xl font-semibold'>No bill to review</p>
                </div>
        </div>
    </>
  )
}

export default ForReview