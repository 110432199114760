import { useState,useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validation from "../../utils/validation";
import Logo from "../Logo";


const BusinessEntityInfo = () => {
  const divstyle =
    "border border-pearl mb-2 py-2 text-center w-[49.5%] h-[129px] content-center text-sm cursor-pointer";
  const selecteddiv =
    "border border-pearl mb-2 py-2 text-center w-[49.5%] h-[129px] content-center text-sm bg-malachite text-white cursor-pointer";
  const { t } = useTranslation();
  const navigate = useNavigate()

   const gotToPrevPage=()=>{
     navigate("/BusinessEntityTypeForTax");
   }
   let BusinessEntityInfo = {};
   const [isActive, setIsActive] = useState(false)
   const [vatno,setVatno] = useState('');

   // State hooks
  //const [inputValue, setInputValue] = useState('');
  const [selectedDiv, setSelectedDiv] = useState(null);
   

  // useEffect(() => {
    
  // }, [vatno]);
  useEffect(() => {
    localStorage.setItem('type', JSON.stringify(BusinessEntityInfo.Name));
  }, [BusinessEntityInfo.Name]);


   const [errors, setErrors] = useState({});
   const validationErrors = {};

   const submitButton = ()=>{
    //console.log("lenth ",Object.keys(BusinessEntityInfo).length)
    const type = localStorage.getItem('type');
    console.log(type)
    if(localStorage.getItem('vatno') === "" && !type){
      console.log(" vatn : ",localStorage.getItem('vatno'))
      console.log(type)
      setIsActive(false)
    }else{
      setIsActive(true)
    }
   }

   const handleChange = (event) =>{
    setVatno(event.target.value);
   };

   const handleDivClick = (value) => {
    //setSelectedDiv(index);
    //setSelectedDiv(prev => (prev === index ? null : index));
    setSelectedDiv(prev => (prev === value ? null : value));
    console.log("selectedDiv : ", selectedDiv," vantno : ",vatno)
    
  };

  // Determine if the button should be enabled
  const isButtonEnabled = vatno.trim() !== '' && selectedDiv !== null;
  console.log("btnEnabled : ",isButtonEnabled);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isButtonEnabled) {
    }
  };

  return (
    <>
    <Logo />
      <div className="h-auto bg-white flex justify-center border-t-4 py-[2%] border-black">
        <div className="w-[640px]">
          <div className="py-7 font-semibold font-sans flex justify-center">
            <h1 className="text-2xl"
            name="heading">
              {t("BEaddInfo")}
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="w-full flex justify-center">
            <div className="w-full">
              <div className="">
                <div className="flex flex-wrap mb-6 ">
                  <div className="w-full">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal"
                      for="grid-vatno"
                    >
                      {t("VATNo")} *
                    </label>
                    <input
                      className="appearance-none block w-full h-[35px] text-gray-700 border border-pearl rounded-lg px-2 leading-tight focus:outline-none focus:bg-white focus:border-Lochmara"
                      id="grid-vatno"
                      type="number"
                      name="vatnumber"
                      onChange={handleChange}
                      value={vatno}
                      placeholder=""
                      maxLength={10}
                    />
                    {errors.vatno && (
                      <span className="text-errorRed text-xs flex italic mt-1">
                        <img src="alert.png" className="w-4 mr-1" />
                        {errors.vatno}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal"
                      for="grid-classification"
                    >
                      {t("classification")} *
                    </label>
                  </div>
                  <div className="flex row justify-between w-full pb-4">
                    <div className={`${selectedDiv === "micro" ? selecteddiv : divstyle}`} 
                         Name="micro" key="micro"
                         onClick={() => handleDivClick("micro")}>
                      <p className="font-semibold text-xl font-sans pointer-events-none">
                        {t("micro")}
                      </p>{" "}
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("10emp")}</p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("lessthanusd50k")}</p>
                    </div>
                    
                    <div className={`${selectedDiv === "small" ? selecteddiv : divstyle}`} 
                    key="small"
                    onClick={() => handleDivClick("small")}>
                      <p className="font-semibold text-xl font-sans pointer-events-none">
                        {t("small")}
                      </p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("11emp")}</p>
                      <p className="pointer-events-none text-xl font-normal font-sans"> {t("assest50k")}</p>
                    </div>
                  </div>
                  <div className="flex row justify-between">
                    <div className={`${selectedDiv === "medium" ? selecteddiv : divstyle}`} 
                    key="medium"
                    onClick={() => handleDivClick("medium")}>
                      {" "}
                      <p className="font-semibold text-xl font-sans pointer-events-none">
                        {t("medium")}
                      </p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("51emp")}</p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("assest250k")}</p>
                    </div>
                    <div className={`${selectedDiv === "large" ? selecteddiv : divstyle}`} 
                    key="large"
                    onClick={() => handleDivClick("large")}>
                      <p className="font-semibold font-sans text-xl pointer-events-none">
                        {t("large")}
                      </p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("100emp")}</p>
                      <p className="pointer-events-none text-xl font-normal font-sans">{t("assest500k")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-right space-x-2 lg:space-x-6 py-5'>
                  <button type="submit" className="uppercase bg-pearl text-white text-xs w-[200px] h-[35px] rounded-lg my-5 font-bold" onClick={gotToPrevPage}>{t("back")}</button>
                  <button type="submit" role="button" 
                  className={`${
                    isButtonEnabled ? "bg-lochmara" : "bg-havelock"
                  } uppercase text-white w-[200px] h-[35px] rounded-lg my-5  text-xs font-bold content-center`}
                  disabled={!isButtonEnabled}
                  >{t("save")}</button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BusinessEntityInfo;
