import React from 'react'
import ReactDOM from 'react-dom'

const Dialog = ({ isOpen, onClose, children ,height='h-[720px]', width='w-[1280px]'}) => {
    console.log("open",isOpen)
    console.log("onClose",onClose)
   
    return (
      <div className={` ${isOpen ? 'absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 min-w-[1280px] max-w-[1920px] mx-auto':''}`}>
        <div  className={`absolute top-0 right-0 h-full z-50 ${
        isOpen ? '' : 'hidden'
      } overflow-y-scroll `}>
        <div className={`bg-white ${width} ${height} rounded-md shadow-md`}>
          {children}
        </div>
      </div>
      </div>
    );
}

export default Dialog