import React from "react";
import Layout from "../../Layout";
import { FaChevronDown } from "react-icons/fa";
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown";
import Dialog from "../../../components/Dialog";
import CreateInvoice from "../create-invoice/CreateInvoice";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "../../../components/Modal";
import ReceivePayment from "../receive-payment/ReceivePayment";
import InvoiceCopy from "../create-duplicate-invoice/InvoiceCopy"
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";

const Invoices = () => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isVoidModalOpen, setVoidModalOpen] = useState(false);
  const [isCreateInvoiceDialogOpen, setIsCreateInvoiceDialogOpen] = useState(false);
  const [isInvoiceCopyDialogOpen, setIsInvoiceCopyDialogOpen] = useState(false);
  const [isCreateInvoiceDialogClose, setIsCreateInvoiceDialogClose] = useState(false);
  const [isInvoiceCopyDialogClose, setIsInvoiceCopyDialogClose] = useState(false);
  const [isReceivePaymentDialogOpen, setIsReceivePaymentDialogOpen] =
  useState(false);
const [isReceivePaymentDialogClose, setIsReceivePaymentDialogClose] =
  useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openVoidModal = () => setVoidModalOpen(true);
  const closeVoidModal = () => setVoidModalOpen(false);
  const openInvoiceCopyModal = () => setIsInvoiceCopyDialogOpen(true);

  const handleSubmitCreateInvoice = () => {
    setIsCreateInvoiceDialogOpen((preventDefault) => !preventDefault);
  };
  const handleSubmitInvoiceCopy = () => {
    setIsInvoiceCopyDialogOpen((preventDefault) => !preventDefault);
  };

  const closePaymentDialog = () => setIsReceivePaymentDialogOpen(false);

  const handleSubmitPayment = () => {
    // Handle password submission logic here
    //console.log('Submitted password:', password);
    //closePaymentDialog();
    setIsReceivePaymentDialogOpen((preventDefault) => !preventDefault);
  };
  
  const navigate = useNavigate();

  const handleSelect = (value) => {
    if (value === "void") {
      openVoidModal();
    }
    if (value === "delete") {
      openDeleteModal();
    }
    if (value === "duplicate") {
      openInvoiceCopyModal();
    }
  };
  return (
    <>
      <Layout>
        <div className=" bg-white w-full h-auto overflow-y-scroll px-8 mb-2">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans my-10">
                <h1>All Invoices</h1>
              </div>
              <div className="w-full flex row gap-3 pt-8 pb-5">
                <div className="w-1/2">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="font-semibold font-sans text-3xl">$0</h1>
                      <p className="text-base font-semibold font-sans text-greyText pb-5">
                        Overdue
                      </p>
                    </div>
                    <div>
                      <h1 className="font-semibold font-sans text-3xl">$0</h1>
                      <p className="text-base font-semibold font-sans text-greyText pb-5">
                        Not due yet
                      </p>
                    </div>
                  </div>
                  <div className="bg-tango h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-1/2">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="font-semibold font-sans text-3xl">$0</h1>
                      <p className="text-base font-semibold font-sans text-greyText pb-5">
                        Not deposited
                      </p>
                    </div>
                    <div>
                      <h1 className="font-semibold font-sans text-3xl">$0</h1>
                      <p className="text-base font-semibold font-sans text-greyText pb-5">
                        Deposited
                      </p>
                    </div>
                  </div>
                  <div className="gap-1 flex row w-full">
                    <div className="bg-bayLeaf h-[30px] rounded-[5px] w-1/2"></div>
                    <div className="bg-salem h-[30px] rounded-[5px] w-1/2"></div>
                  </div>
                </div>
              </div>
              <div className="float-right">
                <button className="uppercase bg-lochmara text-sm font-sans font-normal justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2"
                onClick={handleSubmitCreateInvoice}>
                  Create Invoice
                  <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                </button>
              </div>
            </div>
            <div className="py-16">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-7">
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Status</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <select className="border-pearl border w-full px-2 py-2 rounded-[5px]">
                        <option>All</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Date</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <select className="border-pearl border w-full px-2 py-2 rounded-[5px]">
                        <option>Last 12 months</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex content-center py-5 px-5">
                  <div className="w-6 h-6">
                    <img src="blob.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="icon8.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="printer.jpg" />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                      <th className="w-[4%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[12%]">
                        <div className="flex">
                          DATE
                          <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                        </div>
                      </th>
                      <th className="w-[10%]">No</th>
                      <th className="w-[25%]">CUSTOMER</th>
                      <th className="w-[10%]">AMOUNT</th>
                      <th className="w-[15%]">STATUS</th>
                      <th className="w-[22%]">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">1001</td>
                      <td className="py-2">Sample customer</td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 pt-3 flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">
                          Edit |
                        </a>
                        <a className="px-1 pt-2 text-lochmara font-semibold"
                        onClick={handleSubmitPayment}>
                          Receive payment |
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">1002</td>
                      <td className="py-2">Sample customer</td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 pt-3 flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">
                          Edit |
                        </a>
                        <a className="px-1 pt-2 text-lochmara font-semibold"
                        onClick={handleSubmitPayment}>
                          
                          Receive payment |
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">1003</td>
                      <td className="py-2">Sample customer</td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 pt-3 flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">
                          Edit |
                        </a>
                        <a className="px-1 pt-2 text-lochmara font-semibold"
                        onClick={handleSubmitPayment}>
                          Receive payment |
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={isVoidModalOpen}>
        <div className="relative">
          <button
            onClick={closeVoidModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans">Void invoice?</h3>
        <p className="text-sm font-sans font-normal h-10 my-4 px-4">
        The invoice balance will go to zero, and your customer won't be able to pay it. You can't undo this.
        </p>
        <button
          onClick={closeVoidModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          {t("void")}
        </button>
      </Modal>
      <Modal isOpen={isDeleteModalOpen}>
        <div className="relative">
          <button
            onClick={closeDeleteModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans">Delete invoice?</h3>
        <p className="text-sm font-sans font-normal h-10 my-4 px-4">
        The invoice balance will go to zero, and your customer won't be able to pay it. You can't undo this.
        </p>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          {t("delete")}
        </button>
      </Modal>
      <Dialog
        isOpen={isCreateInvoiceDialogOpen}
        onClose={isCreateInvoiceDialogClose}
        height="h-[1080px]"
      >
        <CreateInvoice
          onSubmit={handleSubmitCreateInvoice}
          onClose={handleSubmitCreateInvoice}
        />
      </Dialog>

      <Dialog
        isOpen={isInvoiceCopyDialogOpen}
        onClose={isInvoiceCopyDialogClose}
        height="h-[1080px]"
      >
        <InvoiceCopy
          onSubmit={handleSubmitInvoiceCopy}
          onClose={handleSubmitInvoiceCopy}
        />
      </Dialog>
      <Dialog
        isOpen={isReceivePaymentDialogOpen}
        onClose={isReceivePaymentDialogClose}
        width="w-[500px]"
      >
        <ReceivePayment
          onSubmit={handleSubmitPayment}
          onClose={closePaymentDialog}
        />
      </Dialog>
    </>
  );
};

export default Invoices;
