import React, { useState } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";

const AddProduct = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onSubmit();
  };
  const [isNewCategoryModalOpen, setNewCategoryModalOpen] = useState(false);

  const openNewCategoryModal = () => setNewCategoryModalOpen(true);
  const closeNewCategoryModal = () => setNewCategoryModalOpen(false);

  const handleSelect = (value) => {
    if (value === "newCategory") {
      openNewCategoryModal();
    }
  };
  return (
    <>
      <div className="w-full font-sans">
        <div className="w-full flex">
          <div>
            <div className="py-2">
              <lable className="text-sm font-semibold">Name *</lable>
              <div>
                <textarea
                  type="text"
                  className="w-[466px] h-[69px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                />
              </div>
            </div>
            <div>
              <lable className="text-sm font-semibold">SKU</lable>
              <div>
                <input
                  type="text"
                  className="w-[466px] h-[48px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2"
                />
              </div>
            </div>
          </div>
          <div className="content-center pt-4 pl-4 flex">
            <div className="border border-pearl">
              <img src="default-img.png" className="w-[166px] h-[166px]" />
            </div>
            <div className="content-end">
              <img src="edit.png" />
              <img src="trash-bin-icon.png" />
            </div>
          </div>
        </div>
        <div className="flex justify-between py-2">
          <div className="flex items-center justify-center space-x-2 mt-5">
            <input type="checkbox" className="form-checkbox h-5 w-5" />
            <label className="text-sm">Track Inventory</label>
          </div>
          <div className="pr-4">
            <lable className="text-sm font-semibold">Inventory in hand</lable>
            <div>
              <input
                type="text"
                className="w-[325px] h-[45px] border border-pearl focus:border-lochmara focus:outline-0 p-2"
              />
            </div>
          </div>
        </div>
        <div className="pr-4 py-2">
          <lable className="text-sm font-semibold">Category</lable>
          <CustomDropdown
            options={[
              {
                value: "newCategory",
                option: "New Category",
              },
            ]}
            border="border border-pearl rounded-md"
            btnCss="text-white bg-bombay"
            onSelect={handleSelect}
          />
        </div>
        <div className="pr-4 py-2">
          <lable className="text-sm font-semibold">Description</lable>
          <div>
            <textarea
              type="text"
              className="h-[80px] w-full border border-pearl focus:border-lochmara focus:outline-0 p-2 resize-none"
            />
          </div>
        </div>
        <div className="pr-4 py-2">
          <div className="flex justify-between">
            <div>
              <lable className="text-sm font-semibold">Sales price/rate</lable>
              <div>
                <input
                  type="text"
                  className="h-9 w-[325px] border border-pearl  focus:border-lochmara focus:outline-0 p-2"
                />
              </div>
            </div>
            <div className="w-[325px]">
              <lable className="text-sm font-semibold">Income account</lable>
              <CustomDropdown
                options={[
                  {
                    value: "alltransactions",
                    option: "All Transactions",
                  },
                  { value: "expense", option: "Expense" },
                  { value: "bill", option: "Bill" },
                  { value: "payment", option: "Bill payment" },
                ]}
                border="border border-pearl rounded-md"
                btnCss="text-white bg-bombay"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between py-2 ">
          <div className="flex items-center justify-center space-x-2 mt-5">
            <input type="checkbox" className="form-checkbox h-5 w-5" />
            <label className="text-sm">Taxable</label>
          </div>
          <div className="pr-4">
            <lable className="text-sm font-semibold">Tax rate/VAT/GST</lable>
            <div>
              <input
                type="text"
                className="w-[325px] h-[45px] border border-pearl focus:border-lochmara focus:outline-0 p-2"
              />
            </div>
          </div>
        </div>
        <div className="border-b-2 pb-5 border-pearl mr-4"></div>
        <div className="pt-3">
          <p className="text-base font-sans font-semibold">
            Purchasing information
          </p>
          <div className="flex items-center space-x-2 pt-3">
            <input type="checkbox" className="form-checkbox h-5 w-5" />
            <label className="text-sm">Purchased from a vendor</label>
          </div>
        </div>
        <div className="pr-4 py-2">
          <div className="flex justify-between">
            <div>
              <lable className="text-sm font-semibold">Cost</lable>
              <div>
                <input
                  type="text"
                  className="h-9 w-[325px] border border-pearl focus:border-lochmara focus:outline-0 p-2"
                />
              </div>
            </div>
            <div className="w-[325px]">
              <lable className="text-sm font-semibold">Expense Account</lable>
              <CustomDropdown
                options={[
                  {
                    value: "alltransactions",
                    option: "All Transactions",
                  },
                  { value: "expense", option: "Expense" },
                  { value: "bill", option: "Bill" },
                  { value: "payment", option: "Bill payment" },
                ]}
                border="border border-pearl rounded-md"
                btnCss="text-white bg-bombay"
              />
            </div>
          </div>
        </div>
        <div className="pr-4 py-2">
          <div className="w-[325px]">
            <div>
              <lable className="text-sm font-semibold">Vendor</lable>
              <CustomDropdown
                options={[
                  {
                    value: "alltransactions",
                    option: "All Transactions",
                  },
                  { value: "expense", option: "Expense" },
                  { value: "bill", option: "Bill" },
                  { value: "payment", option: "Bill payment" },
                ]}
                border="border border-pearl rounded-md"
                btnCss="text-white bg-bombay"
              />
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex pr-4 pt-8">
          <div className="flex justify-between">
            <button
              onClick={handleClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isNewCategoryModalOpen}
       width="w-[550px]"
       height="h-[275px]">
         <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>New Category</h1>
            </div>
          </div>
          <div className="w-full flex font-sans">
              <div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold">Name *</lable>
                    <div>
                      <textarea
                        type="text"
                        className="w-[500px] h-[69px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="w-full justify-end flex pt-10">
          <div className="flex justify-between">
            <button
              onClick={closeNewCategoryModal}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={closeNewCategoryModal}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
        </div>
      </Modal>
    </>
  );
};

export default AddProduct;
