import React, { useState, useRef, useEffect } from 'react';

const CustomDropdown = ({ options,onSelect,border,btnCss,width = 'auto'}) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Add event listener for clicks outside the dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${border} relative text-right h-9 content-center p-[1px] pt-[1px]`} ref={selectRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`${btnCss} focus:outline-none w-[31px] h-[31px] rounded-sm`}
      >
        
        <svg className="w-5 h-5 ml-[5px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className={`absolute z-50 py-2 ${width} text-nowrap text-left bg-white shadow-greyText rounded-md shadow-inner right-0`}>
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => onSelect(option.value)}
              className="cursor-pointer pl-4 pr-[26px] py-[2.9px] text-sm font-medium hover:bg-lochmara hover:text-white"
            >
              {option.option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
