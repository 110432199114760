// src/components/Dialog.js
import React from 'react';

const Dialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-auto overflow-y-auto">
      <div className="relative bg-white rounded-md shadow-lg">
        {children}
      </div>
    </div>
  );
};

export default Dialog;
