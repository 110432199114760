import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import Categorize from "./categorize/Categorize";
import TruncateTooltip from "../../../components/TruncateTooltip";
import { FaSearch } from "react-icons/fa";
import { FaCalendarAlt } from 'react-icons/fa'; // Calendar icon
import { IoIosArrowDown } from 'react-icons/io'; // Down arrow icon
import RecordAsTransfer from "./record-as-transfer/RecordAsTransfer";

const ReviewList = () => {
  const [openRow, setOpenRow] = useState(null);
  const [selected, setSelected] = useState('category');

  console.log(selected);

  const handleButtonClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const data = [
    { id: 1, name: "John Doe", age: 28 },
    { id: 2, name: "Jane Smith", age: 34 },
    { id: 3, name: "Alice Johnson", age: 45 },
  ];

  return (
    <>
      <div className="font-sans">
        <div className="my-9 flex justify-between h-9">
          <div className="h-9 flex">
            <div className="relative flex items-center">
              {/* Calendar Icon */}
              <FaCalendarAlt className="absolute left-3 text-gray-500 pointer-events-none" />

              {/* Select Field */}
              <select
                className="w-[175px] h-9 pl-10 pr-10 py-2 text-sm border border-greyText rounded-md focus:outline-none focus:border-lochmara appearance-none"
                
              >
                <option value="" disabled>
                  All dates
                </option>
                <option value="2024-01-01">All dates</option>
                <option value="2024-01-02"></option>
                {/* Add more options as needed */}
              </select>

              {/* Down Arrow Icon */}
              <IoIosArrowDown className="absolute right-3 text-gray-500 pointer-events-none" />
            </div>
            {/* <select className="border border-greyText w-[175px] h-9 rounded-md px-2 ">
              <option>All dates</option>
            </select> */}
            {/* <input
              type="text"
              className="w-[475px] h-9 border border-greyText px-2 rounded-md ml-4"
              placeholder="Search by description,check by number, or amount"
            /> */}
            <div className="relative ml-3 ">
              <input
                type="text"
                className="pl-10 pr-4 py-2 w-[475px] text-sm h-9 border border-greyText px-2 rounded-md focus:outline-none focus:border-lochmara"
                placeholder="Search by description,check by number, or amount"
              />
              <FaSearch className="absolute w-6 left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>
            {/* <SearchBox /> */}
          </div>
          <div className="content-center">
            <div className="flex px-5 gap-2">
              <div className="w-9 h-9">
                <img src="blob.png" />
              </div>
              <div className="w-9 h-9">
                <img src="icon8.png" />
              </div>
              <div className="w-9 h-9">
                <img src="printer.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                <th className="w-[10%] py-1">
                  <input type="checkbox" className="w-5 h-5 mt-1" />
                </th>
                <th className="w-[20%]">
                  <div className="flex">
                    DATE
                    <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                  </div>
                </th>
                <th className="w-[5%]">DESCRIPTION</th>
                <th className="w-[20%]">FROM/TO</th>
                <th className="w-[10%]">AMOUNT</th>
                <th className="w-[25%]">ASSIGN TO</th>
                <th className="w-[22%]"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <React.Fragment key={row.id}>
                  <tr className="border-b text-sm">
                    <td className="py-2">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </td>
                    <td className="py-2">25/07/2024</td>
                    <td className="py-2 pt-3 whitespace-nowrap relative group">
                      <TruncateTooltip
                        text="online transfer to sam online transfer to sam online transfer to sam "
                        maxLength={50}
                      />
                    </td>
                    <td className="py-2"></td>
                    <td className="py-2">$400.00</td>
                    <td className="py-2 text-royalBlue">Assign a category</td>
                    <td className="py-2">
                      <button
                        onClick={() => handleButtonClick(row.id)}
                        className="bg-blue-500 py-1 px-3 text-sm font-bold rounded-[10px] border w-[144px] h-9"
                      >
                        REVIEW
                      </button>
                    </td>
                  </tr>
                  {openRow === row.id && (
                    <tr className="w-full">
                      <td colSpan="7" className="w-full">
                        <div className="h-[363px] w-full bg-bonJour">
                          <div className="relative">
                            <button
                              onClick={() => handleButtonClick(row.id)}
                              className="absolute top-2 right-5 text-gray-600 hover:text-gray-900 transition-colors"
                            >
                              <img
                                src="cross-icon.png"
                                className="w-[12px] h-[12px] m-2"
                              />
                            </button>
                          </div>
                          <div className="gap-4 flex m-2 py-4 px-2">
                            <input type="radio" 
                            name="toggle"
                            value="category"
                            checked={selected === 'category'}
                            onChange={() => setSelected('category')}
                            className="w-4 h-4 mt-[2px]"
                            />
                            <p className="text-sm">Categorizes</p>
                            <input type="radio" className="w-4 h-4 mt-[2px]"
                             name="toggle"
                             value="transfer"
                             checked={selected === 'transfer'}
                             onChange={() => setSelected('transfer')}
                            />
                            <p className="text-sm">Record as transfer</p>
                          </div>
                          <div className="w-full px-5">
                         
                          {selected === 'transfer' && <RecordAsTransfer />}
                          {selected === 'category' && <Categorize />}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReviewList;
