import React from "react";

const RunPayroll = () => {
  return (
    <>
      <div className="w-full font-sans">
        <div className="mb-4">
          <h1 className="text-xl font-semibold">
            Process Pay Run for June 2024
          </h1>
        </div>
        <div className="flex ">
          <div className="flex gap-10">
            <div>
              <p className="text-sm font-normal">EMPLOYEE'S NET PAY</p>
              <p className="text-base font-bold">YET TO PROCESS</p>
            </div>
            <div>
              <p className="text-sm font-normal">EMPLOYEE'S NET PAY</p>
              <p className="text-base font-bold">30/06/2024</p>
            </div>
            <div>
              <p className="text-sm font-normal">EMPLOYEE'S NET PAY</p>
              <p className="text-base font-bold">20</p>
            </div>
          </div>
          <div className="ml-28">
          <button
            className="uppercase bg-lochmara text-sm font-sans font-bold justify-center text-white rounded-[10px] w-[200px] h-[36px] flex py-2"
            //onClick={handleSalaryRevisionDialog}
          >
            CREATE PAY RUN
          </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RunPayroll;
