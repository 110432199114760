import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo";

const BusinessEntityTypeForTax = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotToNextPage = () => {
    navigate("/BusinessEntityInfo");
  };
  const gotToPrevPage = () => {
    navigate("/BusinessInformation");
  };

  const [isActive, setIsActive] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState(null);

  let BusinessEntityType = {};

  const divstyle =
    "border border-pearl my-2 py-2 text-center w-[350px] h-[60px] text-xl font-normal font-sans content-center leading-[20px] cursor-pointer";
  const selecteddiv =
    "border border-pearl my-2 py-2 text-center w-[350px] h-[60px] text-xl font-normal font-sans content-center bg-malachite text-white cursor-pointer";

  // const changeStyle = (event) => {
  //   event.preventDefault();
  //   console.log(Object.keys(BusinessEntityType).length)
  //   let length = Object.keys(BusinessEntityType).length;
  //   if (length === 0 ) {
  //       console.log(BusinessEntityType , event.target.innerText)
  //       //setIsActive((preventDefault) => !preventDefault)
  //     if (event.target.className !== selecteddiv) {
  //       event.target.className = selecteddiv;
  //       BusinessEntityType.Name = event.target.innerText;
  //       console.log(BusinessEntityType)
        
        
  //     } else {
  //       event.target.className = divstyle;
  //     }
      
  //   } else if (BusinessEntityType.Name === event.target.innerText) {
  //     event.target.className = divstyle;
  //     BusinessEntityType = {};
  //     console.log(BusinessEntityType)
  //     setIsActive((preventDefault) => !preventDefault)
  //   }
    
  // };

  const handleDivClick = (value) => {
    //setSelectedDiv(index);
    //setSelectedDiv(prev => (prev === index ? null : index));
    setSelectedDiv(prev => (prev === value ? null : value));
    console.log("selectedDiv : ", selectedDiv)
    
  };

   // Determine if the button should be enabled
   const isButtonEnabled = selectedDiv !== null;
   console.log("btnEnabled : ",isButtonEnabled);

  return (
    <>
    
      <div className="bg-white h-screen">
      <Logo />
        <div className="h-auto flex justify-center border-t-4 py-[2%] border-black">
          <div className="h-auto">
          <div className="font-semibold text-center text-2xl font-sans py-7">
            <h1>{t("BETfortax")}</h1>
          </div>
            <div className=" w-[770px]">
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "soleprop" ? selecteddiv : divstyle}`} 
                    key="soleprop"
                    onClick={() => handleDivClick("soleprop")}>
                {t("soleprop")}
                </div>
                <div className="border border-white text-center w-[350px] text-sm"></div>
              </div>
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "generalpartnership" ? selecteddiv : divstyle}`} 
                    key="generalpartnership"
                    onClick={() => handleDivClick("generalpartnership")}>
                  {t("generalpartnership")}
                </div>
                <div className={`${selectedDiv === "ltdpartnership" ? selecteddiv : divstyle}`} 
                    key="ltdpartnership"
                    onClick={() => handleDivClick("ltdpartnership")}>
                  {t("ltdpartnership")}
                </div>
              </div>
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "pvtltdcompany" ? selecteddiv : divstyle}`} 
                    key="pvtltdcompany"
                    onClick={() => handleDivClick("pvtltdcompany")}>
                  {t("pvtltdcompany")}
                </div>
                <div className={`${selectedDiv === "siglememberpvtltdcompany" ? selecteddiv : divstyle}`} 
                    key="siglememberpvtltdcompany"
                    onClick={() => handleDivClick("siglememberpvtltdcompany")}>
                  {t("siglememberpvtltdcompany")}
                </div>
              </div>
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "publicltdcompany" ? selecteddiv : divstyle}`} 
                    key="publicltdcompany"
                    onClick={() => handleDivClick("publicltdcompany")}>
                  {t("publicltdcompany")}
                </div>
                <div
                  className="border border-white text-center w-[350px] text-sm"
                  
                ></div>
              </div>
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "repoffice" ? selecteddiv : divstyle}`} 
                    key="repoffice"
                    onClick={() => handleDivClick("repoffice")}>
                  {t("repoffice")}
                </div>
                <div className={`${selectedDiv === "branchoffice" ? selecteddiv : divstyle}`} 
                    key="branchoffice"
                    onClick={() => handleDivClick("branchoffice")}>
                  {t("branchoffice")}
                </div>
              </div>
              <div className="flex row justify-around">
                <div className={`${selectedDiv === "subsidiary" ? selecteddiv : divstyle}`} 
                    key="subsidiary"
                    onClick={() => handleDivClick("subsidiary")}>
                  {t("subsidiary")}
                </div>
                <div
                  className="border border-white text-center w-[350px] text-sm"
                  
                ></div>
              </div>
              <div className="text-center flex justify-end space-x-2 w-[98%] pt-5">
            <button
              type="submit"
              className="uppercase bg-pearl text-white text-xs w-[200px] h-[35px] rounded-[10px] font-bold"
              onClick={gotToPrevPage}
            >
              {t("back")}
            </button>
            <button
              type="submit"
              className={`${
                isButtonEnabled ? "bg-lochmara" : "bg-havelock"
              } uppercase text-white text-xs w-[200px] h-[35px] rounded-[10px] font-bold content-center`}
              onClick={gotToNextPage}
              disabled = {!isButtonEnabled ? true : false}
            >
              {t("next")}
            </button>
          </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessEntityTypeForTax;
