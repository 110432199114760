import React,{useState} from 'react'
import { useTranslation } from 'react-i18next'
import { validate } from '../../../utils/formValidation';


const ReceivePayment = ({ onSubmit, onClose }) => {
    const {t} = useTranslation();
   
      const [formData, setFormData] = useState({ date:'',paymentMethod:'' ,refno:'',depositTo:''});
      const [errors, setErrors] = useState({ date:'' ,paymentMethod:'',refno:'',depositTo:''});
      const [isActive,setIsActive] = useState(false);
  
      // Handle change in input fields
      const handleChange = (event) => {
          const { name, value } = event.target;

          // Update form data state
          setFormData(prevData => ({
              ...prevData,
              [name]: value
          }));
  
          // Validate field
          const error = validate(name, value);
          setErrors(prevErrors => ({
              ...prevErrors,
              [name]: error
          }));
          console.log("error",errors);
          console.log("form",formData);

         // if(error === ''){

            if(formData.date !== '' && formData.paymentMethod !== '' && formData.depositTo !== '' & formData.refno !== ''){
              
              if (errors.date === '' && errors.paymentMethod === '' && errors.depositTo === '' && errors.refno === '') {
                // Form is valid, handle submission
                console.log("inner",error);
                setIsActive(true);
              }else{
                setIsActive(false);
              }
            }else{
              setIsActive(false);
            }
         

      };
  
      // Handle form submission
      const handleSubmit = (event) => {
        event.preventDefault();

        // Validate all fields
        const newErrors = {
            refno: validate('refno', formData.refno),
            paymentMethod: validate('paymentMethod', formData.paymentMethod),
            depositTo: validate('depositTo', formData.depositTo),
            date: validate('date', formData.date)
        };
        setErrors(newErrors);
       

        // Check if there are any errors
        if (!newErrors.date && !newErrors.paymentMethod && !newErrors.depositTo) {
            // Form is valid, handle submission
            //console.log('Form submitted:', formData);
            setIsActive(true);
        }else{
          setIsActive(false);
        }
      };

      const handleClose = ()=>{
        onClose();
        setFormData({ date:'',paymentMethod:'' ,refno:'',depositTo:''});
        setErrors({ date:'' ,paymentMethod:'',refno:'',depositTo:''});
      };
  
  return (
    <>
      
        <form onSubmit={handleSubmit} className='bg-white h-screen w-[500px] px-5 py-5 font-sans content-between grid'>
          <div>
            <div className="bg-white h-10 flex">
            <div className='grid grid-cols-1 gap-1 p-2 '>
                <h1 className='text-center font-semibold text-blueRibbon text-2xl w-[440px]'>Invoice # 1001</h1>
            </div>
            <button type='button' onClick={handleClose} className='-ml-8'>
            <img src="cross-icon.png" className='w-6 h-6 mt-3' />
            </button>
            </div>
            <div className='flex justify-center'>
            <div className='w-[450px]'>
            <div className='flex justify-between py-2 mt-10'>
                <div>
                    <lable className="text-base font-light">Due date</lable>
                    <h1 className='text-lg font-semibold'>06/05/2024</h1>
                </div>
                <div>
                    <lable>Original amount</lable>
                    <h1 className='text-lg font-bold text-right'>$125.00</h1>
                </div>
            </div>
            <div>
            <div className="w-[450px] py-2">
                <lable className="text-base font-light">Payment date *</lable>
                <input type="date" name="date" value={formData.date}
                 onChange={handleChange} 
                 className={`${
                    errors.date
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } w-[450px] h-[45px] p-2 outline-none cursor-pointer border rounded-md`}
                  />
               {errors.date && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px] mr-1" />
                        {errors.date}
                      </span>
                    )}
              </div>
            </div>
            <div className="w-full text-sm py-2">
                <lable className="text-base font-light">Payment method *</lable>
                <select name="paymentMethod" value={formData.paymentMethod}
                 onChange={handleChange} 
                 className={`${
                    errors.paymentMethod
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } w-[450px] h-[45px] p-2 outline-none cursor-pointer border rounded-md`}
                 >
                    <option>Choose payment method</option>
                    <option>payment method 1</option>
                </select>
                {errors.paymentMethod && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px] mr-1" />
                        {errors.paymentMethod}
                      </span>
                    )}
            </div>
            <div className="w-full text-sm py-2">
                <lable className="text-base font-light">Reference no.</lable>
                <input type="text" 
                 name="refno"
                 value={formData.refno}
                 onChange={handleChange}
                 className="border-bombay border focus:border-lochmara focus:outline-0
                  w-[450px] h-[45px] p-2 outline-none cursor-pointer rounded-md"
                />
                
            </div>
            <div className="w-full text-sm py-2">
                <lable className="text-base font-light">Deposit to *</lable>
                <select name="depositTo" value={formData.depositTo}
                 onChange={handleChange} 
                 className={`${
                    errors.depositTo
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } w-[450px] h-[45px] p-2 outline-none cursor-pointer border rounded-md`}
                 >
                    <option>Cash</option>
                    <option>Cash</option>
                </select>
                {errors.depositTo && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px] mr-1" />
                        {errors.depositTo}
                      </span>
                    )}
            </div>
            <div className="w-full text-sm py-2">
                <lable className="text-base font-light">Amount received</lable>
                <input type="number" disabled className="w-[450px] h-[45px] p-2 outline-none cursor-pointer border border-bombay rounded-md" placeholder="$125.00"/>
            </div>
            </div>
            </div>
          </div>
          <div className=''>
              <div className='flex justify-center gap-4'>
              <button type="reset" onClick={handleClose} className="uppercase bg-pearl text-white text-sm w-[200px] h-[36px] rounded-[10px] my-5 font-semibold">{t("cancel")}</button>
                  <button type="submit" role="button"
                  //className='uppercase bg-lochmara text-white w-[200px] h-[35px] rounded-[10px] my-5  text-sm font-bold content-center'
                  className={`${
                    isActive ? "bg-lochmara" : "bg-havelock"
                  } uppercase text-white w-[200px] h-[36px] rounded-[10px] my-5 text-sm font-bold content-center`}
                  disabled={!isActive ? true : false}
                  >{t("save")}</button>
              </div>
          </div>
        </form>
      
    </>
  )
}

export default ReceivePayment