import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import validation from "../../utils/validation.js";
//import { validate } from "../../utils/formValidation.js";
//import { validatePasswordMatch } from "../../utils/passwordValidation.js";
import Tooltip from "../../utils/toolTip.js";
import Mainpages from "../Mainpages.js";
import Success from "../success-popup/Success.js";
import Error from "../error-popup/Error.js";


const Register = () => {
  const { t } = useTranslation();

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });
  // const [errors, setErrors] = useState({
  //   fname: "",
  //   lname: "",
  //   email: "",
  //   password: "",
  //   confirmpassword: "",
  // });

  const [errors, setErrors] = useState({});
  const [showpwd, setShowPwd] = useState(true);
  const [showcfmpwd, setShowcfmPwd] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [termcondition, setTermCondition] = useState(false);
  const [isSubmit,SetIsSubmit] = useState(false);
  const [isChecked,setIsChecked] = useState(false)
  const validationErrors = {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (formData.fname !== "") {
      if (!formData.fname.trim()) {
        validationErrors.fname = t("providefirstname");
      } else if (!validation.firstName.pattern.value.test(formData.fname)) {
        validationErrors.fname = t("firstletteralpha");
      }
    }
    if (formData.lname !== "") {
      if (!formData.lname.trim()) {
      } else if (!validation.firstName.pattern.value.test(formData.lname)) {
        validationErrors.lname = t("firstletteralpha");
      }
    }
    if (formData.email !== "") {
      if (!formData.email.trim()) {
        validationErrors.email = t("provideemail");
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
        validationErrors.email = t("invalidemail");
      }
    }
    console.log("pwdlength ",formData.password.length)
    if (formData.password !== "") {
      if (formData.password.length < 6) {
        validationErrors.password = validation.password.min.message;
      } else if (!validation.password.pattern.value.test(formData.password)) {
        validationErrors.password = validation.password.pattern.message;
      }
    }
    if (formData.confirmpassword !== "") {
      if (!formData.confirmpassword.trim()) {
        validationErrors.confirmpassword = t("cfmpwd");
      } else if (formData.password !== formData.confirmpassword) {
        validationErrors.confirmpassword = t("pwdnotmatched");
      }
    }
  
    setErrors(validationErrors);
    let length = Object.keys(validationErrors).length;
    if (length === 0) {
      if (formData.fname !== "" && formData.lname !== "" && formData.email !== "" && formData.password !== "" && formData.confirmpassword !== "") {
        if(!termcondition){
            setTermCondition((preventDefault) => !preventDefault);
        }
        
      }
    } else {
      setTermCondition(false);
      setIsChecked(false)
    }
  };


  const togglePassword = (e) => {
    setShowPwd((preventDefault) => !preventDefault);
  };
  const togglecfmPassword = (e) => {
    setShowcfmPwd((preventDefault) => !preventDefault);
  };
  const toggleTC = (e) => {
    console.log(validationErrors);
    setIsChecked((preventDefault) => !preventDefault)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.preventDefault());

    if (
      Object.keys(validationErrors).length === 0 &&
      formData.fname !== "" &&
      formData.lname !== "" &&
      formData.email !== "" &&
      formData.password !== "" &&
      formData.confirmpassword !== ""
    ) {
      setIsSuccessDialogOpen(true);
    } else {
      setIsErrorDialogOpen(true);
    }
  };

  // const handleCloseDialog = () => {
  //   setIsDialogOpen(false);
  // };


  //-------------separate validation file -------------------------------------------//
   // Handle change in input fields
//    const handleChange = (event) => {
//     const { name, value } = event.target;
//     console.log("pwd",formData.password, formData.confirmPassword)

//     // Update form data state
//     setFormData(prevData => ({
//         ...prevData,
//         [name]: value
//     }));

//       // Validate field
//       const error = validatePasswordMatch({ ...formData, [name]: value });
//       setErrors(error);
    
//     // const validationErrors = validatePasswordMatch(formData.password, formData.confirmPassword);
    
//     // setErrors(({
//     //   [name]: validationErrors
//     // }));
    
//     console.log("errors : ",errors)
//     if (!errors.fname && !errors.lname && !errors.email && !errors.password && !errors.confirmpassword) {
//       // Form is valid, handle submission
//       if(!termcondition){
//           setTermCondition((preventDefault) => !preventDefault);
//       }
//       console.log('Form submitted:', formData);
//   }

// };

// // Handle form submission
// const handleSubmit = (event) => {
//   event.preventDefault();

//   // Validate all fields
//   const newErrors = {
//       fname: validate('fname', formData.fname),
//       lname: validate('lname', formData.lname),
//       email: validate('email', formData.email),
//       password: validate('password', formData.password),
//       confirmpassword: validate('confirmpassword', formData.confirmpassword)
//   };
//   setErrors(newErrors);
 

//   // Check if there are any errors
//   if (!newErrors.date && !newErrors.paymentMethod && !newErrors.depositTo && !newErrors.refno) {
//       // Form is valid, handle submission
//       if(!termcondition){
//           setTermCondition((preventDefault) => !preventDefault);
//       }
//       console.log('Form submitted:', formData);
//   }
// };

  return (
    <>
      <Mainpages>
      <div className="w-full h-auto py-[25px] content-center">
            <h1 className="text-center font-semibold font-sans pb-10 pt-2 text-2xl" name="heading">
              {t("createaccount")}
            </h1>
            <div className="justify-center flex px-[47px]">
              <form className="w-full max-w-lg h-full" onSubmit={handleSubmit}>
                <div className="flex justify-between px-2 pb-4">
                  <div className="w-[300px]">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-fname"
                    >
                      {t("firstname")} *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-fname"
                        name="fname"
                        type="text"
                        onInputCapture={handleChange}
                        className={`${
                          errors.fname
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                       {errors.fname && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.fname}</p>
                      </span>
                    )}
                    </div>

                   
                  </div>
                  <div className="w-[120px]">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-mname"
                    >
                      {t("middlename")}
                    </label>
                    <input
                      id="grid-mname"
                      name="mname"
                      type="text"
                      onChangeCapture={handleChange}
                      className="w-full h-[35px] border-pearl rounded-[10px] 
                      focus:outline-none border focus:border-lochmara px-2"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 pb-4">
                  <div className="w-full px-5">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-lname"
                    >
                      {t("lastname")} *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-lname"
                        name="lname"
                        type="text"
                        onChangeCapture={handleChange}
                        className={`${
                          errors.lname
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                       {errors.lname && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.lname}</p>
                      </span>
                    )}
                    </div>
                   
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 pb-4">
                  <div className="w-full px-5">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-email"
                    >
                      {t("emailaddress")} *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-email"
                        name="email"
                        type="text"
                        onChangeCapture={handleChange}
                        className={`${
                          errors.email
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      {errors.email && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.email}</p>
                      </span>
                    )}
                    </div>
                    
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 pb-4">
                  <div className="w-full px-5">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-password"
                    >
                      {t("pwd")} *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-password"
                        name="password"
                        type={`${!showpwd ? "text" : "password"}`}
                        onChangeCapture={handleChange}
                        maxLength={8}
                        className={`${
                          errors.password
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      <div
                        className="absolute inset-y-0 right-2 flex items-center"
                        onClick={togglePassword}
                      >
                        {showpwd ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {errors.password && (
                    <Tooltip message={errors.password}>
                         <span className="text-errorRed text-base flex italic mt-1 rounded bg-amber-500 shadow-sm">
                          <img src="alert.png" className="w-[18px] h-[18px]" />
                          <p className="h-[17px] text-sm px-1"> Invalid password...</p>
                       </span>
                    </Tooltip>
                    )}
                  </div>
                    
                </div>
                <div className="flex flex-wrap -mx-3 pb-4">
                  <div className="w-full px-5">
                    <label
                      className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                      for="grid-cfmpwd"
                    >
                      {t("confirmpwd")} *
                    </label>
                    
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-cfmpwd"
                        name="confirmpassword"
                        type={`${!showcfmpwd ? "text" : "password"}`}
                        onChange={handleChange}
                        className={`${
                          errors.confirmpassword
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl focus:border-lochmara border focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      <div
                        className="absolute inset-y-0 right-2 flex items-center"
                        onClick={togglecfmPassword}
                      >
                        {showcfmpwd ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {errors.confirmpassword && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.confirmpassword}</p>
                        
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center pl-2 justify-between mb-4 mt-2">
                  <div className="flex justify-between">
                    <div className="flex items-center h-5">
                      <input
                        id="agree"
                        aria-describedby="agree"
                        type="checkbox"
                        name="termcondition"
                        className="w-[20.04px] h-[20.17px] border border-gray-300 rounded accent-green-500 bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                        onChange={toggleTC}
                        disabled={!termcondition ? true : false}
                        checked={isChecked ? true : false}
                      />
                    </div>
                    <div className="ml-3 pb-4 content-center text-base leading-[18px]">
                      <label
                        for="agree"
                        className="text-gray-700 font-normal font-sans dark:text-gray-700"
                      >
                        {t("agreetermcondition")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-[100%] gap-4">
                  <button
                    type="reset"
                    name="reset"
                    className="uppercase bg-pearl text-white font-semibold text-base w-[200px] h-[36px] rounded-[10px]"
                  >
                    {t("reset")}
                  </button>
                  <button
                    type="submit"
                    name="submit"
                    role="button"
                    className={`${
                      isChecked ? "bg-lochmara" : "bg-havelock"
                    } uppercase text-white text-base font-semibold w-[200px] h-[36px] rounded-[10px]`}
                    disabled={isChecked ? false : true}
                  >
                    {t("submit")}
                  </button>
                </div>
              </form>
            </div>
      </div>
      </Mainpages>
      {isSuccessDialogOpen && (
        <Success title="Success" icon="check-circle.png" message={t("accountcreated")} setIsSuccessDialogOpen={setIsSuccessDialogOpen} />
      )}

      {isErrorDialogOpen && (
        <Error title="Error" icon="error-icon.png" message={t("invalidemailpwd")} setIsErrorDialogOpen={setIsErrorDialogOpen} />
      )}


    </>
  );
};

export default Register;
