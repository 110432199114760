import React from 'react'
import CustomDropdown from '../custom-dropdown/CustomDropdown'

const TransactionList = () => {
  return (
    <>
         <div className="">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-4">
                    <div className="">
                        <div className="text-sm font-medium font-sans">
                             <lable>Type</lable>
                        </div>
                        <div className="text-sm font-normal font-sans">
                            <select className="w-[199px] h-[36px] border-slate-400 border px-2 py-1 rounded-sm">
                                <option>All Transactions</option>
                            </select>
                        </div>
                    </div>
                    <div className="">
                        <div className="text-sm font-medium font-sans">
                             <lable>Date</lable>
                        </div>
                        <div className="text-sm font-normal font-sans">
                            <select className="w-[199px] h-[36px] border-slate-400 border px-2 py-1 rounded-sm">
                                <option>All</option>
                            </select>
                        </div>
                    </div>
                    <div className="">
                        <div className="text-sm font-medium font-sans">
                             <lable>Status</lable>
                        </div>
                        <div className="text-sm font-normal font-sans">
                            <select className="w-[199px] h-[36px] border-slate-400 border px-2 py-1 rounded-sm">
                                <option>All</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex content-center py-5 px-5">
                    <div className="w-6 h-6">
                       <img src="blob.png"/>
                    </div>
                    <div className="w-6 h-6">
                       <img src="icon8.png"/>
                    </div>
                    <div className="w-6 h-6">
                       <img src="printer.jpg"/>
                    </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                    <tbody className="text-sm">
                        <tr className="border-b-2 border-bombay text-left uppercase font-sans font-semibold">
                            <th className="w-[10%]">No</th>
                            <th className="w-[20%]">Customer</th>
                            <th className="w-[22%]">Memo</th>
                            <th className="w-[10%]">Amount</th>
                            <th className="w-[15%]">Status</th>
                            <th className="w-[22%]">Action</th>
                        </tr>
                        <tr className="border-b-2 border-bombay font-sans">
                            <td className="py-2">1001</td>
                            <td className="py-2">Sample Customer Name</td>
                            <td className="py-2">Sample Memo</td>
                            <td className="py-2">$400.00</td>
                            <td className="py-2">Overdue 16 days</td>
                            <td className="py-2 flex cursor-pointer">
                                <a className="pr-1 text-lochmara font-semibold">Edit</a> |
                                <a className="px-1 text-lochmara font-semibold"> Receive Payment</a> |
                                <CustomDropdown
                                  options={[{value :'view',option:'View/Edit'}, {value :'/invoicecopy',option:'Duplicate'}, {value :'pdf',option:'Generate pdf'}, {value :'print',option:'Print'}, {value :'void',option:'Void'}, {value :'delete',option:'Delete'}]}
                                 
                                />
                            </td>
                        </tr>
                        <tr className="border-b-2 border-bombay font-sans">
                            <td className="py-2">1002</td>
                            <td className="py-2">Sample Customer Name</td>
                            <td className="py-2">Sample Memo</td>
                            <td className="py-2">$400.00</td>
                            <td className="py-2">Overdue 16 days</td>
                            <td className="py-2 flex cursor-pointer">
                                <a className="pr-1 text-lochmara font-semibold">Edit</a> |
                                <a className="px-1 text-lochmara font-semibold"> Receive Payment</a> |
                                <CustomDropdown
                                  options={[{value :'view',option:'View/Edit'}, {value :'/invoicecopy',option:'Duplicate'}, {value :'pdf',option:'Generate pdf'}, {value :'print',option:'Print'}, {value :'void',option:'Void'}, {value :'delete',option:'Delete'}]}
                                 
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
         </div>
    </>
  )
}

export default TransactionList