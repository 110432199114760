import React from "react";
import { useTranslation } from "react-i18next";

const AddCategory = ({onSubmit,onClose}) => {
    const { t } = useTranslation();
  return (
    <>

<div className=" bg-white w-full h-full overflow-y-scroll px-7 font-sans">
        <div className="">
          <div className="w-full">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
              <h1>Category information</h1>
              </div>
            </div>
            <div className="py-24">
              <lable className="text-sm font-semibold">Name *</lable>
              <div>
                <textarea
                  type="text"
                  className="w-[500px] h-[69px] border border-pearl focus:border-lochmara focus:outline-0 p-2"
                />
              </div>
            </div>
            <div className="w-full justify-end flex pt-[300px] content-end">
              <div className="flex justify-between">
                <button
                  onClick={onClose}
                  className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
                >
                  {t("cancel")}
                </button>
                <button
                  type="submit"
                  role="button"
                  onClick={onSubmit}
                  className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
