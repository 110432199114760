import React from 'react'
import LanguageSelector from './LanguageSelector'

const Logo = () => {
  return (
    <>
      <div className='w-full'>
        <div className='bg-white w-full h-[65px] flex justify-between'>
        <div className='content-center'>
           <img src='image.png' className='w-[124px] h-[40px] ml-4'/>
        </div>
        <div className='content-center'>
        <LanguageSelector/>
        </div>
        </div>
        
      </div>
    </>
  )
}

export default Logo