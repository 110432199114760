import React,{useState} from "react";
import CustomDropdown from "../../../../../components/custom-dropdown/CustomDropdown";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal";

const EditBasicInfo = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false);
  const [isDepartmentModalClose, setIsDepartmentModalClose] = useState(false);

  const [isDesignationModalOpen, setIsDesignationModalOpen] = useState(false);
  const [isDesignationModalClose, setIsDesignationModalClose] = useState(false);

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isLocationModalClose, setIsLocationModalClose] = useState(false);

  const openDesignationModal = () => setIsDesignationModalOpen(true);
  const closeDesignationModal = () => setIsDesignationModalOpen(false);

  const openDepartmentModal = () => setIsDepartmentModalOpen(true);
  const closeDepartmentModal = () => setIsDepartmentModalOpen(false);

  const openLocationModal = () => setIsLocationModalOpen(true);
  const closeLocationModal = () => setIsLocationModalOpen(false);

  const handleSelect = (value) => {
    if (value === "department") {
      openDepartmentModal();
    }
    if(value == "designation"){
      openDesignationModal();
    }
    if(value == "location"){
      openLocationModal();
    }
    
  };
  return (
    <>
      <div className=" bg-white w-full h-full grid grid-cols content-between overflow-y-scroll font-sans">
        <div className="">
          <div className="w-full px-7 pb-3">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
                <h1>Update Employee Basic & Work Information</h1>
              </div>
            </div>
            <div>
              <p className="uppercase text-lochmara text-base font-semibold">
                BASIC INFORMATION
              </p>
            </div>
            <div className="w-full flex">
              <div>
                <div className="flex gap-5">
                  <div>
                    <lable className="text-sm font-semibold">
                      Employee ID *
                    </lable>
                    <div>
                      <input
                        type="text"
                        className="w-[100px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div>
                    <lable className="text-sm font-semibold">
                      First Name *
                    </lable>
                    <div>
                      <input
                        type="text"
                        className="w-[380px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-6 py-2">
                  <div>
                    <lable className="text-sm font-semibold">Last Name *</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[325px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div>
                    <lable className="text-sm font-semibold">Middle Name</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[150px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <lable className="text-sm font-semibold">
                    Personal Email Address
                  </lable>
                  <div>
                    <input
                      type="text"
                      className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2"
                    />
                  </div>
                </div>
              </div>
              <div className="content-center pt-6 pl-2 flex">
                <div className="border border-pearl mr-2">
                  <img src="default-img.png" className="w-[166px] h-[166px]" />
                </div>
                <div className="content-end">
                  <img src="edit.png" />
                  <img src="trash-bin-icon.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="border-b w-full border-pearl"></div>
          <div className="w-full px-7 pb-3 pt-2">
            <div>
              <p className="uppercase text-lochmara text-base font-semibold">
                WORK INFORMATION
              </p>
            </div>
            <div className="w-full flex">
              <div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Department *
                    </lable>
                    <CustomDropdown
                      options={[
                        { value: "department", option: "Add Department" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Designation *
                    </lable>
                    <CustomDropdown
                      options={[
                        { value: "designation", option: "Add Designation" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Location *</lable>
                    <CustomDropdown
                      options={[{ value: "location", option: "Add Location" }]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                      onSelect={handleSelect}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Employment Type *
                    </lable>
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Employee Status *
                    </lable>
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Reporting Manager
                    </lable>
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[460px] py-1">
                    <lable className="text-sm font-semibold">
                      Work email address
                    </lable>
                    <div>
                      <input
                        type="text"
                        className="w-[460px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">
                      Date of Joining *
                    </lable>
                    <div>
                      <input
                        type="date"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex px-7 pb-4">
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isDepartmentModalOpen}
        width="w-[550px]"
        height="h-[250px]"
      >
        <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>Add Department</h1>
            </div>
          </div>
          <div className="w-full flex">
              <div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold font-sans">Department Name</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="w-full justify-end flex pt-9">
          <div className="flex justify-between">
            <button
              onClick={closeDepartmentModal}
              className="uppercase bg-pearl font-sans text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={closeDepartmentModal}
              className="uppercase bg-lochmara font-sans text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
        </div>
      </Modal>
      <Modal
        isOpen={isDesignationModalOpen}
        width="w-[550px]"
        height="h-[250px]"
      >
        <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>Add Designation</h1>
            </div>
          </div>
          <div className="w-full flex">
              <div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold font-sans">Designation Name</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="w-full justify-end flex pt-9">
          <div className="flex justify-between">
            <button
              onClick={closeDesignationModal}
              className="uppercase bg-pearl font-sans text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={closeDesignationModal}
              className="uppercase bg-lochmara font-sans text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
        </div>
      </Modal>
      <Modal
        isOpen={isLocationModalOpen}
        width="w-[550px]"
        height="h-[250px]"
      >
        <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>Add Location</h1>
            </div>
          </div>
          <div className="w-full flex">
              <div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold font-sans">Location Name</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="w-full justify-end flex pt-9">
          <div className="flex justify-between">
            <button
              onClick={closeLocationModal}
              className="uppercase bg-pearl font-sans text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={closeLocationModal}
              className="uppercase bg-lochmara font-sanstext-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
        </div>
      </Modal>
    </>
  );
};

export default EditBasicInfo;
