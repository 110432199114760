import React,{useState} from "react";
import SalaryRevision from "./salary-revision/SalaryRevision";
import Dialog from "../../../../components/Dialog";

const SalaryDetails = () => {
  const [isSalaryRevisionDialogOpen, setIsSalaryRevisionDialogOpen] =  useState(false);

  const handleSalaryRevisionDialog = () => {
    setIsSalaryRevisionDialogOpen((preventDefault) => !preventDefault);
  };
  return (
    <>
      <div className="font-sans w-full">
        <div>
          <p className="text-xl font-semibold">Salary Details</p>
          <p className="text-sm font-normal py-2">MONTHLY GROSSS SALARY</p>
        </div>
        <div className="flex justify-between">
          <p className="text-2xl font-bold">$ 2695.00</p>
          <button
            className="uppercase bg-lochmara text-sm font-sans font-bold justify-center text-white rounded-[10px] w-[200px] h-[36px] flex py-2"
            onClick={handleSalaryRevisionDialog}
          >
            REVISE SALARY
          </button>
        </div>
        <div className="w-full mx-auto py-4">
          <table className="w-full bg-white border border-pearl">
            <thead>
              <tr className="text-base font-normal">
                <td className="py-2 px-7 border-pearl border-b">
                  SALARY COMPONENTS
                </td>
                <td className="py-2 px-7 border-pearl border-b">
                  MONTHLY AMOUNT
                </td>
              </tr>
            </thead>
            <tbody className="my-4">
              <tr className="">
                <th className="py-2 pt-5 px-7 text-start">Basic</th>
                <td className="py-2 pt-5 px-7">
                  <p className="w-[210px] text-center">$2000.00</p></td>
              </tr>
              <tr className="">
                <th className="py-2 px-7 text-start">Housing Allowance</th>
                <td className="py-2 px-7">
                <p className="w-[210px] text-center">$250.00</p></td>
              </tr>
              <tr className="">
                <th className="py-2 px-7 text-start">
                  Transportation Allowance
                </th>
                <td className="py-2 px-7">
                <p className="w-[210px] text-center">$125.00</p></td>
              </tr>
              <tr className="">
                <th className="py-2 px-7 text-start">Meal Allowance</th>
                <td className="py-2 px-7">
                <p className="w-[210px] text-center">$200.00</p></td>
              </tr>
              <tr className="">
                <th className="py-2 pb-5 px-7 text-start">Daily Allowance</th>
                <td className="py-2 pb-5 px-7">
                <p className="w-[210px] text-center">$120.00</p></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
            <tfoot>
              <tr className="">
                <td className="py-2 px-7 border-pearl border-t text-base font-bold">
                  GROSS SALARY
                </td>
                <td className="py-2 px-7 border-pearl border-t">
                <p className="w-[210px] text-base font-bold text-center">$2695.00</p></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="container mx-auto py-4">
        <div>
          <p className="text-xl font-semibold">Salary Revision History</p>
        </div>
          <table className="min-w-full bg-white border border-pearl">
            <thead>
              <tr className="text-base font-normal">
                <td className="py-2 px-7 border-pearl border-b">
                PREVIOUS GROSS SALARY
                </td>
                <td className="py-2 px-7 border-pearl border-b">
                REVISED SALARY
                </td>
                <td className="py-2 px-7 border-pearl border-b">
                EFFECTIVE FROM
                </td>
                <td className="py-2 px-7 border-pearl border-b">
                  
                </td>
              </tr>
            </thead>
            <tbody className="my-4">
              <tr className="">
                <th className="py-2 pt-5 px-7 text-start text-sm font-semibold">$2245.00</th>
                <th className="py-2 pt-5 px-7 text-start text-sm font-semibold">$2695.00</th>
                <td className="py-2 pt-5 px-7 text-sm font-semibold">Mar, 2024</td>
                <td className="py-2 pt-5 px-7">
                  <a className="text-lochmara text-sm font-semibold">
                    View Details
                  </a>
                </td>
              </tr>
              <tr className="">
              <th className="py-2 pt-5 px-7 text-start text-sm font-semibold">-----</th>
                <th className="py-2 pt-5 px-7 text-start text-sm font-semibold">$2495.00</th>
                <td className="py-2 pt-5 px-7 text-sm font-semibold">Jan, 2024</td>
                <td className="py-2 pt-5 px-7">
                  <a className="text-lochmara text-sm font-semibold">
                    View Details
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Dialog
        isOpen={isSalaryRevisionDialogOpen}
        onClose={isSalaryRevisionDialogOpen}
        height="h-[825px]"
        width="w-[750px]"
      >
        <SalaryRevision
          onSubmit={handleSalaryRevisionDialog}
          onClose={handleSalaryRevisionDialog}
        />
      </Dialog>
    </>
  );
};

export default SalaryDetails;
