import React,{useRef,useState} from 'react'
import SelectAccount from '../select-account/SelectAccount';

const UploadFile = ({setIsUploadFileOpen,setIsSelectAccountOpen}) => {
  const fileInputRef = useRef(null);
  const [fileName,setFileName] =useState();

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleSelectAccountPopup = () => {
    setIsSelectAccountOpen(true)
    setIsUploadFileOpen(false);
  };

  const handleClose =()=>{
    setIsUploadFileOpen(false)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Selected file:', file.name);
      setFileName(file.name)
      // Handle the file upload logic here
    }
  };
  return (
    <>
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center bg-gray-800">
          <div className="text-center w-[500px] h-[500px] bg-white py-5 font-sans">
            <div className='w-[450px] h-[86px] mx-7 my-3 flex'>
              <p className='w-full text-4xl font-semibold text-start'>Manually upload your transactions</p>
              <div className="relative">
            <button
              onClick={handleClose}
              className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <img src="cross-icon.png" className="w-[18px] h-[18px] mx-2 -mt-4" />
            </button>
            
          </div>
            </div>
            
            <div className='w-[430px] h-[58px] ml-7 flex text-start text-sm my-3'>
              <p className='text-5xl font-semibold text-pearl'>1.</p>
              <p className='content-center ml-8'>Open a new tab and sign in to your online bank account.</p>
            </div>
            <div className='w-[430px] h-[58px] ml-7 flex text-start text-sm my-3'>
              <p className='text-5xl font-semibold text-pearl'>2.</p>
              <p className='content-center ml-7'>Export your bank statement in a .CSV</p>
            </div>
            <div className='w-[430px] h-[58px] ml-7 flex text-start text-sm'>
              <p className='text-5xl font-semibold text-pearl'>3.</p>
              <p className='content-center ml-7'>Upload your bank statement.</p>
            </div>
            <div className='uploadimg w-[446px] h-[72px] ml-7 my-3'>
              <div className='ml-16 h-9 content-center pt-5'>
                <div className='flex justify-between w-72'>
                  <img src='file-upload.png' className=''/>
                  <div className='content-center'>
                  <p className='flex text-sm leading-3'>Drag and dropdown or
                  <button className="text-royalBlue content-center ml-1" onClick={handleClick}>
                        select files
                      </button>
                      <input ref={fileInputRef} type="file" className="hidden" onChange={handleFileChange} />
                  </p>
                  <span className='text-sm leading-[1px]'>{fileName}</span>
                  </div>
                </div>
              </div>
              <div>
            </div>
            </div>
            <div className='flex justify-end px-6'>
              <button className='w-[200px] h-[35px] text-sm font-bold rounded-[10px] mt-6 uppercase bg-lochmara text-white'
              onClick={handleSelectAccountPopup}>continue</button>
            </div>
          </div>
        </div>
    </>
  )
}

export default UploadFile