import React from "react";
import Select from "../../../components/Select";

const MapField = ({ setIsMapFieldOpen,setIsSelectAccountOpen }) => {
  const handleClose = () => {
    setIsMapFieldOpen(false);
  };
  const handlePrevClick = ()=>{
    setIsMapFieldOpen(false);
    setIsSelectAccountOpen(true);
  }
  const options1 = [
    "Yes",
    "No",
    // Add more options as needed
  ];
  const options2 = [
    "One column",
    "Two column",
    "Three column",
    // Add more options as needed
  ];
  const options3 = [
    "Select a column from file",
    // Add more options as needed
  ];
  return (
    <>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center bg-gray-800">
        <div className="text-center w-[550px] h-[700px] bg-white py-5 font-sans">
          <div className="w-[480px] h-[86px] mx-12 pt-3 flex">
            <p className="w-full text-4xl font-semibold text-start">
              Let's set up your file
            </p>
            <div className="relative ">
              <button
                onClick={handleClose}
                className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
              >
                <img src="cross-icon.png" className="w-[18px] h-[18px] ml-4 -mt-3" />
              </button>
            </div>
          </div>
          <div>
            <div className="text-start ml-12 flex border-b-2 border-tpGray w-[395px] h-[24px] text-base">
              <p className="font-semibold pr-1">Step 1: </p>
              <p>Tell us about the format of your data</p>
            </div>
            <div className="text-start ml-12 py-2 text-sm">
              <p>Is the first row in your file a header?</p>
              <Select
                options={options1}
                placeholder="Yes"
                width="w-[360px]" // Set dynamic width here
              />
            </div>
            <div className="text-start ml-12 pb-2 text-sm">
              <p>How many columns show amounts?</p>
              <Select
                options={options2}
                placeholder="One column"
                width="w-[360px]" // Set dynamic width here
              />
            </div>
            <div className="text-start ml-12 pb-2 text-sm">
              <p>What’s the date format used in your file?</p>
              <Select
                options={options2}
                placeholder="Select a date format"
                width="w-[360px]" // Set dynamic width here
              />
            </div>
          </div>
          <div className="pt-2">
            <div className="text-start ml-12 flex border-b-2 border-tpGray w-[450px] h-[24px] text-base">
              <p className="font-semibold pr-1">Step 2:</p>
              <p>Select the fields that correspond to your file</p>
            </div>
            <div className="ml-12 w-[450px] py-3">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="w-full h-[35px]">
                    <td className="bg-pearl text-sm border-r-2 border-tpGray text-start px-2">Application Fields</td>
                    <td className="bg-pearl text-sm text-start px-4">Columns for your file</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-sm">
                    <td className="py-2 border-r-2 border-tpGray text-start px-2">Date</td>
                    <td className="py-3">
                      <Select
                        options={options3}
                        placeholder="Select a column from file"
                        width="w-[240px]" // Set dynamic width here
                      />
                    </td>
                  </tr>
                  <tr className="text-sm">
                    <td className="py-2 border-r-2 border-tpGray text-start px-2">Description</td>
                    <td className="py-2">
                      <Select
                        options={options3}
                        placeholder="Select a column from file"
                        width="w-[240px]" // Set dynamic width here
                      />
                    </td>
                  </tr>
                  <tr className="text-sm">
                    <td className="py-2 border-r-2 border-tpGray text-start px-2">Amount</td>
                    <td className="py-2">
                      <Select
                        options={options3}
                        placeholder="Select a column from file"
                        width="w-[240px]" // Set dynamic width here
                      />
                    </td>
                  </tr>
                  <tr className="text-sm">
                    <td className="py-2 border-r-2 border-tpGray text-start px-2">Check No (Optional)</td>
                    <td className="py-2">
                      <Select
                        options={options3}
                        placeholder="Select a column from file"
                        width="w-[240px]" // Set dynamic width here
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-center px-6 gap-4">
          <button className="w-[200px] h-[35px] text-sm font-bold rounded-[10px] mt-4 uppercase bg-pearl text-white"
          onClick={handlePrevClick}>
              back
            </button>
            <button className="w-[200px] h-[35px] text-sm font-bold rounded-[10px] mt-4 uppercase bg-lochmara text-white">
              import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapField;
