// src/components/ChangePasswordForm.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import validation from '../../utils/validation';
import Tooltip from '../../utils/toolTip';

const ChangePasswordForm = ({ onSubmit, onClose }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showpwd, setShowPwd] = useState(true);
  const [showcfmpwd, setShowcfmPwd] = useState(true);
  const validationErrors = {};
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(password);
    setPassword('');
    setConfirmPassword('');
  };

  const handleChage = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formData.password !== "") {
      if (!formData.password.trim()) {
        validationErrors.password = t("providepwd");
      } else if (!validation.password.pattern.value.test(formData.password)) {
        validationErrors.password = validation.password.pattern.message;
      }
    }
    if (formData.confirmpassword !== "") {
      if (!formData.confirmpassword.trim()) {
        validationErrors.confirmpassword = t("cfmpwd");
      } else if (formData.password !== formData.confirmpassword) {
        validationErrors.confirmpassword = t("pwdnotmatched");
      }
    }
  
    setErrors(validationErrors);
    
  };

  const togglePassword = (e) => {
    setShowPwd((preventDefault) => !preventDefault);
  };
  const togglecfmPassword = (e) => {
    setShowcfmPwd((preventDefault) => !preventDefault);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 pt-5 w-[440px]">
      <h2 className="text-center text-2xl font-sans font-semibold pb-2">Change Password</h2>
      <div className=''>
        <label htmlFor="password" className="block text-base font-normal font-sans text-gray-700">
         {t("pwd")} *
        </label>
       
        <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-password"
                        name="password"
                        type={`${!showpwd ? "text" : "password"}`}
                        onChangeCapture={handleChage}
                        className={`${
                          errors.password
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      <div
                        className="absolute inset-y-0 right-2 flex items-center"
                        onClick={togglePassword}
                      >
                        {showpwd ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
        </div>
        {errors.password && (
                    <Tooltip message={errors.password}>
                         <span className="text-errorRed text-base flex italic mt-1 rounded bg-amber-500 shadow-sm">
                          <img src="alert.png" className="w-[18px] h-[18px]" />
                          <p className="h-[17px] text-sm px-1"> Invalid password...</p>
                       </span>
                    </Tooltip>
                    )}
      </div>
      <div>
        <label htmlFor="confirmPassword" className="block text-base font-normal font-sans text-gray-700">
        {t("confirmpwd")} *
        </label>
        <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-cfmpwd"
                        name="confirmpassword"
                        type={`${!showcfmpwd ? "text" : "password"}`}
                        onChange={handleChage}
                        className={`${
                          errors.confirmpassword
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl focus:border-lochmara border focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      <div
                        className="absolute inset-y-0 right-2 flex items-center"
                        onClick={togglecfmPassword}
                      >
                        {showcfmpwd ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {errors.confirmpassword && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.confirmpassword}</p>
                        
                      </span>
                    )}
      </div>
      <div className='text-right space-x-2 lg:space-x-6'>
                <button type="submit" className="uppercase bg-pearl text-white text-xs w-[200px] h-[35px] rounded-[10px] my-5 font-bold">{t("cancel")}</button>
                <button type="submit" role="button" 
                className={`${
                  true ? "bg-lochmara" : "bg-havelock"
                } uppercase text-white w-[200px] h-[35px] text-xs rounded-[10px] my-5 font-bold content-center`}
                disabled={true == true ? false : true}
                >{t("save")}</button>
            </div>
    </form>
  );
};

export default ChangePasswordForm;
