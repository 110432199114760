import React, { useState,useContext } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import validation from "../../utils/validation"
// import SetupBusinessContext from "../../context/SetupBusinessContext"
// import { SetupBusinessProvider } from "../../context/SetupBusinessContext";

const BusinessInfo = () => {
  //const { formData, setFormData } = useContext(SetupBusinessContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

   const gotToNextPage = () => {
   navigate("/BusinessEntityTypeForTax");
   };

  const [formData, setFormData] = useState({
    legalbusinessname: "",
    dba: "",
    streetaddress: "",
    suiteno: "",
    city: "",
    state: "",
    country: "",
  });

  const [errors, setErrors] = useState({});
  const [isActive, setIsActive] = useState(false);
  const validationErrors = {};

  const handleChage = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // const { name, value } = e.target;
    // setFormData(prevData => ({ ...prevData, [name]: value }));

    if (formData.legalbusinessname !== "") {
      if (!formData.legalbusinessname.trim()) {
        validationErrors.legalbusinessname = t("providelbn");
      }else if (!validation.firstName.pattern.value.test(formData.legalbusinessname)) {
        validationErrors.legalbusinessname = t("firstletteralpha");
      }
    }
    if (formData.streetaddress !== "") {
    if (!formData.streetaddress.trim()) {
      validationErrors.streetaddress = t("providestreetadd");
    }else if (!validation.firstName.pattern.value.test(formData.streetaddress)) {
      validationErrors.streetaddress = t("firstletteralpha");
    }
  }
  if (formData.city !== "") {
    if (!formData.city.trim()) {
        validationErrors.city = t("providecity");
    }else if (!validation.firstName.pattern.value.test(formData.city)) {
      validationErrors.city = t("firstletteralpha");
    }
  }

  if (formData.state !== "") {
    if (!formData.state.trim()) {
        validationErrors.state = t("providestate");
    }else if (!validation.firstName.pattern.value.test(formData.state)) {
      validationErrors.state = t("firstletteralpha");
    }
  }if (formData.country !== "") {
    if (!formData.country.trim()) {
        validationErrors.country = t("providecountry");
    }else if (!validation.firstName.pattern.value.test(formData.country)) {
      validationErrors.country = t("firstletteralpha");
    }
  }
  console.log("isActive",isActive)
  console.log(validationErrors)
    setErrors(validationErrors);
    let length = Object.keys(validationErrors).length;
    if (length === 0) {
      if (formData.legalbusinessname !== "" && formData.streetaddress !== "" && formData.city !== "" && formData.state !== "" && formData.country !== "") {
        console.log("formData",formData)
        if(!isActive){ 
        setIsActive((preventDefault) => !preventDefault)
        }
        
      }else{
        setIsActive(false)
      }
    } else {
      setIsActive(false)
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!formData.legalbusinessname.trim()) {
      validationErrors.legalbusinessname = t("providelbn");
    }
    if (!formData.streetaddress.trim()) {
      validationErrors.streetaddress = t("providestreetadd");
    }
    if (!formData.city.trim()) {
        validationErrors.city = t("providecity");
    }
    if (!formData.state.trim()) {
        validationErrors.state = t("providestate");
    }
    if (!formData.country.trim()) {
        validationErrors.country = t("providecountry");
    }

    

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: t("accountcreated"),
        showConfirmButton: true,
        confirmButtonColor: "#0072bb",
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        text: t("error"),
        showConfirmButton: true,
        confirmButtonText: t("ok"),
        confirmButtonColor: "#0072bb",
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div className="h-screen">
      <Logo />
      <div className="h-auto w-full content-center border-t-4 py-[1.8%] border-black">
        <div className="bg-white h-full w-full  content-center">
          <h1 className="text-center font-semibold font-sans text-2xl py-4
          " name="heading">
            {t("businessinfo")}
          </h1>
          <div className="flex justify-center h-auto">
            <form className="h-auto w-[440px]" onSubmit={handleSubmit}>
              <div className="flex flex-wrap pb-4 ">
                <div className="w-full">
                  <label
                    className="block tracking-wide text-gray-700 text-base leading-5 font-sans font-normal"
                    for="grid-legalbusinessname"
                  >
                    {t("legalbusinessname")} *
                  </label>

                  <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-legalbusinessname"
                        name="legalbusinessname"
                        type="text"
                        onChange={handleChage}
                        value={formData.legalbusinessname}
                        className={`${
                          errors.legalbusinessname
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
                     `}
                       maxLength={10}
                      />
                   </div>
                   {errors.legalbusinessname && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.legalbusinessname}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap pb-4">
                <div className="w-full">
                  <label
                    className="block tracking-wide w-full text-gray-700 text-base leading-5 font-sans font-normal"
                    for="grid-dba"
                  >
                    {t("DBA")}
                  </label>
                 <div className="relative rounded-md shadow-sm">
                      <input
                         id="grid-dba"
                         type="text"
                         name="dba"
                         onChange={handleChage}
                         value={formData.dba}
                         className={`${
                            errors.dba
                              ? "border border-errorRed focus:outline-none"
                              : "border-pearl border focus:border-lochmara focus:outline-0"
                          } w-full h-[35px] rounded-[10px] px-2
                        `}
                        maxLength={10}
                      />
                   </div>

                   {errors.dba && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.dba}</p>
                      </span>
                    )}

                </div>
              </div>
              <div className="flex flex-wrap pb-4">
                <div className="w-full">
                  <label
                    className="block tracking-wide w-full text-gray-700 text-base leading-5 font-sans font-normal"
                    for="grid-streetaddress"
                  >
                    {t("streetaddress")} *
                  </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-streetaddress"
                        type="text"
                        name="streetaddress"
                        onChange={handleChage}
                        value={formData.streetaddress}
                        maxLength={10}
                        className={`${
                          errors.streetaddress
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                   </div>

                   {errors.streetaddress && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.streetaddress}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap pb-4">
                <div className="w-full">
                  <label
                    className="block tracking-wide text-gray-700 text-base leading-5 w-full font-sans font-normal"
                    for="grid-suitenumber"
                  >
                    {t("suitenumber")}
                  </label>
                  
                  <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-suitenumber"
                        type="text"
                        name="suitenumber"
                        onChange={handleChage}
                        value={formData.suitenumber}
                        maxLength={10}
                        className={`${
                          errors.suitenumber
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                   </div>

                   {errors.suitenumber && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.suitenumber}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap pb-4">
                <div className="w-full">
                  <label
                    className="block tracking-wide text-gray-700 w-full text-base leading-5 font-sans font-normal"
                    for="grid-city"
                  >
                    {t("city")} *
                  </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-city"
                        type="text"
                        name="city"
                        onChange={handleChage}
                        value={formData.city}
                        maxLength={10}
                        className={`${
                          errors.city
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                   </div>
                   {errors.city && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.city}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap pb-4">
                <div className="w-full">
                  <label
                    className="block tracking-wide text-gray-700 w-full text-base leading-5 font-sans font-normal"
                    for="grid-state"
                  >
                    {t("state")} *
                  </label>
                     <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-state"
                        type="text"
                        name="state"
                        onChange={handleChage}
                        value={formData.state}
                        maxLength={10}
                        className={`${
                          errors.state
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                   </div>
                   {errors.state && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.state}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap pb-3">
                <div className="w-full">
                  <label
                    className="block tracking-wide text-gray-700 text-base w-full font-sans leading-5 font-normal"
                    for="grid-country"
                  >
                    {t("country")} *
                  </label>
                     <div className="relative rounded-md shadow-sm">
                      <input
                        id="grid-country"
                        type="text"
                        name="country"
                        onChange={handleChage}
                        value={formData.country}
                        maxLength={10}
                        className={`${
                          errors.country
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                   </div>
                   {errors.country && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.country}</p>
                      </span>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap float-right mt-3">
                <div className="w-full">
                  <button
                    className={`${
                      isActive ? "bg-lochmara" : "bg-havelock"
                    } uppercase text-white rounded-[10px] w-[200px] h-[35px] text-xs font-bold content-center`}
                    onClick={gotToNextPage}
                    disabled ={isActive ? false : true}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
