import React from "react";
import Layout from "../../Layout";
import Tabs from '../../../components/Tabs';
import RunPayroll from "./run-payroll/RunPayroll";

const PayRuns = () => {
    const tabs = [
        {
          id: 'runpayroll',
          title: 'Run Payroll',
          content: <RunPayroll/>,
        },
        {
          id: 'payrollhistory',
          title: 'Payroll History',
          content: '',
        },
      ];
  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-5 mb-2">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans mt-8 mb-11">
                <h1>Pay Runs</h1>
              </div>
            </div>
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PayRuns;
