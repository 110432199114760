import React from "react";
import Layout from "../../Layout";
import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import AddEditCustomer from "../add-edit-customer/AddEditCustomer";
import AddCustomerDialog from "../add-edit-customer/AddCustomerDialog";
import ReceivePayment from "../receive-payment/ReceivePayment";
import ReceivePaymentDialog from "../receive-payment/ReceivePaymentDialog";
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown";
import Dialog from "../../../components/Dialog";

const Customer = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogClose, setIsDialogClose] = useState(false);
  const [isReceivePaymentDialogOpen, setIsReceivePaymentDialogOpen] =
    useState(false);
  const [isReceivePaymentDialogClose, setIsReceivePaymentDialogClose] =
    useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };
  // const openDialog = () => setIsDialogOpen(true);
  // const closeDialog = () => setIsDialogClose(true);

  // const openPaymentDialog = () => setIsReceivePaymentDialogOpen(true);
  const closePaymentDialog = () => setIsReceivePaymentDialogOpen(false);

  const handleSubmitCustomer = () => {
    // Handle password submission logic here
    //console.log('Submitted password:', password);
    setIsDialogOpen((preventDefault) => !preventDefault);
  };
  const handleSubmitPayment = () => {
    // Handle password submission logic here
    //console.log('Submitted password:', password);
    //closePaymentDialog();
    setIsReceivePaymentDialogOpen((preventDefault) => !preventDefault);
  };

  const changeFunc = (e) => {
    console.log("onselect");
    const val = e.target.value;
    if (val === "view") {
      setIsDialogOpen(true);
    }
    if (val === "statement") {
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Layout>
        <div className=" bg-white w-full h-full overflow-y-scroll px-8 font-sans">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold my-10">
                <h1>Customers</h1>
              </div>
              <div className="w-full flex row gap-1 py-5">
                <div className="w-[23.55%] h-[115px]">
                  <h1 className="font-semibold text-3xl">$0</h1>
                  <p className="text-xs font-semibold text-greyText pb-5">
                    0 estimates
                  </p>
                  <div className="bg-royalBlue h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold text-3xl">$0</h1>
                  <p className="text-xs font-semibold text-greyText pb-5">
                    unbilled income
                  </p>
                  <div className="bg-seagull h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold text-3xl">$0</h1>
                  <p className="text-xs font-semibold text-greyText pb-5">
                    1 overdue invoice
                  </p>
                  <div className="bg-tango h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold text-3xl">$0</h1>
                  <p className="text-xs font-semibold text-greyText pb-5">
                    1 open invoice or credit
                  </p>
                  <div className="bg-bombay h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold text-3xl">$0</h1>
                  <p className="text-xs font-semibold text-greyText pb-5">
                    0 recently paid
                  </p>
                  <div className="bg-salem h-[30px] rounded-[5px]"></div>
                </div>
              </div>
              <div className="float-right">
                <button
                  className="uppercase font-semibold bg-lochmara text-sm justify-center text-white py-2 rounded-[10px] w-[200px] h-[35px]  flex"
                  onClick={handleSubmitCustomer}
                >
                  New Customer
                  <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                </button>
              </div>
            </div>
            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-1 py-3 w-full">
                  <div className="">
                    <div className="text-sm font-medium">
                      <lable>Search</lable>
                    </div>
                    <div className="text-sm font-normal">
                      <div className="relative">
                        <input
                          type="search"
                          placeholder="Search"
                          className="w-[250px] h-[36px] py-1 pl-2  border border-bombay rounded-md text-gray-800 focus:outline-none focus:ring-1 focus:ring-lochmara"
                        />
                        <button className="absolute inset-y-0 right-0 flex items-center px-2">
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-bombay hover:text-blue-500"
                          >
                            <path
                              d="M23 21l-5.875-5.875a8.5 8.5 0 1 0-1.25 1.25L21 21l2 2zM10 16a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex content-center py-5 px-5">
                  <div className="w-6 h-6">
                    <img src="blob.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="icon8.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="printer.jpg" />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="text-sm">
                    <tr className="border-b-2 border-bombay text-left uppercase">
                      <th className="w-[4%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[15%]">
                        <div className="flex">
                          Name
                          <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                        </div>
                      </th>
                      <th className="w-[23.55%]">Company Name</th>
                      <th className="w-[20%]">Phone</th>
                      <th className="w-[20%]">Open balance</th>
                      <th className="w-[25%]">Action</th>
                    </tr>
                    <tr className="border-b-2 border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">Jon Smith</td>
                      <td className="py-2">Sample Company Inc</td>
                      <td className="py-2">+1 999 999 9999</td>
                      <td className="py-2">$501.25</td>
                      <td className="py-2 flex cursor-pointer">
                        <a
                          className="text-royalBlue font-semibold pt-2"
                          onClick={handleSubmitPayment}
                        >
                         
                          Receive payment |
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              {
                                value: "statement",
                                option: "Create statement",
                              },
                              { value: "invoice", option: "Create invoice" },
                              {
                                value: "receipt",
                                option: "Create sales receipt",
                              },
                              { value: "inactive", option: "Make inactive" },
                            ]}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Dialog isOpen={isDialogOpen} onClose={isDialogClose} width="w-[700px]" height="h-[1400px]">
        <AddEditCustomer
          onSubmit={handleSubmitCustomer}
          onClose={handleSubmitCustomer}
        />
      </Dialog>

      <Dialog
        isOpen={isReceivePaymentDialogOpen}
        onClose={isReceivePaymentDialogClose}
        width="w-[500px]"
      >
        <ReceivePayment
          onSubmit={handleSubmitPayment}
          onClose={closePaymentDialog}
        />
      </Dialog>
    </>
  );
};

export default Customer;
