import React from 'react'
import CustomDropdown from '../../../../components/custom-dropdown/CustomDropdown'
import { FaChevronDown } from 'react-icons/fa'

const VendorTransactionList = () => {
    const handleSelect = (value) => {
        // if (value === "void") {
         
        //   openVoidModal();
        // }
        // if (value === "delete") {
         
        //   openDeleteModal();
        // }
        // if (value === "print") {
        //   openCustomDateModal();
        // }
      };
  return (
    <>
         <div className="">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-4">
                    <div className="">
                        <div className="text-sm font-medium font-sans">
                             <lable>Type</lable>
                        </div>
                        <div className="text-sm font-normal font-sans">
                            <select className="w-[199px] h-[36px] border-slate-400 border px-2 py-1 rounded-sm">
                                <option>All Transactions</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex content-center py-5 px-5">
                    <div className="w-6 h-6">
                       <img src="blob.png"/>
                    </div>
                    <div className="w-6 h-6">
                       <img src="icon8.png"/>
                    </div>
                    <div className="w-6 h-6">
                       <img src="printer.jpg"/>
                    </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                      <th className="w-[12%]">
                        <div className="flex">
                          DATE
                          {/* <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" /> */}
                        </div>
                      </th>
                      <th className="w-[10%]">TYPE</th>
                      <th className="w-[10%]">NO.</th>
                      <th className="w-[10%]">PAYEE</th>
                      <th className="w-[20%]">CATEGORY</th>
                      <th className="w-[15%] pl-6">TOTAL</th>
                      <th className="w-[22%] text-right">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Bill</td>
                      <td className="py-2">5243</td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">
                      <CustomDropdown
                        options={[{value :'alltransactions',option:'All Transactions'}, {value :'expense',option:'Expense'}, {value :'bill',option:'Bill'}, {value :'payment',option:'Bill payment'}]}
                        border="border border-pearl rounded-md"
                      />
                      </td>
                      <td className="py-2 pl-6">$400.00</td>
                      <td className="py-2  flex cursor-pointer justify-end">
                        <a className="pt-2 text-lochmara font-semibold">Schedule Payment |</a>{" "}
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "paid", option: "Mark as paid" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "delete", option: "Delete Bill" },
                            ]}
                            onSelect={handleSelect}
                            width='w-[185px]'
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Bill</td>
                      <td className="py-2">34254</td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">
                      <CustomDropdown
                        options={[{value :'alltransactions',option:'All Transactions'}, {value :'expense',option:'Expense'}, {value :'bill',option:'Bill'}, {value :'payment',option:'Bill payment'}]}
                        border="border border-pearl rounded-md"
                      />
                      </td>
                      <td className="py-2 pl-6">$400.00</td>
                      <td className="py-2 mt-1 flex cursor-pointer justify-end">
                        <a className="pt-2 text-lochmara font-semibold">View/Edit |</a>{" "}
                        <div className="">
                          <CustomDropdown
                            options={[
                                { value: "view", option: "View/Edit" },
                                { value: "paid", option: "Mark as paid" },
                                { value: "duplicate", option: "Duplicate" },
                                { value: "delete", option: "Delete Bill" },
                            ]}
                            onSelect={handleSelect}
                             width='w-[185px]'
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
         </div>
    </>
  )
}

export default VendorTransactionList