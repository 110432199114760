import React from 'react'
import { FaBars,FaUserCircle} from 'react-icons/fa'
import { IoNotificationsOutline ,IoChevronBackOutline} from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Swal from 'sweetalert2'
import ChangePasswordForm from '../change-password/ChangePassword'
import Dialog from '../change-password/ChangePasswordPopup'

const Navbar = ({sidebarToggle,setSidebarToggle}) => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        password: "",
        confirmpassword: "",
      });
    
      const [errors, setErrors] = useState({});

      const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleSubmitPassword = (password) => {
    // Handle password submission logic here
    console.log('Submitted password:', password);
    closeDialog();
  };
    
      const handleChage = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = {}
          if(!formData.password.trim()){
              validationErrors.password = t("providepwd");
          }else if(formData.password.length < 6){
              validationErrors.password = t("invalidpwd");
          }
          if (!formData.confirmpassword.trim()) {
            validationErrors.confirmpassword = t("cfmpwd");
          } else if (formData.confirmpassword !== formData.password) {
            validationErrors.confirmpassword = t("pwdnotmatched");
          }
          setErrors(validationErrors)
    
          if(Object.keys(validationErrors).length === 0){
          Swal.fire({
              position: "center",
              icon: "success",
              text: t("loginsuccesfully"),
              showConfirmButton: true,
              confirmButtonText: t("ok"),
              confirmButtonColor:"#3232ff",
              timer: 1500
            });
          }else{
              Swal.fire({
                  position: "center",
                  icon: "warning",
                  text: "Invalid Email Address and/or Password",
                  showConfirmButton: true,
                  confirmButtonColor:"#3232ff",
                  timer: 1500
                });
          }
      };
  return (
    <>
      <nav className={`${sidebarToggle ? "bg-white h-[100px] flex justify-between float-right w-full border-2 border-x-white border-b-blueRibbon border-t-white":"bg-white h-[100px] flex justify-between float-right w-[100%] border-2 border-t-white border-x-white border-b-blueRibbon"}`}>
        <div className='flex items-center text-base font-sans font-normal mt-4'>
            <IoChevronBackOutline className={`${sidebarToggle ? "hidden":""} text-blueRibbon me-4 ms-1 cursor-pointer`}
            onClick={()=>setSidebarToggle(!sidebarToggle)}/>
            <FaBars className={`${sidebarToggle ? "text-blueRibbon ms-4 me-4 cursor-pointer":"hidden"}`}
            onClick={()=>setSidebarToggle(!sidebarToggle)}/>
            <span className='text-blueRibbon font-sans font-semibold text-lg'>Combodia Inc</span>
        </div>
        <div className='flex items-center gap-x-5 me-7'>
            <IoNotificationsOutline className='w-10 h-9 mb-2 mt-4'/>
            <div className='relative'>
            <button className='group'>
                <FaUserCircle className='w-10 h-10 mb-2 mt-4 text-blueRibbon'/>
                <div className='z-10 hidden absolute rounded-md bg-white shadow-inner shadow-greyText w-[200px] h-[90px] group-focus:block top-full right-0 text-base font-medium text-left font-sans px-3 py-2'>
                    <ul>
                        <li><a href='#'>Profile</a></li>
                        <li><a onClick={openDialog}>Change Password</a></li>
                        <li><a href='#'>Logout</a></li>
                    </ul>
                </div>
            </button>
            </div>
        </div>
      </nav>
      <Dialog isOpen={isDialogOpen} onClose={closeDialog}>
          <div className="font-sans flex justify-center w-[550px] h-[350px] py-6">
          <ChangePasswordForm onSubmit={handleSubmitPassword} onClose={closeDialog} />
          </div>
        </Dialog>
    </>
  )
}

export default Navbar