import React from 'react'
import ReactDOM from 'react-dom';

const CustomDates = ({ isOpen, children }) => {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="text-center w-[500px] h-[250px] bg-white py-4 rounded-[10px] flex justify-center">
        {children}
      </div>
    </div>,
    document.body
  );
}

export default CustomDates