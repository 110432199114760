import React from 'react';
import SideMenu from '../sidemenu/SideMenu';
import Navbar from '../navbar/Navbar'
import { useState } from 'react';

const Dashboard = () => {
    const [sidebarToggle,setSidebarToggle] = useState(false)
    const [submenuToggle,setSubmenuToggle] = useState(false)
    const [open, setOpen] = React.useState(1);
   
    const handleOpen = (value) => {
      console.log(value);
      setOpen(open === value ? 0 : value);
    };
    return (
        <div className="flex h-screen overflow-hidden">
            {/* <Sidebar /> */}
            <SideMenu/>
            <div className="flex flex-col flex-1 overflow-hidden">
            <Navbar sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}/>
               
            </div>
        </div>
    );
};

export default Dashboard;
