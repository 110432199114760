import React from 'react'

const Success = ({title,message,icon, setIsSuccessDialogOpen}) => {
    const handleCloseDialog = () => {
        setIsSuccessDialogOpen(false);
    };

  return (
    <>
        <div className=" fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center bg-gray-800">
          <div className="text-center w-[400px] h-[250px] bg-white py-5">
            <img src={icon} className="text-center text-3xl mx-auto"/>
            <h3 className="w-full h-[29px] text-2xl font-semibold my-6 text-center content-center uppercase text-malachite font-sans">{title}</h3>
            <p className="text-sm font-sans font-normal h-10">{message}</p>
            <button
                      onClick={handleCloseDialog}
                      className="my-5 uppercase text-white bg-lochmara font-semibold text-base w-[200px] h-[36px] rounded-[10px]"
                      
                  >
                    OK
                  </button>
          </div>
        </div>
    </>
  )
}

export default Success