import React from 'react';
import SideMenu from '../components/sidemenu/SideMenu';
import Navbar from '../components/navbar/Navbar'
import { useState } from 'react';

const Layout = ({children}) => {

    const [sidebarToggle,setSidebarToggle] = useState(false)
   
    return (
        <div className="flex h-screen overflow-hidden min-w-[1280px] max-w-[1920px] mx-auto justify-center relative">
            <SideMenu sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}/>
            <div className="flex flex-col flex-1 overflow-hidden">
            <Navbar sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}/>
                {children}
            
            </div>
        </div>
    );
};

export default Layout;
