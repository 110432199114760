import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Swal from "sweetalert2";
import Mainpages from "../Mainpages";
import Tooltip from "../../utils/toolTip";
import validation from "../../utils/validation";

const Login = ({ submit }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [checked, setChecked] = useState(false);

  const [errors, setErrors] = useState({});
  const [showpwd, setShowPwd] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const validationErrors = {};

  const handleChage = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formData.email !== "") {
    if (!formData.email.trim()) {
      validationErrors.email = t("provideemail");
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      validationErrors.email = t("invalidemail");
    }
  }
  if (formData.password !== "") {
    if (formData.password.length < 6) {
      validationErrors.password = validation.password.min.message;
    } else if (!validation.password.pattern.value.test(formData.password)) {
      validationErrors.password = validation.password.pattern.message;
    }
  }
    setErrors(validationErrors);
    let length = Object.keys(validationErrors).length;
    if (length === 0) {
      if (
        formData.fname !== "" &&
        formData.lname !== "" &&
        formData.email !== "" &&
        formData.password !== "" &&
        formData.confirmpassword !== ""
      ) {
         setIsActive(true);
      }
    } else {
      setIsActive(false);
    }
  };

  const togglePassword = () => {
    setShowPwd((preventDefault) => !preventDefault);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //submit(formData);
    
   
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: t("loginsuccesfully"),
        showConfirmButton: true,
        confirmButtonText: t("ok"),
        confirmButtonColor: "#0072bb",
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        text: "Invalid Email Address and/or Password",
        showConfirmButton: true,
        confirmButtonColor: "#0072bb",
        timer: 1500,
      });
    }
  };
  return (
    <>
      <Mainpages>
        <div className="w-full py-1">
          <div className="flex justify-center">
            <div className="w-full pb-10">
              <h1
                className="uppercase text-center py-8 font-sans text-2xl font-semibold"
                name="heading"
              >
                {t("login")}
              </h1>
              <div className="flex justify-center">
                <form
                  className="w-full max-w-lg px-9"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-wrap mb-6">
                    <div className="w-full">
                      <label
                        className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                        for="grid-email"
                      >
                        {t("emailaddress")}
                      </label>
                      <div className="relative rounded-[10px] shadow-sm">
                      <input
                        id="grid-email"
                        name="email"
                        type="text"
                        
                        onChangeCapture={handleChage}
                        className={`${
                          errors.email
                          ? "border border-errorRed focus:outline-none"
                          : "border-pearl border focus:border-lochmara focus:outline-0"
                      } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                     
                    </div>
                    {errors.email && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-[18px] h-[18px]" />
                        <p className="h-[17px] text-sm px-1">{errors.email}</p>
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="flex flex-wrap mb-6">
                    <div className="w-full">
                      <label
                        className="block tracking-wide text-gray-700 text-base font-sans font-normal leading-5"
                        for="grid-password"
                      >
                        {t("pwd")}
                      </label>
                             
                   <div className="relative rounded-[10px] shadow-sm">
                      <input
                        id="grid-password"
                        name="password"
                        type={`${!showpwd ? "text" : "password"}`}
                        onChangeCapture={handleChage}
                        className={`${
                          errors.password
                            ? "border border-errorRed focus:outline-none"
                            : "border-pearl border focus:border-lochmara focus:outline-0"
                        } w-full h-[35px] rounded-[10px] px-2
           `}
                      />
                      <div className="absolute inset-y-0 right-2 flex items-center" onClick={togglePassword}>
                      {showpwd ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            )}
                       </div>
                    </div>
                    {errors.password && (
                    <Tooltip message={errors.password}>
                         <span className="text-errorRed text-base flex italic mt-1 rounded bg-amber-500 shadow-sm">
                          <img src="alert.png" className="w-[18px] h-[18px]" />
                          <p className="h-[17px] text-sm px-1"> Invalid password...</p>
                       </span>
                    </Tooltip>
                    )}
                    </div>
                  </div>
                  <div className="flex flex-wrap mb-6 justify-between content-center">
                    {/* <CheckboxLabel/> */}
                    <div className="gap-1 flex content-center">
                      <input
                        type="checkbox"
                        className="accent-green-500 w-5 h-[22px]"
                        defaultChecked={checked}
                        onChange={(e) => setChecked(!checked)}
                      />
                      <label
                        for="remember"
                        className="ml-1 text-gray-700 font-normal dark:text-gray-700 text-base font-sans"
                      >
                        {t("rememberme")}
                      </label>
                    </div>
                    <div className="flex content-center">
                      <a
                        href="#"
                        className="text-blueRibbon font-semibold text-base font-sans"
                      >
                        {t("forgetpwd")}
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-center w-[100%] gap-4 py-1 pt-5">
                    <button
                      type="cancel"
                      name="cancel"
                      className="uppercase bg-pearl text-white font-semibold text-base w-[200px] h-[36px] rounded-[10px]"
                      disabled
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="submit"
                      name="submit"
                      role="button"
                      className={`${
                        isActive ? "bg-lochmara" : "bg-havelock"
                      } uppercase text-white font-semibold text-base w-[200px] h-[36px] rounded-[10px]`}
                      disabled={!isActive ? true : false}
                  >
                    {t("login")}
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </Mainpages>
    </>
  );
};

export default Login;
