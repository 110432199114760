import React, { useState } from "react";
import Layout from "../Layout";
import { FaChevronDown } from "react-icons/fa";
import CustomDropdown from "../../components/custom-dropdown/CustomDropdown";
import AddProduct from "./add-product/product/AddProduct";
import Dialog from "../../components/Dialog";
import AddProductsAndServices from "./add-product/AddProductsAndServices";
import Modal from "../../components/Modal";

const ProductAndServices = () => {
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isProductDialogClose, setIsProductDialogClose] = useState(false);
  const [isInactiveModalOpen, setInactiveModalOpen] = useState(false);
  const [isMakeInactiveModalOpen, setMakeInactiveModalOpen] = useState(false);

  const openMakeInactiveModal = () => setMakeInactiveModalOpen(true);
  const closeMakeInactiveModal = () => setMakeInactiveModalOpen(false);
  const openInactiveModal = () => setInactiveModalOpen(true);
  const closeInactiveModal = () => setInactiveModalOpen(false);
  const makeInactive = () => {
    openMakeInactiveModal();
  };
  const handleSelect = (value) => {
    if (value === "inactive") {
      openMakeInactiveModal();
    }
    
  };

  const handleSubmitProduct = () => {
    setIsProductDialogOpen((preventDefault) => !preventDefault);
  };

  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-5 mb-2">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans mt-14 mb-11">
                <h1>Products and Services</h1>
              </div>
            </div>
            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-7">
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Search</lable>
                    </div>
                    <div className="text-sm font-normal">
                      <div className="relative">
                        <input
                          type="search"
                          placeholder="Find product and services"
                          className="w-[253px] h-[36px] py-1 pl-2  border border-bombay rounded-md text-gray-800 focus:outline-none focus:ring-1 focus:ring-lochmara"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="float-right flex gap-4 pr-1">
                  <button
                    className="uppercase bg-lochmara text-sm font-sans font-bold justify-center text-white rounded-[10px] w-[200px] h-[36px] flex py-2"
                    //onClick={handleSubmitExpense}
                  >
                    Import
                  </button>
                  <button
                    className="uppercase bg-lochmara text-sm font-sans font-bold justify-center text-white rounded-[10px] w-[200px] h-[36px] flex py-2"
                    onClick={handleSubmitProduct}
                  >
                    ADD PRODUCT/SERVICE
                  </button>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold uppercase">
                      <th className="w-[3%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[20%]">
                        <div className="flex ml-3">
                          Name
                          <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                        </div>
                      </th>
                      <th className="w-[10%]">Category</th>
                      <th className="w-[10%]">SKU</th>
                      <th className="w-[10%]">Type</th>
                      <th className="w-[15%]">Sales description</th>
                      <th className="w-[10%] text-right">Sales price</th>
                      <th className="w-[10%] text-right">Cost</th>
                      <th className="w-[15%] pr-2 text-right">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2 flex">
                        <img src="default-img.png" />
                        <p className="content-center">Product 1</p>
                      </td>
                      <td className="py-2">Grocery</td>
                      <td className="py-2">32564</td>
                      <td className="py-2">Product</td>
                      <td className="py-2">This is product</td>
                      <td className="py-2 text-right">3.25</td>
                      <td className="py-2 text-right">2.25</td>
                      <td className="">
                        <a className="flex justify-end">
                          <p className="content-center text-lochmara font-sans font-semibold text-sm">
                            Edit |
                          </p>
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "inactive", option: "Make Inactive" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2 flex">
                        <img src="default-img.png" />
                        <p className="content-center">Product 1</p>
                      </td>
                      <td className="py-2">Grocery</td>
                      <td className="py-2">32564</td>
                      <td className="py-2">Product</td>
                      <td className="py-2">This is product</td>
                      <td className="py-2 text-right">3.25</td>
                      <td className="py-2 text-right">2.25</td>
                      <td className="">
                        <a className="flex justify-end">
                          <p className="content-center text-lochmara font-sans font-semibold text-sm">
                            Edit |
                          </p>
                          <CustomDropdown
                             options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "inactive", option: "Make Inactive" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2 flex">
                        <img src="default-img.png" />
                        <p className="content-center">Product 1</p>
                      </td>
                      <td className="py-2">Grocery</td>
                      <td className="py-2">32564</td>
                      <td className="py-2">Product</td>
                      <td className="py-2">This is product</td>
                      <td className="py-2 text-right">3.25</td>
                      <td className="py-2 text-right">2.25</td>
                      <td className="">
                        <a className="flex justify-end">
                          <p className="content-center text-lochmara font-sans font-semibold text-sm">
                            Edit |
                          </p>
                          <CustomDropdown
                             options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "inactive", option: "Make Inactive" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={isMakeInactiveModalOpen}>
        <div className="relative">
          <button
            onClick={closeMakeInactiveModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img src="warning-icon.png" className="w-9 h-9 mx-auto mb-3" />
        </div>
        <p className="text-sm font-sans font-normal my-5 mt-9 px-7 text-start">
        Are you sure you want to make Grocery: Product 1 inactive?
        </p>
        <button
          onClick={closeMakeInactiveModal}
          className="my-5 uppercase font-sans text-white bg-lochmara font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          YES
        </button>
      </Modal>
      <Dialog
        isOpen={isProductDialogOpen}
        onClose={isProductDialogClose}
        height="h-[1080px]"
        width="w-[750px]"
      >
        <AddProductsAndServices
          onSubmit={handleSubmitProduct}
          onClose={handleSubmitProduct}
        />
      </Dialog>
    </>
  );
};

export default ProductAndServices;
