import React from 'react'
import { useTranslation } from 'react-i18next'

const MainText = () => {
  const {t} = useTranslation();
  return (
    // <div className='justify-center h-[184px]'>
    //     <div className='w-[483px] text-4xl leading-3 text-center grid grid-rows-1 justify-center'>
    //         <div className='flex space-x-7 bg-tpGray bg-opacity-50 w-full h-10 font-semibold py-4 uppercase font-sans'>
    //           <h1 className='text-golden tracking-wide'>{t("u")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("n")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("i")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("f")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("i")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("e")}</h1>
    //           <h1 className='text-golden tracking-wide'>{t("d")}</h1>
    //         </div>
    //         <div>
    //           <p className=' leading-[87px] text-white font-bold tracking-wide text-5xl font-sans'>{t("accounting")}</p>
    //         </div>
    //         <div className='flex space-x-5 bg-tpGray bg-opacity-50 w-full h-10 font-semibold py-4 font-sans'>
    //           <h1 className='text-white tracking-wide'>{t("s")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("o")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("f")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("t")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("w")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("a")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("r")}</h1>
    //           <h1 className='text-white tracking-wide'>{t("e")}</h1>
    //         </div>
    //     </div>
    // </div>
    <div className="flex flex-col w-[483px] h-[184px]">
      <div className='w-[483px] h-full bg-tpGray bg-opacity-50 content-center'>
      <span className="uppercase text-6xl text-golden font-semibold font-sans tracking-[41px] h-full w-[483px]">
      {t("unified")}
      </span>
      </div>
      <div className="text-white text-7xl leading-[47px] font-semibold font-sans tracking-[5px]">
       {t("accounting")}
      </div>
      <div className="bg-tpGray w-[483px] bg-opacity-50 text-5xl ">
       <span className='w-[470px] text-white font-semibold font-sans tracking-[36px] leading-tight'>
        {t("software")}
        </span>
      </div>
    </div>
  )
}

export default MainText