import { Translation } from "react-i18next";

const validation = {
  
    firstName:{
         min: {
            value: 8,
            message: "Password should be atleast 8 characters!",
          },
          max: {
            value: 32,
            message: "Password should not be more than 32 characters!",
          },
          pattern: {
            value: /^[A-Za-z]/,
            message: "First letter is always charater",
        },
    },
    password:{
        min:{
          value:6,
          message:"Password should be atleast 6 characters!"
        },
        max:{
          value: 10,
          message: "Password should not be more than 10 characters!",
        },
        pattern:{
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          message: "Must contain at least one number and one uppercase and lowercase letter",
        }
    },
    number:{
     pattern:{
      value:/^[0-9+]*$/,
      message:"Please Enter digits not alphabets"
     }
    },
    alphaNumeric:{
      pattern:{
        value:/^[A-Za-z0-9,.]*$/,
        message:"Invalid characters. Only A-Z, a-z, 0-9, commas, and periods are allowed."
      }
    },
    website:{
      pattern:{
        value:/^[A-Za-z0-9$-_.+!*'(),]*$/
      }
    },
    address:{
      pattern:{
        value:/^[A-Za-z0-9,.#()&+-_]*$/
      }
    },
    zipcode:{
      pattern:{
        value:/^[a-zA-Z0-9,.]*$/
      }
    },
    email:{
      pattern:{
        value:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      }
    }
}

export default validation;