import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n.js';
import Login from './components/login/Login.js';
import BusinessInfo from './components/business-info/BusinessInfo.js';
import { BrowserRouter,Routes,Route, createBrowserRouter,RouterProvider } from 'react-router-dom';
import BusinessEntityTypeForTax from './components/business-entity-type-for-tax/BusinessEntityTypeForTax.js';
import BusinessEntityInfo from './components/business-entity-info/BusinessEntityInfo.js';
import SideMenu from './components/sidemenu/SideMenu.js';
import SalesTransactions from './pages/sales/sales-transactions/SalesTransactions.js';
import Dashboard from './components/dashboard/Dashboard.js';
import Invoices from './pages/sales/all-invoices/Invoices.js';
import CreateInvoice from './pages/sales/create-invoice/CreateInvoice.js';
import Customer from './pages/sales/customer/Customer.js';
import AddDynamicInputFields from './components/AddDynamicInputFields.js';
import CustomerDashboard from './pages/sales/customer-dashboard/CustomerDashboard.js';
import InvoiceCopy from './pages/sales/create-duplicate-invoice/InvoiceCopy.js';
import Expenses from './pages/expenses/Expenses.js';
import BankTransactions from './pages/transaction/bank-transaction/BankTransactions.js';
import CopyExpense from './pages/expenses/copy-expense/CopyExpense.js';
import CreateExpense from './pages/expenses/create-expense/CreateExpense.js';
import Bill from './pages/expenses/bills/Bill.js';
import EditBill from './pages/expenses/bills/view-edit-bill/EditBill.js';
import CreateBill from './pages/expenses/bills/create-bill/CreateBill.js';
import BillMarkAsPaid from './pages/expenses/bills/mark-as-paid/BillMarkAsPaid.js';
import PayBill from './pages/expenses/bills/pay-bill/PayBill.js';
import VendorCredit from './pages/expenses/vendor/vendor-credit/VendorCredit.js';
import VendorList from './pages/expenses/vendor/vendor-list/VendorList.js';
import VendorDashboard from './pages/expenses/vendor/vendor-dashboard/VendorDashboard.js';
import ProductAndServices from './pages/product-and-services/ProductAndServices.js';
import Category from './pages/product-and-services/category/Category.js';
import EmployeeList from './pages/payroll/employee-list/EmployeeList.js';
import EmployeeOverview from './pages/payroll/employee-overview/EmployeeOverview.js';
import PayRuns from './pages/payroll/pay-runs/PayRuns.js';
import RegularPayroll from './pages/payroll/regular-payroll/RegularPayroll.js';



const router = createBrowserRouter([
  {
    path:"/",
    element:<App/>,
  },
  {
    path:"/dashboard",
    element:<Dashboard/>,
  },
  {
    path:"/sales",
    element:<SalesTransactions/>,
  },
  {
    path:"/sidemenu",
    element:<SideMenu/>,
  },
  {
    path:"/invoices",
    element:<Invoices/>,
  },
  {
    path:"/customers",
    element:<Customer/>,
  },
  {
    path:"/login",
    element:<Login/>,
  },
  {
    path:"/BusinessInformation",
    element:<BusinessInfo/>,
  },
  {
    path:"/BusinessEntityTypeForTax",
    element:<BusinessEntityTypeForTax/>,
  },
  {
    path:"/BusinessEntityInfo",
    element:<BusinessEntityInfo/>,
  },
  {
    path:"/createinvoice",
    element:<CreateInvoice/>,
  },
  {
    path:"/fields",
    element:<AddDynamicInputFields/>,
  },
  {
    path:"/customerdashboard",
    element:<CustomerDashboard/>,
  },
  {
    path:"/invoicecopy",
    element:<InvoiceCopy/>,
  },
  {
    path:"/expenses",
    element:<Expenses/>,
  },
  {
    path:"/banktransactions",
    element:<BankTransactions/>,
  },
  {
    path:"/copyexpense",
    element:<CopyExpense/>,
  },
  {
    path:"/createexpense",
    element:<CreateExpense/>,
  },
  {
    path:"/bill",
    element:<Bill/>,
  },
  {
    path:"/editbill",
    element:<EditBill/>,
  },
  {
    path:"/createbill",
    element:<CreateBill/>,
  },
  {
    path:"/paidbill",
    element:<BillMarkAsPaid/>,
  },
  {
    path:"/paybill",
    element:<PayBill/>,
  },
  {
    path:"/vendorcredit",
    element:<VendorCredit/>,
  },
  {
    path:"/vendorlist",
    element:<VendorList/>,
  },
  {
    path:"/vendordashboard",
    element:<VendorDashboard/>,
  },
  {
    path:"/productandservices",
    element:<ProductAndServices/>,
  },
  {
    path:"/category",
    element:<Category/>,
  },
  {
    path:"/employees",
    element:<EmployeeList/>,
  },
  {
    path:"/employeeoverview",
    element:<EmployeeOverview/>,
  },
  {
    path:"/payrollruns",
    element:<PayRuns/>,
  },
  {
    path:"/regularpayroll",
    element:<RegularPayroll/>,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <SetupBusinessProvider> */}
       <RouterProvider router={router}/>
    {/* </SetupBusinessProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
