import './App.css';
import Login from './components/login/Login';
import Logo from './components/Logo';
import  Register from './components/register/Register'
import ChangePasswordPopup from './components/change-password/ChangePasswordPopup';

function App() {
  return (
   <>
   {/* <Logo/> */}
    {/* <div className="h-screen bg-lochmara w-[34%] float-right bg-fixed xl:-z-50"></div> */}
      {/* <div className='h-screen grid grid-rows justify-center xl:flex xl:justify-end xl:mr-36 z-auto'> */}
      <Register/>
    {/* </div> */}
   </>
  );
}

export default App;
