import React, { useState } from "react";
import Layout from "../../Layout";
import { FaChevronDown } from "react-icons/fa";
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown";
//import VoidInvoice from "../../../components/void-invoice-dialog/VoidInvoice";
//import DeleteInvoice from "../../../components/delete-invoice-dialog/DeleteInvoice";
//import CustomDate from "../../../components/custom-dates/CustomDate";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import CustomDates from "../custom-date/CustomDates";

const SalesTransactions = () => {
  const { t } = useTranslation();
  const [isVoidDialogOpen, setIsVoidDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [isCustomDateDialogOpen, setIsCustomDateDialogOpen] = useState(false);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isVoidModalOpen, setVoidModalOpen] = useState(false);
  const [isCustomDateModalOpen, setCustomDateModalOpen] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openVoidModal = () => setVoidModalOpen(true);
  const closeVoidModal = () => setVoidModalOpen(false);
  const openCustomDateModal = () => setCustomDateModalOpen(true);
  const closeCustomDateModal = () => setCustomDateModalOpen(false);

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSelect = (value) => {
    if (value === "void") {
      //setIsVoidDialogOpen(true);
      //setIsDeleteDialogOpen(false);
      openVoidModal();
    }
    if (value === "delete") {
      // setIsVoidDialogOpen(false);
      // setIsDeleteDialogOpen(true);
      openDeleteModal();
    }
    if (value === "print") {
      openCustomDateModal();
    }
  };

  return (
    <>
      <Layout>
        <div className=" bg-white w-full h-full overflow-y-scroll px-5">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans my-10">
                <h1>Sales Transactions</h1>
              </div>
              <div className="w-full flex row gap-1 pt-8 pb-5">
                <div className="w-[23.55%] h-[115px]">
                  <h1 className="font-semibold font-sans text-3xl">$0</h1>
                  <p className="text-base font-semibold font-sans text-greyText pb-5">
                    0 estimates
                  </p>
                  <div className="bg-royalBlue h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold font-sans text-3xl">$0</h1>
                  <p className="text-base font-semibold font-sans text-greyText pb-5">
                    unbilled income
                  </p>
                  <div className="bg-seagull h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold font-sans text-3xl">$0</h1>
                  <p className="text-base font-semibold font-sans text-greyText pb-5">
                    1 overdue invoice
                  </p>
                  <div className="bg-tango h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold font-sans text-3xl">$0</h1>
                  <p className="text-base font-semibold font-sans text-greyText pb-5">
                    1 open invoice or credit
                  </p>
                  <div className="bg-tpGray h-[30px] rounded-[5px]"></div>
                </div>
                <div className="w-[23.55%]">
                  <h1 className="font-semibold font-sans text-3xl">$0</h1>
                  <p className="text-base font-semibold font-sans text-greyText pb-5">
                    0 recently paid
                  </p>
                  <div className="bg-salem h-[30px] rounded-[5px]"></div>
                </div>
              </div>
              <div className="float-right">
                <button className="uppercase bg-lochmara text-sm font-sans font-normal justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2">
                  New Transactions
                  <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                </button>
              </div>
            </div>
            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-7">
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Type</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <select className="border-pearl border w-full px-2 py-2 rounded-[5px]">
                        <option>All Transactions</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Date</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <select className="border-pearl border w-full px-2 py-2 rounded-[5px]">
                        <option>Last 12 months</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Customer</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <select className="border-pearl border w-full px-2 py-2 rounded-[5px]">
                        <option>Search</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex content-center py-5 px-5">
                  <div className="w-6 h-6">
                    <img src="blob.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="icon8.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="printer.jpg" />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                      <th className="w-[3%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[12%]">
                        <div className="flex">
                          DATE
                          <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                        </div>
                      </th>
                      <th className="w-[10%]">TYPE</th>
                      <th className="w-[10%]">No</th>
                      <th className="w-[18%]">MEMO</th>
                      <th className="w-[10%]">AMOUNT</th>
                      <th className="w-[15%]">STATUS</th>
                      <th className="w-[24%]">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1001</td>
                      <td className="py-2"></td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">Edit |</a>{" "}
                        
                        <a className="px-1 pt-2 text-lochmara font-semibold">
                          {" "}
                          Receive Payment |
                        </a>{" "}
                        
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "/invoicecopy", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1002</td>
                      <td className="py-2"></td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 mt-1 flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">Edit |</a>{" "}
                       
                        <a className="px-1 pt-2 text-lochmara font-semibold">
                          {" "}
                          Receive Payment |
                        </a>{" "}
                       
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "/invoicecopy", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1003</td>
                      <td className="py-2"></td>
                      <td className="py-2">$400.00</td>
                      <td className="py-2">Overdue 16 days</td>
                      <td className="py-2 mt-1 content-center flex cursor-pointer">
                        <a className="pr-1 pt-2 text-lochmara font-semibold">Edit |</a>{" "}
                        
                        <a className="px-1 pt-2 text-lochmara font-semibold">
                          {" "}
                          Receive Payment |
                        </a>{" "}
                       
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "/invoicecopy", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={isVoidModalOpen}>
        <div className="relative">
          <button
            onClick={closeVoidModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans">
          {t("voidinvoice")}
        </h3>
        <p className="text-sm font-sans font-normal h-10 my-4">
          {t("voidinvoicemsg")}
        </p>
        <button
          onClick={closeVoidModal}
          className="my-5 uppercase text-white bg-lochmara font-semibold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          {t("void")}
        </button>
      </Modal>
      <Modal isOpen={isDeleteModalOpen}>
        <div className="relative">
          <button
            onClick={closeDeleteModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans">
          {t("deleteinvoice")}
        </h3>
        <p className="text-sm font-sans font-normal h-10 my-4">
          {t("voidinvoicemsg")}
        </p>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-lochmara font-semibold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          {t("delete")}
        </button>
      </Modal>
      <CustomDates isOpen={isCustomDateModalOpen}>
        <div className=" font-sans">
          <div className="relative">
            <button
              onClick={closeCustomDateModal}
              className="absolute top-0 -right-5 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
            </button>
          </div>
          <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans">
            {t("customdates")}
          </h3>
          <div className="w-[421px] h-[55px] flex justify-between">
            <div className="">
              <div className="float-left">
                <label>Start date</label>
              </div>
              <div className="py-1">
                <input
                  type="date"
                  className="w-[200px] h-[35px] border px-1 outline-none cursor-pointer"
                />
              </div>
            </div>
            <div className="">
              <div className="float-left">
                <label>End date</label>
              </div>
              <div className="w-full py-1">
                <input
                  type="date"
                  className="w-[200px] h-[35px] border px-1 outline-none cursor-pointer"
                />
              </div>
            </div>
          </div>
          <button
            onClick={closeCustomDateModal}
            className="my-10 uppercase text-white bg-lochmara font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
          >
            {t("apply")}
          </button>
        </div>
      </CustomDates>
    </>
  );
};

export default SalesTransactions;
