import React,{useState} from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";
import Modal from "../../../../components/Modal";
import { useTranslation } from "react-i18next";

const EmployeeSummary = () => {
  const { t } = useTranslation();
  const [isApprovePayrollModalOpen, setApprovePayrollModalOpen] = useState(false);
  const closeApprovePayrollModal = () => setApprovePayrollModalOpen(false);

  const handleApprovePayroll = () => {
    setApprovePayrollModalOpen((preventDefault) => !preventDefault);
  };
  return (
    <>
      <div className="py-2">
        <table className="w-full">
          <tbody className="font-sans">
            <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
              <th className="w-[3%]">
                <input type="checkbox" className="w-5 h-5 mt-1" />
              </th>
              <th className="">
                <div className="flex">Employee Name</div>
              </th>
              <th className="">PAID DAYS</th>
              <th className="">GROSS PAY</th>
              <th className="text-right">DEDUCTIONS</th>
              <th className="text-right">TAXES</th>
              <th className="text-right">BENEFITS</th>
              <th className="text-right">REIMBUSEMENTS</th>
              <th className="text-right">NET PAY</th>
              <th className="px-4">ACTION</th>
            </tr>
            <tr className="border-b-2 text-sm font-normal border-bombay">
              <td className="py-2">
                <input type="checkbox" className="w-5 h-5 mt-1" />
              </td>
              <td className="py-2 flex">
                <p className="content-center text-wrap overflow-clip pt-1">
                  John Smith
                </p>
              </td>
              <td className="py-2">20</td>
              <td className="py-2">$2645.00</td>
              <td className="py-2 text-right">$0.00</td>
              <td className="py-2 text-right">$980.00</td>
              <td className="py-2 text-right">$200.00</td>
              <td className="py-2 text-right">$0.00</td>
              <td className="py-2 text-right">$1520.00</td>
              <td className="px-4">
                <a className="flex">
                  <button
                    className="text-lochmara font-sans font-semibold text-sm text-nowrap"
                    onClick={handleApprovePayroll}
                  >
                    Withhold Salary |
                  </button>
                  <CustomDropdown
                    options={[
                      { value: "withhold", option: "Withhold Salary" },
                      { value: "payroll", option: "Skip from this Payroll" },
                      { value: "release", option: "Release Withhold Salary" },
                    ]}
                    width="w-[197px]"
                    //onSelect={handleSelect}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal isOpen={isApprovePayrollModalOpen} width="w-[400px]" height="h-[250px]">
        <div className="relative">
          <button
            onClick={closeApprovePayrollModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <h3 className="w-full text-2xl font-semibold pt-1 pb-6 text-center content-center font-sans">
        Approve Payroll
        </h3>
        </div>
      
        <p className="text-sm font-sans font-normal pb-3 px-9">
        You are about to approve this payroll for June, 2024. Once you approve it, you can make payments for all your employees on the paydate 30/06/2024.
        </p>
        
        <button
          onClick={closeApprovePayrollModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          approve
        </button>
      </Modal>
    </>
  );
};

export default EmployeeSummary;
