import React from "react";
import { useTranslation } from "react-i18next";

const SalaryRevision = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className=" bg-white w-full h-full grid grid-cols content-between overflow-y-scroll font-sans">
        <div className="">
          <div className="w-full px-7 pb-3">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
                <h1>Salary Revision</h1>
              </div>
            </div>
            <div>
              <p className="text-lochmara text-base font-semibold">
                SALARY REVISION FOR JOHN SMITH
              </p>
              <div className="container mx-auto py-4">
                <table className="min-w-full bg-white border border-pearl">
                  <thead>
                    <tr className="text-base font-normal">
                      <td className="py-2 px-7 border-pearl border-b">
                        SALARY COMPONENTS
                      </td>
                      <td className="py-2 px-7 border-pearl border-b">
                        MONTHLY AMOUNT
                      </td>
                    </tr>
                  </thead>
                  <tbody className="my-4">
                    <tr className="">
                      <th className="py-2 pt-5 px-7 text-start">Basic</th>
                      <td className="py-2 pt-5 px-7">
                        <input
                          type="text"
                          className="border border-pearl w-[200px] h-9 focus:outline-none focus:border-lochmara px-2"
                        />
                      </td>
                    </tr>
                    <tr className="">
                      <th className="py-2 px-7 text-start">
                        Housing Allowance
                      </th>
                      <td className="py-2 px-7 text-left">
                        <input
                          type="text"
                          className="border border-pearl w-[200px] h-9 focus:outline-none focus:border-lochmara px-2"
                        />
                      </td>
                    </tr>
                    <tr className="">
                      <th className="py-2 px-7 text-start">
                        Transportation Allowance
                      </th>
                      <td className="py-2 px-7 text-left">
                        <input
                          type="text"
                          className="border border-pearl w-[200px] h-9 focus:outline-none focus:border-lochmara px-2"
                        />
                      </td>
                    </tr>
                    <tr className="">
                      <th className="py-2 px-7 text-start">Meal Allowance</th>
                      <td className="py-2 px-7 text-left">
                        <input
                          type="text"
                          className="border border-pearl w-[200px] h-9 focus:outline-none focus:border-lochmara px-2"
                        />
                      </td>
                    </tr>
                    <tr className="">
                      <th className="py-2 pb-5 px-7 text-start">
                        Daily Allowance
                      </th>
                      <td className="py-2 pb-5 px-7 text-left">
                        <input
                          type="text"
                          className="border border-pearl w-[200px] h-9 focus:outline-none focus:border-lochmara px-2"
                        />
                      </td>
                    </tr>
                    {/* Add more rows as needed */}
                  </tbody>
                  <tfoot>
                    <tr className="">
                      <th className="py-2 px-7 border-pearl border-t text-start">
                        GROSS SALARY
                      </th>
                      <th className="py-2 pl-12 border-pearl border-t">$0.00</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="border-b w-full border-pearl"></div>
          <div className="px-7 w-full">
              <p className="text-lochmara text-base font-semibold pt-3">
              PAYOUT PREFERENCES
              </p>
              <div>
                <div className="flex text-start">
                  <div className="w-[325px] py-1">
                    <lable className="text-sm font-semibold font-sans">Revised Salary effective from *</lable>
                    <div>
                    <input
                        type="text"
                        className="w-[325px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex text-start">
                  <div className="w-[325px] py-1">
                    <lable className="text-sm font-semibold font-sans">Payout Month *</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[325px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b w-full border-pearl py-1"></div>
        </div>
        <div className="w-full justify-end flex pb-6 content-end px-7">
          <div className="flex justify-between ">
            <button
              onClick={onClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalaryRevision;
