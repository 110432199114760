import React from 'react'
import Layout from "../../Layout";
import Tabs from '../../../components/Tabs';
import Overview from './overview/Overview';
import SalaryDetails from './salary-details/SalaryDetails';

const EmployeeOverview = () => {
    const tabs = [
        {
          id: 'overview',
          title: 'Overview',
          content: <Overview/>,
        },
        {
          id: 'salarydetails',
          title: 'Salary Details',
          content: <SalaryDetails/>,
        },
      ];
  return (
    <>
     <Layout>
     <div className="bg-white w-full h-full overflow-y-scroll px-5 mb-2">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans mt-8 mb-11">
                <h1>Employees Overview</h1>
              </div>
            </div>
            <Tabs tabs={tabs} />
          </div>
        </div>
     </Layout>
    </>
  )
}

export default EmployeeOverview