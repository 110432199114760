import React from 'react'

const Statement = () => {
  return (
    <>
        <div className="">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full">
                    <div className="">
                        <div className="text-base font-sans font-normal">
                             <lable>Date</lable>
                        </div>
                        <div className="text-base font-sans font-normal py-1">
                            <select className="w-[199px] h-[36px] border-slate-400 border px-2 py-1 rounded-sm">
                                <option>All</option>
                            </select>
                        </div>
                    </div>
                </div>
              </div>
              <div className="py-4">
                <table className="w-full">
                    <tbody className="text-sm font-sans">
                        <tr className="border-b-2 border-bombay text-left uppercase">
                           <th className="w-[4%] py-2"><input type="checkbox" className='w-5 h-5 mt-1'/></th>
                            <th className="w-[10%]">Date</th>
                            <th className="w-[10%]">No.</th>
                            <th className="w-[24%]">Customer</th>
                            <th className="w-[20%]">Start Date</th>
                            <th className="w-[18%]">End Date</th>
                            <th className="w-[18%]">Statement Type</th>
                        </tr>
                        <tr className="border-b-2 border-bombay">
                            <td className='py-2'><input type="checkbox" className='w-5 h-5 mt-1'/></td>
                            <td className="py-2">25/07/2024</td>
                            <td className="py-2">1001</td>
                            <td className="py-2">Sample Customer Name</td>
                            <td className="py-2">01/01/2024</td>
                            <td className="py-2">01/31/2024</td>
                            <td className="py-2">Overdue-23 days</td>
                        </tr>
                    </tbody>
                </table>
              </div>
         </div>
    </>
  )
}

export default Statement