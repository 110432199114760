import React from "react";

const VendorDetails = () => {
  return (
    <>
      <div>
        <div className="w-full border-2 border-bonJour rounded-md py-3 h-auto">
          <div className="w-[90%] grid grid-cols-3 gap-10 px-5">
            <div className="text-sm font-sans">
              <lable className="text-greyText font-semibold">Vendor name</lable>
              <p className="font-semibold pb-3">Sample Vendor Name</p>
              <label className="text-greyText font-semibold">
                Company name
              </label>
              <p className=" font-semibold pb-3">Sample Company Name</p>
              <lable className="text-greyText font-semibold font-sans">
                Billing address
              </lable>
              <div className="font-semibold text-sm font-sans">
                <p>123 some street</p>
                <p>Suite # 999</p>
                <p>Some City, Some State</p>
                <p>Some Country, 999 9999</p>
              </div>
            </div>
            <div className="text-sm font-sans">
              <lable className="text-greyText font-semibold">Email</lable>
              <p className=" font-semibold pb-3">someone@gmail.com</p>
              <lable className="text-greyText font-semibold">
                Phone number
              </lable>
              <p className=" font-semibold pb-3">+1 999 999 9999</p>
              <lable className="text-greyText font-semibold">
                Mobile number
              </lable>
              <p className=" font-semibold pb-3">+1 999 999 9999</p>
              <lable className="text-greyText font-semibold">Fax</lable>
              <p className=" font-semibold pb-3">+1 999 999 9999</p>
            </div>
            <div className="text-sm font-sans">
              <lable className="text-greyText font-semibold">Website</lable>
              <p className=" font-semibold pb-3">www.samplewebsite.com</p>
              <lable className="text-greyText font-semibold">Terms</lable>
              <p className=" font-semibold uppercase pb-3">NET 15 days</p>
              <lable className="text-greyText font-semibold">Notes</lable>
              <div className="font-semibold text-sm w-full">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Possimus ducimus consectetur assumenda voluptate optio neque.
                </p>
              </div>
            </div>
            <div className="font-sans">
              <lable className="text-greyText font-normal text-base">
                Attachements
              </lable>
              <div className="font-semibold text-sm py-2">
                <img src="pdf-file.png" className="w-10 h-10" />
                <span className="text-[10px] font-sans text-greyText">
                  CompanyRegistration.pdf
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
