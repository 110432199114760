import React, { useState } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";
import { useTranslation } from "react-i18next";

const AddBundle = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState([{ product: "", quantity: "" }]);
  const handleAddInput = () => {
    setInputs([...inputs, { product: "", quantity: "" }]);
  };

  const handleDeleteInput = (index) => {
    console.log("index :", index);
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  return (
    <>
      <div className="w-full font-sans">
        <div className="w-full flex">
          <div>
            <div className="py-2">
              <lable className="text-sm font-semibold">Name *</lable>
              <div>
                <textarea
                  type="text"
                  className="w-[466px] h-[69px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                />
              </div>
            </div>
            <div>
              <lable className="text-sm font-semibold">SKU</lable>
              <div>
                <input
                  type="text"
                  className="w-[466px] h-[48px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2"
                />
              </div>
            </div>
          </div>
          <div className="content-center pt-4 pl-4 flex">
            <div className="border border-pearl">
              <img src="default-img.png" className="w-[166px] h-[166px]" />
            </div>
            <div className="content-end">
              <img src="edit.png" />
              <img src="trash-bin-icon.png" />
            </div>
          </div>
        </div>
        <div className="py-2">
          <lable className="text-sm font-semibold">Description</lable>
          <div>
            <textarea
              type="text"
              className="h-[80px] w-full border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
            />
          </div>
        </div>
        <div className="border-b pb-5 border-pearl"></div>
        <div className="bg-white h-auto mb-3">
          <div className="my-[4px]">
            <div className="py-2">
              <lable className="font-semibold text-sm">
                Product/services included in the bundle
              </lable>
            </div>
            {/* product or services */}
            <div className="pt-4">
              <table className="w-full pt-4">
                <tbody>
                  <tr className="w-full border-b-2 border-tpGray">
                    <th className="w-[25px] border-r-2 border-tpGray"></th>
                    <th className="w-[24%] border-r-2 border-tpGray text-start pl-4">
                      PRODUCT/SERVICE
                    </th>
                    <th className="w-[12%] border-r-2 border-tpGray text-right pr-4">
                      QTY
                    </th>
                    <th className="w-[25px] "></th>
                  </tr>
                  {inputs.map((item, index) => (
                    <tr className="w-full border-b-2 border-tpGray">
                      <td className="w-[25px] border-r-2 border-tpGray">
                        <img src="reorder-icon.png" className="w-6 h-6" />
                      </td>
                      <td className="border-r-2 border-tpGray">
                        <div className="w-[484px] px-4">
                          <CustomDropdown
                            options={[
                              {
                                value: "alltransactions",
                                option: "All Transactions",
                              },
                              { value: "expense", option: "Expense" },
                              { value: "bill", option: "Bill" },
                              { value: "payment", option: "Bill payment" },
                            ]}
                            border="border border-pearl rounded-md"
                            btnCss="text-white bg-bombay"
                          />
                        </div>
                      </td>
                      <td className="py-1 px-4 border-r-2 border-tpGray">
                        <input
                          name="qty"
                          type="number"
                          className="border-2 border-tpGray w-[85px] p-1 rounded-md outline-none focus:border-lochmara font-normal font-sans"
                        />
                      </td>
                      <td className="w-[25px]">
                        {inputs.length > 1 && (
                          <button onClick={() => handleDeleteInput(index)}>
                            <img
                              src="trash-bin-icon.png"
                              className="w-6 h-6 cursor-pointer ml-2 mt-1"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => handleAddInput()} className="flex pt-3">
                <img src="addbtn-icon.png" className="w-6 h-6" />
                <p className="text-sm font-normal text-nowrap content-center pt-[3px] pl-2">
                  {" "}
                  Add lines
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex pt-[380px] pb-5 content-end">
          <div className="flex justify-between ">
            <button
              onClick={onClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBundle;
