import React from 'react'
import MainText from './MainText'
import Logo from './Logo'

const Mainpages = ({children}) => {
  return (
    <>
      <div className="flex flex-col h-screen">
      <Logo/>
      <div className="content-center bg-[url('../public/bg-img.png')] bg-cover bg-center bg-no-repeat h-full">
      <div className="flex overflow-hidden justify-between h-full">
        {/* Left Section */}
        <div className="bg-gray-100 w-[50%] flex items-center justify-center">
          <div className="text-start flex justify-center">
            <MainText/>
          </div>
        </div>

        {/* Right Section */}
        <div className='w-[48%] h-full content-center bg-gradient-to-r from-transparent from-10% via-lochmara to-lochmara to-90%'>
            <div className="w-[550px] flex h-auto content-around bg-form">
                {children}
            </div>
        </div>
        </div>
      </div>
      <div className='fixed bottom-1 left-1 w-[146px] h-[17px]'>
        <img src='Copyright_Statement.png'/>
      </div>
    </div>
           
    </>
  )
}

export default Mainpages