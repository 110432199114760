import React from 'react';

const TruncateTooltip = ({ text, maxLength }) => {
  // Function to truncate text
  const truncateText = (str, length) => {
    return str.length > length ? str.substring(0, length) + '...' : str;
  };

  return (
    <div className="relative inline-block">
      <span className="truncate max-w-[150px] block text-ellipsis overflow-hidden uppercase">{truncateText(text, maxLength)}</span>
      <div className="tooltip absolute left-1/2 transform -translate-x-1/2 w-auto px-2 py-1 text-sm text-white bg-lochmara uppercase rounded transition-opacity duration-300">
        {text}
      </div>
    </div>
  );
};

export default TruncateTooltip;
