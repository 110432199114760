import React from 'react'
import CustomDropdown from "../../../../../components/custom-dropdown/CustomDropdown";
import { useTranslation } from "react-i18next";

const EditPersonalInfo = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
  return (
    <>
        <div className=" bg-white w-full h-full grid grid-cols content-between overflow-y-scroll font-sans">
        <div className="">
          <div className="w-full px-7">
            <div className="w-full text-lg font-semibold font-sans my-5 justify-between flex border-b-4 border-pearl">
              <div className="">
                <h1>Update Employee Personal Information</h1>
              </div>
            </div>
          </div>
          <div className="w-full px-7 pb-3">
            <div>
              <p className="uppercase text-lochmara text-base font-semibold">
              PERSONAL INFORMATION
              </p>
            </div>
            <div className="w-full flex">
              <div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Address 1</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Address 2</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">City</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">State</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Country</lable>
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Postal Code / Zip Code</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">National I.D.</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Other I.D.</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Personal Mobile Number</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Marital Status</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-[220px] py-1">
                    <lable className="text-sm font-semibold">Date of Birth *</lable>
                    <div>
                      <input
                        type="date"
                        className="w-[220px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex pb-4 px-7">
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={onSubmit}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditPersonalInfo