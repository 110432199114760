import React,{useState} from 'react'
import Layout from '../../Layout'
import ReviewList from '../review-list/ReviewList';
import CategorizedList from '../categorized-list/CategorizedList';
import ExcludedList from '../excluded-list/ExcludedList';
import UploadFile from '../upload-file/UploadFile';
import SelectAccount from '../select-account/SelectAccount';
import MapField from '../map-field/MapField';

const BankTransactions = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isUploadFileOpen, setIsUploadFileOpen] = useState(false);
  const [isSelectAccountOpen, setIsSelectAccountOpen] = useState(false);
  const [isMapFieldOpen, setIsMapFieldOpen] = useState(false);

  const handleUploadFilePopup = ()=>{
    setIsUploadFileOpen(true)
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <Layout>
      <div className=" bg-white w-full h-full overflow-y-scroll px-5 font-sans">
          <div className="">
            <div className="w-full">
              <div className="w-full text-xl font-semibold font-sans my-10 justify-between flex">
                <div className=''>
                <h1>Bank Transactions</h1>
                </div>
                <div className="float-right">
                <button className="uppercase bg-lochmara text-sm font-sans font-normal justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2" onClick={handleUploadFilePopup}>Upload Transactions
                </button>
              </div>
              </div>
            </div>
            <div className='w-[600px] h-9 flex justify-center'>
              <div className={`${activeTab === 1
                    ? 'bg-pearl text-white'
                    : 'text-gray-600'} w-[200px] border border-greyText text-center content-center rounded-l-[22px]`}>
                <button key={1}
                onClick={() => handleTabClick(1)}
                >For Review (0)</button>
              </div>
              <div className={`${activeTab === 2
                    ? 'bg-pearl text-white'
                    : 'text-gray-600'} w-[200px] border border-greyText text-center content-center`}>
                <button key={2}
                onClick={() => handleTabClick(2)}
                >Categorized</button>
              </div>
              <div className={`${activeTab === 3
                    ? 'bg-pearl text-white'
                    : 'text-gray-600'} w-[200px] border border-greyText text-center content-center rounded-r-[22px]`}>
                <button key={3}
                onClick={() => handleTabClick(3)}
                >Exclude</button>
              </div>

            </div>
            {activeTab === 1 && <ReviewList/>}
            {activeTab === 2 && <CategorizedList/>}
            {activeTab === 3 && <ExcludedList/>}
          </div>
        </div>
      </Layout>
      {isUploadFileOpen && <UploadFile setIsUploadFileOpen={setIsUploadFileOpen} setIsSelectAccountOpen={setIsSelectAccountOpen}/>}
      {isSelectAccountOpen && <SelectAccount setIsSelectAccountOpen={setIsSelectAccountOpen} setIsMapFieldOpen={setIsMapFieldOpen} setIsUploadFileOpen={setIsUploadFileOpen}/>}
      {isMapFieldOpen && <MapField setIsMapFieldOpen={setIsMapFieldOpen} setIsSelectAccountOpen={setIsSelectAccountOpen}/>}
      
    </>
  )
}

export default BankTransactions