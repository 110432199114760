import React, { useState, useRef } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";

const PayBill = ({ onSubmit, onClose }) => {
  return (
    <>
      <div className="bg-bonJour h-auto w-full overflow-auto font-sans">
        <div className="bg-white w-full h-[90px] px-7 flex justify-between">
          <div className="content-center">
            <lable className="text-4xl font-sans font-semibold">Bill</lable>
          </div>
          <div className="content-center">
            <div className="flex gap-8">
              <div className="w-6 h-6  cursor-pointer">
                <button onClick={onClose}>
                  <img src="cross-icon.png" className="content-center" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-[101px] mx-7 mt-5">
          <div className="px-5 py-3 flex">
            <div className="">
              <div className="flex gap-8">
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Payment Account</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Payment Date</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="date"
                      className="w-full h-full border border-pearl rounded-md px-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full">
              <div>
                <p className="flex justify-end text-sm font-sans font-light">
                  Total Payment Amount
                </p>
                <h1 className="font-semibold text-5xl font-sans">$0.00</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-auto mx-7 mb-4 mt-2">
          <div className="flex px-5 pt-4 gap-8">
            <div>
              <div className="text-sm font-sans w-[200px] h-[36px]">
                <CustomDropdown
                  options={[
                    {
                      value: "alltransactions",
                      option: "Filters",
                    },
                    { value: "expense", option: "Expense" },
                    { value: "bill", option: "Bill" },
                    { value: "payment", option: "Bill payment" },
                  ]}
                  border="border border-pearl rounded-md"
                  btnCss="text-white bg-bombay"
                />
              </div>
            </div>
            <div>
              <button className="text-sm font-sans font-semibold text-white bg-pearl rounded-[15px] px-2 py-1 w-[141px] h-[35px]">
                Last 365 days
              </button>
            </div>
          </div>
          <div className="my-[4px] px-5 min-h-[373px]">
            {/* product or services */}
            <div className="pt-7">
              <table className="w-full">
                <tbody className="font-sans">
                  <tr className="border-b-2 text-base border-bombay text-left font-semibold uppercase">
                    <th className="w-[3%]">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </th>
                    <th className="w-[9%]">Payee</th>
                    <th className="w-[9%]">Ref no.</th>
                    <th className="w-[10%]">Due Date</th>
                    <th className="w-[8%] ">Status</th>
                    <th className="w-[15%] text-right">Open Balance</th>
                    <th className="w-[10%] text-right">Credit Applied</th>
                    <th className="w-[15%] text-right">Payment</th>
                    <th className="w-[20%] text-nowrap px-2">Total Amount</th>
                  </tr>
                  <tr className="border-b-2 text-sm font-normal border-bombay">
                    <td className="py-2">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </td>
                    <td className="py-2 flex">Sample Company</td>
                    <td className="py-2">656454</td>
                    <td className="py-2">02/09/2024</td>
                    <td className="py-2">
                      Due later <p> Due in 10 days</p>
                    </td>
                    <td className="py-2 text-right">$200.00</td>
                    <td className="py-2 text-right">$0.00</td>
                    <td className="py-2 flex cursor-pointer justify-end">
                      <div className="text-sm font-sans w-[125px] h-[36px]">
                        <input
                          type="text"
                          className="w-full h-full border text-right border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                        />
                      </div>
                    </td>

                    <td className="py-2 text-right">$0.00</td>
                  </tr>
                  <tr className="border-b-2 text-sm font-normal border-bombay">
                    <td className="py-2">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </td>
                    <td className="py-2 flex">Sample Company</td>
                    <td className="py-2">656454</td>
                    <td className="py-2">02/09/2024</td>
                    <td className="py-2">
                      Due later <p> Due in 10 days</p>
                    </td>
                    <td className="py-2 text-right">$200.00</td>
                    <td className="py-2 text-right">$0.00</td>
                    <td className="py-2 flex cursor-pointer justify-end">
                      <div className="text-sm font-sans w-[125px] h-[36px]">
                        <input
                          type="text"
                          className="w-full h-full border text-right border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                        />
                      </div>
                    </td>

                    <td className="py-2 text-right">$0.00</td>
                  </tr>

                  <tr className="text-base text-left font-semibold">
                    <th></th>
                    <th className="w-[15%] py-2">Total Payment</th>
                    <th className="w-[10%]"></th>
                    <th className="w-[10%] text-right"></th>
                    <th className="w-[10%] text-right"></th>
                    <th className="w-[15%] text-right">$0.00</th>
                    <th className="w-[20%] text-right px-2">$0.00</th>
                    <th className="w-[20%] text-right px-2">$0.00</th>
                    <th className="w-[20%] text-right px-2">$0.00</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="content-end flex justify-end w-full py-6 px-10">
            <button className="uppercase text-sm font-bold text-white bg-lochmara rounded-[10px] px-2 py-1 w-[200px] h-[35px]">
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayBill;
