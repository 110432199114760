import React,{useState} from "react";
import Modal from "../../../../components/Modal";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../components/Dialog";
import EditBasicInfo from "./edit-basic-info/EditBasicInfo";
import EditPersonalInfo from "./edit-personal-info/EditPersonalInfo";
import EditPayment from "./edit-payment/EditPayment";
import EditExitInfo from "./edit-exit-info/EditExitInfo";

const Overview = () => {
  const { t } = useTranslation();
  const [isEditBasicInfoModalOpen, setIsEditBasicInfoModalOpen] =  useState(false);
  const [isEditPersonalInfoModalOpen, setIsEditPersonalInfoModalOpen] =  useState(false);
  const [isEditPaymentInfoModalOpen, setIsEditPaymentInfoModalOpen] =  useState(false);
  const [isEditExitInfoModalOpen, setIsEditExitInfoModalOpen] =  useState(false);

  const handleEditBasicInfo = () => {
    setIsEditBasicInfoModalOpen((preventDefault) => !preventDefault);
  };
  const handleEditPersonalInfo = () => {
    setIsEditPersonalInfoModalOpen((preventDefault) => !preventDefault);
  };
  const handleEditPaymentInfo = () => {
    setIsEditPaymentInfoModalOpen((preventDefault) => !preventDefault);
  };
  const handleEditExitInfo = () => {
    setIsEditExitInfoModalOpen((preventDefault) => !preventDefault);
  };
  return (
    <>
      <div className="font-sans w-full flex justify-between">
        <div className="w-[54%] border px-7 py-3">
          <div className="flex justify-center">
            <div className="">
              <div className="flex justify-center py-3">
                <img
                  src="default-img.png"
                  className="w-[159px] h-[159px] border border-pearl"
                />
              </div>
              <p className="font-semibold font-sans text-base py-1 flex justify-center">
                John Smith (Emp ID - 123456)
              </p>
            </div>
            <div>
              <button onClick={handleEditBasicInfo}>
                <img src="edit.png" className="w-9 h-9 mt-2" />
              </button>
            </div>
          </div>
          <div>
            <p className="uppercase text-lochmara text-base font-semibold py-1">
              BASIC & WORK INFORMATION
            </p>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Employee ID</p>
              <p className="text-base w-[50%]">132324</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Name</p>
              <p className="text-base w-[50%]">John Smith</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">
                Personal Email Address
              </p>
              <p className="text-base w-[50%] pr-5">
                john.smith@somedomain.com
              </p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Department</p>
              <p className="text-base w-[50%]">Human Resource</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Designation</p>
              <p className="text-base w-[50%]">HR Manager</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Location</p>
              <p className="text-base w-[50%]">Some City</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Employee Status</p>
              <p className="text-base w-[50%]">Active</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Employment Type</p>
              <p className="text-base w-[50%]">Permanent</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">
                Reporting Manager
              </p>
              <p className="text-base w-[50%]">Somnath Bharti</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Date of Joining</p>
              <p className="text-base w-[50%]">12-Jan-2022</p>
            </div>
          </div>
        </div>
        <div className="w-[45%]">
          <div className="border px-7 py-3 h-[50%]">
            <div className="flex">
              <p className="uppercase w-full text-lochmara text-base font-semibold py-1">
                PERSONAL INFORMATION
              </p>
              <div>
              <button onClick={handleEditPersonalInfo}>
                <img src="edit.png" className="w-9 h-9 mt-2" />
              </button>
              </div>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Address</p>
              <p className="text-base w-[50%]">
                1234 Street Address, Sample CitySample State, Country - 99999
              </p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Date of Birth</p>
              <p className="text-base w-[50%]">12-Jan-1965</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Marital Status</p>
              <p className="text-base w-[50%] pr-5">Married</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">National I.D</p>
              <p className="text-base w-[50%]">XXXXXXXXX-XXXXX</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Other I.D.</p>
              <p className="text-base w-[50%]">----------</p>
            </div>
          </div>
          <div className="border px-7 py-3 my-3 h-[23.4%]">
            <div className="flex">
              <p className="uppercase w-full text-lochmara text-base font-semibold py-1">
                PAYMENT INFORMATION
              </p>
              <div>
              <button onClick={handleEditPaymentInfo}>
                <img src="edit.png" className="w-9 h-9 mt-2" />
              </button>
              </div>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Payment Mode</p>
              <p className="text-base w-[50%]">Cheque</p>
            </div>
          </div>
          <div className="border px-7 py-3 my-3 h-[23.4%]">
            <div className="flex">
              <p className="uppercase w-full text-lochmara text-base font-semibold py-1">
                EXIT INFORMATION
              </p>
              <div>
              <button onClick={handleEditExitInfo}>
                <img src="edit.png" className="w-9 h-9 mt-2" />
              </button>
              </div>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Date of Exit</p>
              <p className="text-base w-[50%]">12-Mar-2023</p>
            </div>
            <div className="flex py-3">
              <p className="text-base font-semibold w-[50%]">Reason</p>
              <p className="text-base w-[50%]">Resigned</p>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={isEditBasicInfoModalOpen}
        width="w-[550px]"
        height="h-[275px]"
      >
        <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>New Category</h1>
            </div>
          </div>
          <div className="w-full flex font-sans">
            <div>
              <div className="flex text-start">
                <div className="w-[500px] py-1">
                  <lable className="text-sm font-semibold">Name *</lable>
                  <div>
                    <textarea
                      type="text"
                      className="w-[500px] h-[69px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full justify-end flex pt-10">
            <div className="flex justify-between">
              <button
                onClick={closeEditBasicInfoModal}
                className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                role="button"
                onClick={closeEditBasicInfoModal}
                className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
      <Dialog
        isOpen={isEditBasicInfoModalOpen}
        onClose={isEditBasicInfoModalOpen}
        height="h-[720px]"
        width="w-[750px]"
      >
        <EditBasicInfo
          onSubmit={handleEditBasicInfo}
          onClose={handleEditBasicInfo}
        />
      </Dialog>
      <Dialog
        isOpen={isEditPersonalInfoModalOpen}
        onClose={isEditPersonalInfoModalOpen}
        height="h-[720px]"
        width="w-[750px]"
      >
        <EditPersonalInfo
          onSubmit={handleEditPersonalInfo}
          onClose={handleEditPersonalInfo}
        />
      </Dialog>
      <Dialog
        isOpen={isEditPaymentInfoModalOpen}
        onClose={isEditPaymentInfoModalOpen}
        height="h-[720px]"
        width="w-[750px]"
      >
        <EditPayment
          onSubmit={handleEditPaymentInfo}
          onClose={handleEditPaymentInfo}
        />
      </Dialog>
      <Dialog
        isOpen={isEditExitInfoModalOpen}
        onClose={isEditExitInfoModalOpen}
        height="h-[720px]"
        width="w-[750px]"
      >
        <EditExitInfo
          onSubmit={handleEditExitInfo}
          onClose={handleEditExitInfo}
        />
      </Dialog>
    </>
  );
};

export default Overview;
