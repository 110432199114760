import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideMenu = ({ sidebarToggle, setSidebarToggle }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(localStorage.getItem("openMenu"));

  console.log("localstoge", localStorage.getItem("openMenu"));
  console.log("Activeindex", localStorage.getItem("activeSubmenu"));

  const handleOpen = (value) => {
    console.log(value);
    setOpen(open === value ? 0 : value);
    localStorage.setItem("openMenu", value);
  };
  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem("activeSubmenu")
  );

  const handleClick = (index) => {
    console.log("HandleClick");
    console.log("index", index);
    setActiveIndex(index);
    localStorage.setItem("activeSubmenu", index);
  };
  return (
    <>
      <div
        className={`${sidebarToggle ? " hidden" : "block"} ${
          open !== 0 ? " h-screen" : "h-screen"
        } bg-lochmara w-[220px] justify-center `}
      >
        <div className="w-full h-[60px] flex justify-center mx-1 mt-5">
          <img src="image.png" className="w-[186px] " />
        </div>
        <div className="flex justify-center h-auto">
          <ul>
            <li
              onClick={() => handleClick(14)}
              className={`${activeIndex == 14 ? 'font-semibold' : ''} mb-2 rounded font-sans pt-14`}
            >
              {activeIndex == 14 && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <Link to="/dashboard" className="text-white px-3 text-sm">
                {t("dashboard")}
              </Link>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              {activeIndex == 13 && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <div
                className={`${
                  activeIndex == 13 ? "font-semibold" : ""
                } flex text-white justify-between mt-2`}
              >
                <a href="#" className="text-white px-3 text-sm">
                  {t("transactions")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(5)}
                  />
                ) : open == 5 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(5)}
                  />
                )}
              </div>
              <div
                className={`${
                  open == 5 ? " block" : "hidden"
                } flex justify-between text-sm`}
              >
                <ul>
                  <li
                    onClick={() => handleClick(13)}
                    className={` ${
                      activeIndex == 13 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/banktransactions">
                      Bank Transactions
                      {activeIndex == 13 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal py-2">
              {(activeIndex == 3 || activeIndex == 4 || activeIndex == 5) && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <div
                className={`${
                  activeIndex == 3 || activeIndex == 4 || activeIndex == 5
                    ? "font-semibold"
                    : ""
                } flex text-white justify-between mt-2`}
              >
                <a href="#" className="text-white px-3 text-sm">
                  {t("sales")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(1)}
                  />
                ) : open == 1 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(1)}
                  />
                )}
              </div>
              <div
                className={`${
                  open == 1 ? " block" : "hidden"
                } flex justify-between text-sm font-sans font-normal `}
              >
                <ul>
                  <li
                    
                    className={`${
                      activeIndex == 3 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/sales" onClick={() => handleClick(3)}>
                    {activeIndex == 3 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                      {t("salestransactions")}
                      
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 4 ? "font-semibold" : ""
                    } relative px-6 py-2 text-white`}
                  >
                    <Link to="/invoices" onClick={() => handleClick(4)}>
                      {t("invoices")}
                      {activeIndex == 4 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 5 ? "font-semibold" : ""
                    } relative px-6 py-2 text-white`}
                  >
                    <Link to="/customers"  onClick={() => handleClick(5)}>
                      {t("customers")}
                      {activeIndex == 5 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              {(activeIndex == 6 || activeIndex == 7 || activeIndex == 8) && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <div
                className={`${
                  activeIndex == 6 || activeIndex == 7 || activeIndex == 8
                    ? "font-semibold"
                    : ""
                } flex text-white justify-between mt-2`}
              >
                <a href="#" className="text-white px-3 text-sm">
                  {t("expenses")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(2)}
                  />
                ) : open == 2 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(2)}
                  />
                )}
              </div>
              <div
                className={`${
                  open == 2 ? " block" : "hidden"
                } flex justify-between text-sm font-sans font-normal`}
              >
                <ul>
                  <li
                    className={`${
                      activeIndex == 6 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/expenses" onClick={() => handleClick(6)}>
                      {t("expenses")}
                      {activeIndex == 6 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 7 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/bill"  onClick={() => handleClick(7)}>
                      {t("bills")}
                      {activeIndex == 7 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 8 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/vendorlist" onClick={() => handleClick(8)}>
                      {t("vendors")}
                      {activeIndex == 8 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              {(activeIndex == 9 || activeIndex == 10) && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <div
                className={`${
                  activeIndex == 9 || activeIndex == 10 ? "font-semibold" : ""
                } flex text-white justify-between mt-2`}
              >
                <a href="#" className="text-white px-3 text-sm">
                  {t("products&services")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(3)}
                  />
                ) : open == 3 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(3)}
                  />
                )}
              </div>
              <div
                className={`${
                  open == 3 ? " block" : "hidden"
                } flex justify-between text-sm`}
              >
                <ul>
                  <li
                    className={`${
                      activeIndex == 9 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/productandservices" onClick={() => handleClick(9)}>
                      {t("products")}
                      {activeIndex == 9 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 10 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/category"  onClick={() => handleClick(10)}>
                      {t("category")}
                      {activeIndex == 10 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              {(activeIndex == 11 || activeIndex == 12) && (
                <div className="absolute left-0 h-9 w-3 bg-malachite "></div>
              )}
              <div
                className={`${
                  activeIndex == 11 || activeIndex == 12 ? "font-semibold" : ""
                } flex text-white justify-between mt-2`}
              >
                <a href="#" className="text-white px-3 text-sm">
                  {t("payroll")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(4)}
                  />
                ) : open == 4 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(4)}
                  />
                )}
              </div>
              <div
                className={`${
                  open == 4 ? " block" : "hidden"
                } flex justify-between text-sm`}
              >
                <ul>
                  <li
                    className={`${
                      activeIndex == 11 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/employeeoverview" onClick={() => handleClick(11)}>
                      {t("employees")}
                      {activeIndex == 11 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeIndex == 12 ? "font-semibold" : ""
                    } relative px-6 py-2 mt-2 text-white`}
                  >
                    <Link to="/employees" onClick={() => handleClick(12)}>
                      {t("contractors")}
                      {activeIndex == 12 && (
                        <div className="absolute top-0 left-0 h-8 w-3 bg-malachite"></div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              <div className="flex text-white justify-between">
                <a href="#" className="text-white px-3 text-sm">
                  {t("reports")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(5)}
                  />
                ) : open == 5 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(5)}
                  />
                )}
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal  py-2">
              <div className="flex text-white justify-between">
                <a href="#" className="text-white px-3 text-sm">
                  {t("settings")}
                </a>
                {open == 0 ? (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(6)}
                  />
                ) : open == 6 ? (
                  <FaChevronUp
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(0)}
                  />
                ) : (
                  <FaChevronDown
                    className="content-center cursor-pointer"
                    onClick={() => handleOpen(6)}
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className="fixed bottom-1 left-2 w-[203px] h-[17px]">
          <img src="copyright.png" />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
