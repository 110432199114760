// src/utils/validate.js
import validation from "./validation";

export const validate = (name, value) => {
  let error = "";

  switch (name) {
    case "fname":
      if (!value) {
        error = "First name is required";
      } else if (!validation.firstName.pattern.value.test(value)) {
        error = "First letter is always charater";
      }
      break;

    case "lname":
      if (!value) {
        error = "Last name is required";
      } else if (!validation.firstName.pattern.value.test(value)) {
        error = "First letter is always charater";
      }
      break;
    case "email":
      if (!value) {
        error = "Email is required";
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        error = "Enter valid email";
      }
      break;
    case "password":
      if (!value) {
        error = "Password is required";
      }
    //    else if (!validation.password.pattern.value.test(value)) {
    //     error = validation.password.pattern.message;
    //   }
      break;
    case "confirmpassword":
      if (!value) {
        error = "Confirmpassword is required";
      } 
      break;
    case "paymentMethod":
      if (!value) {
        error = "Payment method is required";
      }
      break;
    case "refno":
      if (!value) {
        error = "Reference number is required";
      } else if (!/^[0-9+]*$/.test(value)) {
        error = "Reference number is invalid";
      }
      break;
    case "depositTo":
      if (!value) {
        error = "Deposite to is required";
      }
      break;
    case "date":
      if (!value) {
        error = "Payment date is required";
      }
      break;
    case "title":
      if (value) {
        if (!validation.zipcode.pattern.value.test(value)) {
          error = "Invalid characters. Only A-Z, a-z, 0-9 are allowed.";
        }
    }
      break;
    case "mname":
      if (value) {
        if (!validation.zipcode.pattern.value.test(value)) {
          error = "Invalid characters. Only A-Z, a-z, 0-9, commas, and periods  are allowed.";
        }
      }
      break;
    case "companyName":
      if (!value) {
        error = "Company name is required";
      }else if (!validation.alphaNumeric.pattern.value.test(value)) {
        error = "Invalid characters. Only A-Z, a-z, 0-9, commas, and periods are allowed.";
      }
      break; 
    case "phoneNumber":
      if (value) {
        if (!validation.number.pattern.value.test(value)) {
          error = "Invalid phone number.";
        }
      }
      break; 
    case "mobileNumber":
      if (!value) {
        error = "Mobile number is required";
      }else if (!validation.number.pattern.value.test(value)) {
        error = "Invalid mobile number.";
      }
      break;  
    case "fax":
      if (value) {
        if (!validation.number.pattern.value.test(value)) {
          error = "Invalid fax.";
        }
      }
      break;
    case "website":
      if (value) {
        if (!validation.website.pattern.value.test(value)) {
          error = "Invalid website.";
        }
      }
      break; 
    case "checkPayableTo":
      if (value) {
        if (!validation.alphaNumeric.pattern.value.test(value)) {
          error = "Invalid characters. Only A-Z, a-z, 0-9, commas, and periods are allowed.";
        }
      }
      break;  
    case "streetAddress1":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid address.";
        }
      }
      break; 
    case "streetAddress2":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid address.";
        }
      }
      break; 
    case "city":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid city.";
        }
      }
      break; 
    case "state":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid state.";
        }
      }
      break;  
    case "zipCode":
      if (value) {
        if (!validation.zipcode.pattern.value.test(value)) {
          error = "Invalid zipcode.";
        }
      }
      break;   
    case "shippingStreetAddress1":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid address.";
        }
      }
      break; 
    case "shippingStreetAddress2":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid address.";
        }
      }
      break; 
    case "shippingCity":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid city.";
        }
      }
      break; 
    case "shippingState":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid state.";
        }
      }
      break;   
    case "shippingZipCode":
      if (value) {
        if (!validation.zipcode.pattern.value.test(value)) {
          error = "Invalid zipcode.";
        }
      }
      break; 
    case "notes":
      if (value) {
        if (!validation.address.pattern.value.test(value)) {
          error = "Invalid notes.";
        }
      }
      break; 
       
    default:
      break;
  }

  return error;
};

export const validateFile = (file) => {
  const errors = [];
  const allowedTypes = ['image/jpeg', 'image/png'];
  const maxSizeMB = 2;

  if (file && !allowedTypes.includes(file.type)) {
    errors.push('Invalid file type. Only .jpg and .png files are allowed.');
  }

  if (file && file.size > maxSizeMB * 1024 * 1024) {
    errors.push(`File size exceeds ${maxSizeMB}MB.`);
  }

  return errors;
};