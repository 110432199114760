import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { validate } from "../../../../utils/formValidation";
import Tooltip from "../../../../utils/toolTip";

const AddVendor = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    title: "",
    fname: "",
    mname: "",
    lname: "",
    suffix: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    mobileNumber: "",
    fax: "",
    website: "",
    checkPayableTo: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    sameAsBillingAddress: false,
    shippingStreetAddress1: "",
    shippingStreetAddress2: "",
    shippingCity: "",
    shippingState: "",
    shippingCountry: "",
    shippingZipCode: "",
    notes: "",
    attachemntFile: null,
  });
  const [errors, setErrors] = useState({
    title: "",
    fname: "",
    mname: "",
    lname: "",
    suffix: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    mobileNumber: "",
    fax: "",
    website: "",
    checkPayableTo: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    sameAsBillingAddress: false,
    shippingStreetAddress1: "",
    shippingStreetAddress2: "",
    shippingCity: "",
    shippingState: "",
    shippingCountry: "",
    shippingZipCode: "",
    notes: "",
    attachemntFile: "",
  });

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  // Handle change in input fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    let selectedFile = "";
    if (event.target.files) {
      selectedFile = event.target.files[0];
    }

    // Update form data state
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate field
    const error = validate(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate all fields
    const newErrors = {
      fname: validate("fname", formData.fname),
      lname: validate("lname", formData.lname),
      email: validate("email", formData.email),
      mobileNumber: validate("mobileNumber", formData.mobileNumber),
    };
    setErrors(newErrors);

    // Check if there are any errors
    if (
      !newErrors.date &&
      !newErrors.paymentMethod &&
      !newErrors.depositTo &&
      !newErrors.refno
    ) {
      // Form is valid, handle submission
      console.log("Form submitted:", formData);
    }
    const formErrors = [...validate(formData.file)];
    setErrors(formErrors);
    console.log(errors);
  };

  const handleClose = () => {
    onClose();
    setFormData({
      title: "",
      fname: "",
      mname: "",
      lname: "",
      suffix: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      mobileNumber: "",
      fax: "",
      website: "",
      checkPayableTo: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      sameAsBillingAddress: false,
      shippingStreetAddress1: "",
      shippingStreetAddress2: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "",
      shippingZipCode: "",
      notes: "",
      attachemntFile: null,
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFormData({
      ...formData,
      attachemntFile: selectedFile,
    });
  };
  return (
    <>
      <div className="bg-white h-auto w-[700px] font-sans p-5">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center font-semibold text-2xl">Vendor</h1>
          {/* <div className="border border-x-bombay px-[14px] pt-1 my-2 py-2 w-[650px] h-auto shadow-[2px_3px_2px_2px_rgba(0,0,0,0.2)] shadow-tpGray"> */}
          <div className="border border-x-bombay px-[14px] pt-1 my-2 py-2 w-[650px] h-auto">
            <div className="text-lg font-semibold">
              <lable>Name and contact</lable>
            </div>
            <div className="flex justify-center">
              <div className="flex text-sm font-normal justify-center gap-2.5 py-1 w-[620px]">
                <div className="w-[65px]">
                  <lable>Title</lable>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    maxLength={3}
                    onChange={handleChange}
                    className={`${
                      errors.title
                        ? "border border-errorRed focus:outline-none"
                        : "border-bombay border focus:border-lochmara focus:outline-0"
                    } rounded-sm w-[65px] h-[35px] px-2 outline-none
     `}
                  />
                  {errors.title && (
                    <Tooltip message={errors.title}>
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        In...
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="w-[150px]">
                  <lable>First Name *</lable>
                  <input
                    type="text"
                    name="fname"
                    value={formData.fname}
                    maxLength={100}
                    onChange={handleChange}
                    className={`${
                      errors.fname
                        ? "border border-errorRed focus:outline-none"
                        : "border-bombay border focus:border-lochmara focus:outline-0"
                    } rounded-sm h-[35px] w-[150px] outline-none px-2
     `}
                  />
                  {errors.fname && (
                    <Tooltip message={errors.fname}>
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid first...
                      </span>
                    </Tooltip>
                  )}
                </div>

                <div className="w-[150px]">
                  <lable>Middle Name</lable>
                  <input
                    type="text"
                    name="mname"
                    value={formData.mname}
                    maxLength={50}
                    onChange={handleChange}
                    className={`${
                      errors.mname
                        ? "border border-errorRed focus:outline-none"
                        : "border-bombay border focus:border-lochmara focus:outline-0"
                    } rounded-sm h-[35px] w-[150px] outline-none px-2
     `}
                  />
                  {errors.mname && (
                    <Tooltip message={errors.mname}>
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid mid...
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="w-[150px]">
                  <lable>Last Name *</lable>
                  <input
                    type="text"
                    name="lname"
                    value={formData.lname}
                    maxLength={100}
                    onChange={handleChange}
                    className={`${
                      errors.lname
                        ? "border border-errorRed focus:outline-none"
                        : "border-bombay border focus:border-lochmara focus:outline-0"
                    } rounded-sm h-[35px] w-[150px] px-2 outline-none `}
                  />
                  {errors.lname && (
                    <Tooltip message={errors.lname}>
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid last...
                      </span>
                    </Tooltip>
                  )}
                </div>

                <div className="w-[65px]">
                  <lable>Suffix</lable>
                  <input
                    type="text"
                    name="suffix"
                    value={formData.suffix}
                    maxLength={50}
                    onChange={handleChange}
                    className="border border-bombay rounded-sm w-[65px] h-[35px] px-2 outline-none focus:border-lochmara"
                  />
                </div>
              </div>
            </div>
            <div className="flex text-sm justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Company Name</lable>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.companyName
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none
     `}
                />
                {errors.companyName && (
                  <Tooltip message={errors.companyName}>
                    <span className="text-errorRed text-sm flex italic mt-1">
                      <img src="alert.png" className="w-5 h-5 mr-1" />
                      Invalid company name...
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="w-[305px]">
                <lable>Email *</lable>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  maxLength={50}
                  onChange={handleChange}
                  className={`${
                    errors.email
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.email && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.email}
                  </span>
                )}
              </div>
            </div>
            <div className="flex text-sm justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Phone Number</lable>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.phoneNumber
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.phoneNumber && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.phoneNumber}
                  </span>
                )}
              </div>
              <div className="w-[305px]">
                <lable>Mobile Number *</lable>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.mobileNumber
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.mobileNumber && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.mobileNumber}
                  </span>
                )}
              </div>
            </div>
            <div className="flex text-sm justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Fax</lable>
                <input
                  type="text"
                  name="fax"
                  value={formData.fax}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.fax
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.fax && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.fax}
                  </span>
                )}
              </div>
              <div className="w-[305px]">
                <lable>Website</lable>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  maxLength={50}
                  onChange={handleChange}
                  className={`${
                    errors.website
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                  //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                />
                {errors.website && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.website}
                  </span>
                )}
              </div>
            </div>
            <div className="flex text-sm justify-center gap-2 py-1 w-full">
              <div className="w-[620px]">
                <lable>Check payable to</lable>
                <input
                  type="text"
                  name="checkPayableTo"
                  value={formData.checkPayableTo}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.checkPayableTo
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[617px] h-[35px] px-1 outline-none `}
                  //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                />
                {errors.checkPayableTo && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.checkPayableTo}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="border border-x-bombay content-center px-[14px] py-2 w-[650px] h-auto">
            <div className="text-lg font-semibold">
              <lable>Addresses</lable>
            </div>
            <div>
              <div>
                <div className="flex text-sm justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Street address 1</lable>
                    <input
                      type="text"
                      name="streetAddress1"
                      value={formData.streetAddress1}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.streetAddress1
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.streetAddress1 && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.streetAddress1}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Street address 2</lable>
                    <input
                      type="text"
                      name="streetAddress2"
                      value={formData.streetAddress2}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.streetAddress2
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.streetAddress2 && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.streetAddress2}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-sm justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>City</lable>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.city
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.city && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.city}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>State</lable>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.state
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.state && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.state}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-sm justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Zip code/Postal code</lable>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      maxLength={6}
                      onChange={handleChange}
                      className={`${
                        errors.zipCode
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.zipCode && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.zipCode}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Country</lable>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.country
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.country && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-x-bombay content-center px-[14px] my-2 w-[650px] h-auto">
            <div className="text-lg font-semibold pt-1">
              <lable>Notes and attachements</lable>
            </div>
            <div className="flex text-sm justify-center gap-2 py-1">
              <div className="w-[620px]">
                <div className="py-2">
                  <lable>Notes</lable>
                  <div className="">
                    <textarea
                      name="notes"
                      value={formData.notes}
                      maxLength={500}
                      onChange={handleChange}
                      className={`${
                        errors.notes
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[620px] h-[93px] border-2 border-tpGray p-1 outline-none resize-none `}
                      //className="w-full h-16 border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1"
                    />
                    {errors.notes && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.notes}
                      </span>
                    )}
                  </div>
                </div>

                <div className="py-2">
                  <lable className="">Attachement</lable>
                  <div className="flex w-[620px]">
                    <div className="w-full h-[104px] border-2 border-tpGray py-2 content-center">
                      <div className="flex justify-center">
                        <button
                          className="text-royalBlue font-bold"
                          onClick={handleClick}
                        >
                          Add attachment
                        </button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="flex justify-center">
                        <span className=" content-center">
                          Max file size : 20 MB
                        </span>
                      </div>
                      {errors.attachemntFile && (
                        <span className="text-errorRed justify-center text-sm flex italic">
                          <img src="alert.png" className="w-5 h-5 mr-1" />
                          {errors.attachemntFile}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-x-bombay content-center px-[14px] py-2 w-[650px] h-auto">
            <div className="text-lg font-semibold">
              <lable>Additional Info</lable>
            </div>
            <div>
              <div>
                <div className="flex text-sm justify-start gap-2 py-3 w-full">
                  <div className="w-[305px]">
                    <div className="text-base font-semibold py-1">
                      <lable>Taxes</lable>
                    </div>
                    <lable>Business ID No. / Country Security No.</lable>
                    <input
                      type="text"
                      name="businessid"
                      value={formData.streetAddress1}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.streetAddress1
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.streetAddress1 && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.streetAddress1}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-sm justify-start gap-2 py-3 w-full">
                  <div className="w-[305px]">
                    <div className="text-base font-semibold py-1">
                      <lable>Expense rate</lable>
                    </div>
                    <lable>Billing rate (/hr)</lable>
                    <input
                      type="text"
                      name="billinghr"
                      value={formData.city}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.city
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.city && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.city}
                      </span>
                    )}
                  </div>
                  
                </div>
                <div className="flex text-sm justify-center gap-2 py-3 w-full">
                <div className="w-[305px]">
                  <div className="text-base font-semibold py-1">
                      <lable>Payments</lable>
                    </div>
                    <lable>Terms</lable>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.state
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.state && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.state}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px] pt-8">
                    <lable>Account no</lable>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.country
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.country && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-sm justify-center gap-2 py-3 w-full">
              <div className="w-[620px]">
              <div className="text-base font-semibold py-1">
                      <lable>Accounting</lable>
                    </div>
                <lable>Default expense category</lable>
                <input
                  type="text"
                  name="checkPayableTo"
                  value={formData.checkPayableTo}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.checkPayableTo
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[617px] h-[35px] px-1 outline-none `}
                  //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                />
                {errors.checkPayableTo && (
                  <span className="text-errorRed text-sm flex italic mt-1">
                    <img src="alert.png" className="w-5 h-5 mr-1" />
                    {errors.checkPayableTo}
                  </span>
                )}
              </div>
            </div>
                <div className="flex text-sm justify-center gap-2 py-3 w-full">
                <div className="w-[305px]">
                  <div className="text-base font-semibold py-1">
                      <lable>Opening Balance</lable>
                    </div>
                    <lable>Opening balance </lable>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.state
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.state && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.state}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px] pt-8">
                    <lable>As of</lable>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.country
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.country && (
                      <span className="text-errorRed text-sm flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="w-[650px] justify-end flex pt-14">
            <div className="flex justify-between">
              <button
                onClick={handleClose}
                className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-semibold"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                role="button"
                className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-semibold content-center"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddVendor;
