import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, children,width='w-[400px]',height='h-[250px]' }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`text-center ${width} ${height} bg-white py-4 rounded-[10px]`}>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
