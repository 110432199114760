import React, { useState } from "react";
import Layout from "../Layout";
import { FaChevronDown } from "react-icons/fa";
import CustomDropdown from "../../components/custom-dropdown/CustomDropdown";
import Modal from "../../components/Modal";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/Dialog";
import CreateExpense from "./create-expense/CreateExpense";
import Filter from "./filter/Filter";
import CopyExpense from "./copy-expense/CopyExpense";

const Expenses = () => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isVoidModalOpen, setVoidModalOpen] = useState(false);
  const [isCreateExpenseDialogOpen, setIsCreateExpenseDialogOpen] = useState(false);
  const [isCopyExpenseDialogOpen, setIsCopyExpenseDialogOpen] = useState(false);
  const [isCreateExpenseDialogClose, setIsCreateExpenseDialogClose] = useState(false);
  const [isCopyExpenseDialogClose, setIsCopyExpenseDialogClose] = useState(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openVoidModal = () => setVoidModalOpen(true);
  const closeVoidModal = () => setVoidModalOpen(false);
  const openFilterModal = () => setFilterModalOpen(true);
  const closeFilterModal = () => setFilterModalOpen(false);
  const openCopyExpenseModal = () => setIsCopyExpenseDialogOpen(true);

  const handleSubmitExpense = () => {
    setIsCreateExpenseDialogOpen((preventDefault) => !preventDefault);
  };
  const handleSubmitCopyExpense = () => {
    setIsCopyExpenseDialogOpen((preventDefault) => !preventDefault);
  };

  const handleSelect = (value) => {
    if (value === "void") {
      openVoidModal();
    }
    if (value === "delete") {
      openDeleteModal();
    }
    if (value === "print") {
      openFilterModal();
    }
    if (value === "duplicate") {
      openCopyExpenseModal();
    }
  };

  const options = [
    "All Transactions",
    "Expense",
    "Bill",
    "Bill Payments",
    // Add more options as needed
  ];

  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-5 font-sans">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans my-10">
                <h1>Expenses</h1>
              </div>
              <div className="float-right">
                <button
                  className="uppercase bg-lochmara text-sm font-sans font-normal justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2"
                  onClick={handleSubmitExpense}
                >
                  New Expenses
                  <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                </button>
              </div>
            </div>
            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-1 w-full pb-7">
                  <div className="">
                    <div className="text-sm font-sans font-medium">
                      <lable>Filter</lable>
                    </div>
                    <div className="text-sm font-sans w-[199px] h-[36px]">
                      <CustomDropdown
                        options={[
                          {
                            value: "alltransactions",
                            option: "All Transactions",
                          },
                          { value: "expense", option: "Expense" },
                          { value: "bill", option: "Bill" },
                          { value: "payment", option: "Bill payment" },
                        ]}
                        border="border border-pearl rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex content-center py-5 px-5">
                  <div className="w-6 h-6">
                    <img src="blob.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="icon8.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="printer.jpg" />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                      <th className="w-[3%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[12%]">
                        <div className="flex">
                          DATE
                          <FaChevronDown className="content-center cursor-pointer mt-1 ml-2" />
                        </div>
                      </th>
                      <th className="w-[10%]">TYPE</th>
                      <th className="w-[10%]">No</th>
                      <th className="w-[10%]">PAYEE</th>
                      <th className="w-[20%]">CATEGORY</th>
                      <th className="w-[15%] pl-6">TOTAL</th>
                      <th className="w-[22%] text-right">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1001</td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">
                        <CustomDropdown
                          options={[
                            {
                              value: "alltransactions",
                              option: "All Transactions",
                            },
                            { value: "expense", option: "Expense" },
                            { value: "bill", option: "Bill" },
                            { value: "payment", option: "Bill payment" },
                          ]}
                          border="border border-pearl rounded-md"
                          btnCss="text-white bg-bombay"
                        />
                      </td>
                      <td className="py-2 pl-6">$400.00</td>
                      <td className="py-2  flex cursor-pointer justify-end">
                        <a className="pt-2 text-lochmara font-semibold">
                          Schedule Payment |
                        </a>{" "}
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1001</td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">
                        <CustomDropdown
                          options={[
                            {
                              value: "alltransactions",
                              option: "All Transactions",
                            },
                            { value: "expense", option: "Expense" },
                            { value: "bill", option: "Bill" },
                            { value: "payment", option: "Bill payment" },
                          ]}
                          border="border border-pearl rounded-md"
                          btnCss="text-white bg-bombay"
                        />
                      </td>
                      <td className="py-2 pl-6">$400.00</td>
                      <td className="py-2 mt-1 flex cursor-pointer justify-end">
                        <a className="pt-2 text-lochmara font-semibold">
                          View/Edit |
                        </a>{" "}
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">25/07/2024</td>
                      <td className="py-2">Invoice</td>
                      <td className="py-2">1001</td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">
                        <CustomDropdown
                          options={[
                            {
                              value: "alltransactions",
                              option: "All Transactions",
                            },
                            { value: "expense", option: "Expense" },
                            { value: "bill", option: "Bill" },
                            { value: "payment", option: "Bill payment" },
                          ]}
                          border="border border-pearl rounded-md"
                          btnCss="text-white bg-bombay"
                        />
                      </td>
                      <td className="py-2 pl-6">$400.00</td>
                      <td className="py-2 mt-1 flex cursor-pointer justify-end">
                        <a className="pt-2 text-lochmara font-semibold">
                          View/Edit |
                        </a>{" "}
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "pdf", option: "Generate pdf" },
                              { value: "print", option: "Print" },
                              { value: "void", option: "Void" },
                              { value: "delete", option: "Delete" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={isVoidModalOpen}>
        <div className="relative">
          <button
            onClick={closeVoidModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans"></h3>
        <p className="text-sm font-sans font-semibold h-10 my-4">
          Are you sure you want to void this transaction?
        </p>
        <button
          onClick={closeVoidModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          {t("void")}
        </button>
      </Modal>
      <Modal isOpen={isDeleteModalOpen}>
        <div className="relative">
          <button
            onClick={closeDeleteModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-[18px] h-[18px] m-2" />
          </button>
          <img src="warning-icon.png" className="text-3xl mx-auto" />
        </div>
        <h3 className="w-full h-[29px] text-2xl font-semibold my-5 text-center content-center font-sans"></h3>
        <p className="text-sm font-sans font-semibold h-10 my-4">
          Are you sure you want to delete this transaction?
        </p>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          {t("delete")}
        </button>
      </Modal>
      <Dialog isOpen={isCreateExpenseDialogOpen} onClose={isCreateExpenseDialogClose}>
        <CreateExpense
          onSubmit={handleSubmitExpense}
          onClose={handleSubmitExpense}
        />
      </Dialog>
      <Dialog isOpen={isCopyExpenseDialogOpen} onClose={isCopyExpenseDialogClose}>
        <CopyExpense
          onSubmit={handleSubmitCopyExpense}
          onClose={handleSubmitCopyExpense}
        />
      </Dialog>
      <Filter isOpen={isFilterModalOpen}>
        <div className="font-sans">
          <div className="relative py-8">
            <button
              onClick={closeFilterModal}
              className="absolute top-0 -right-5 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <img src="cross-icon.png" className="w-6 h-6 m-2" />
            </button>
          </div>
          <div className="flex gap-8">
            <div>
              <div className="text-sm font-sans font-light text-start">
                <lable>Due date</lable>
              </div>
              <div className="text-sm font-sans w-[225px] h-[36px]">
                <CustomDropdown
                  options={[
                    {
                      value: "alltransactions",
                      option: "All Transactions",
                    },
                    { value: "expense", option: "Expense" },
                    { value: "bill", option: "Bill" },
                    { value: "payment", option: "Bill payment" },
                  ]}
                  border="border border-pearl rounded-md"
                  btnCss="text-white bg-bombay"
                />
              </div>
            </div>
            <div>
              <div className="text-sm font-sans font-light text-start">
                <lable>From</lable>
              </div>
              <div className="text-sm font-sans w-[200px] h-[36px]">
                <input
                  type="date"
                  className="w-full h-full border border-pearl rounded-md px-2"
                />
              </div>
            </div>
            <div>
              <div className="text-sm font-sans font-light text-start">
                <lable>To</lable>
              </div>
              <div className="text-sm font-sans w-[200px] h-[36px]">
                <input
                  type="date"
                  className="w-full h-full border border-pearl rounded-md px-2"
                />
              </div>
            </div>
          </div>
          <div className="py-8">
            <div className="text-sm font-sans font-light text-start">
              <lable>Payee</lable>
            </div>
            <div className="text-sm font-sans w-[225px] h-[36px]">
              <CustomDropdown
                options={[
                  {
                    value: "alltransactions",
                    option: "All Transactions",
                  },
                  { value: "expense", option: "Expense" },
                  { value: "bill", option: "Bill" },
                  { value: "payment", option: "Bill payment" },
                ]}
                border="border border-pearl rounded-md"
                btnCss="text-white bg-bombay"
              />
            </div>
          </div>
          <div className="gap-1 flex content-center">
            <input type="checkbox" className="accent-green-500 w-5 h-[20px]" />
            <label
              for="remember"
              className="ml-3 dark:text-gray-700 text-sm font-sans font-light"
            >
              Overdue status only
            </label>
          </div>
          <div className="float-end">
          <button
            type="cancel"
            name="cancel"
            className="uppercase bg-pearl text-white font-sans font-bold mr-8 text-sm w-[200px] h-[36px] rounded-[10px]"
            disabled
          >
            {t("reset")}
          </button>
          <button
            onClick={closeFilterModal}
            className="my-10 uppercase text-white bg-lochmara font-sans font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
          >
            {t("apply")}
          </button>
          </div>
        </div>
      </Filter>
    </>
  );
};

export default Expenses;
