// src/CustomSelect.js
import React, { useState, useRef, useEffect } from 'react';
import { FaCalendarAlt } from 'react-icons/fa'; // Calendar icon
import { IoIosArrowDown } from 'react-icons/io'; // Down arrow icon

const Select = ({ options = [], placeholder = 'Select...', width = 'auto'}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(prev => !prev);
  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`relative inline-block h-9 text-nowrap ${width}`}
    >
      {/* Calendar Icon */}
      {/* <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" /> */}

      {/* Selected Option and Dropdown Toggle */}
      <div
        className="flex items-center pl-4 pr-2 py-[6px] rounded-md border border-greyText bg-white"
        onClick={toggleDropdown}
      >
        <span className="flex-grow text-sm">{selectedOption}</span>
        <IoIosArrowDown className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10 text-sm">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => selectOption(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;
